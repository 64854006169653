import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
// import "./style.css";
import { NavLink } from "react-router-dom";

const Purchaseform = () => {
  const [tab,settab] = useState(1)
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{backgroundColor:'#eff4f9'}}>
              <ul
                className="nav nav-tabs"
                style={{ color: "white", border: "none" }}
              >
                <li
                  className="nav-item"
                  style={{
                    backgroundColor: "#fff"
                  }}
                >
                  <a className={tab == 1 ? "nav-link active text-white" : "nav-link text-white"} onClick={()=>{settab(1)}} href="#">
                    General Information {tab == 1 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />} 
                  </a>
                </li>
              </ul>
            </div>
      <div className="row d-flex justify-content-between"  style={{marginTop:'14px'}}>
        <div className="col-5 ">

          {/* tab one is here  */}
         {tab == 1 ? <div
            className="row sidebarbox m-0"
            style={{ padding: "8px", borderRadius: "5px" }}
          >
            
            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">No.</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                  value="PO-PP/24-25/00021"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Shipment Method<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                  value="Cash"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Scan Box Here</label>
                <input type="text" className="form-control inputdesign"    style={{fontSize:'0.8rem'}} />
              </div>
          
            </div>

            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">DSP Code<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                  // value="2024-10-07 09:21:02"
                  // readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">No. of Box</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                  value="0"
                  // readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Vehical No</label>
                <input
                //  type="date"
                // value="Open"
                 className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
            
            </div>
          </div> : ''} 


 

        </div>

        <div className="col-7">
          <div className="table-responsive newscroller">
            <table className="table table-bordered">
              <thead style={{ backgroundColor: "#4B0082", color: "white" }}>
                <tr>
                  {/* <th style={{textWrap:'nowrap',textAlign:'left'}}>Action</th> */}
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Box Id</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Quantity</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Sales Amount</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Purchase Amount</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>AWB No</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Weight</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Length</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Breadth</th>
                  <th style={{textWrap:'nowrap',textAlign:'left'}}>Height</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>
                    <button className="btn btn-primary btn-sm" style={{backgroundColor:'#ec642a',border:'1px solid #ec642a',borderRadius:'0px'}}>
                      <FaPlus />
                    </button>
                  </td> */}
                 
                  <td colSpan={9} style={{fontSize:'13px'}}>
                    No Record Found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <div>
              <p style={{color:'#270B79',fontSize:'13px'}}>
               <FiPlus /> Add Row
              </p>
            </div>

     
        </div>
      </div>
    </div>
  );
};

export default Purchaseform;
