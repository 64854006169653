import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const PostedGRNReportComponents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const PostedGRNReportComp = [
    {
      location_code: "",
      order_no: "-",
      posted_no: "-",
      posting_date: "-",
      ref_doc_no: "-",
      gate_entry_no: "-",
      asn_no: "-",
      item_no: "-",
      order_qty: "-",
      qty_received: "-",
      ok_outstanding: "-",
      ok_to_receive: "-",
      ok_qauntity: "-",
      not_ok_qauntity: "-",
      UOM: "-",
      Reason_code: "-",
      Reason_Descritpions: "-",
      Source_Line_No: "-",
      Description_1: "-",
      Description_2: "-",
      lot_no: "-",
      serial_no: "-",
      item_expiration: "-",
      manufacture: "-",
      warranty: "-",
      cost_price: "-",
      cost_value: "-",
      created_by: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Posted Warehouse Line
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posted No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posting Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Ref. Doc. No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gate Entry No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  ASN No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qty. Received
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qty. Outstanding
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qty. to Receive
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Ok Qauntity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Not OK Qauntity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  UOM
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Reason Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Reason Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Source Line No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description 1
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description 2
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Lot No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Serial No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Expiration
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Manufacture
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Warranty
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cost Price
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cost Value
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {PostedGRNReportComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((posted_grn_report_data, index) => (
                <tr key={index}>
                  <td>{posted_grn_report_data.location_code}</td>
                  <td>{posted_grn_report_data.order_no}</td>
                  <td>{posted_grn_report_data.posted_no}</td>
                  <td>{posted_grn_report_data.posting_date}</td>
                  <td>{posted_grn_report_data.ref_doc_no}</td>
                  <td>{posted_grn_report_data.gate_entry_no}</td>
                  <td>{posted_grn_report_data.asn_no}</td>
                  <td>{posted_grn_report_data.item_no}</td>
                  <td>{posted_grn_report_data.order_qty}</td>
                  <td>{posted_grn_report_data.qty_received}</td>
                  <td>{posted_grn_report_data.ok_outstanding}</td>
                  <td>{posted_grn_report_data.ok_to_receive}</td>
                  <td>{posted_grn_report_data.ok_qauntity}</td>
                  <td>{posted_grn_report_data.not_ok_qauntity}</td>
                  <td>{posted_grn_report_data.UOM}</td>
                  <td>{posted_grn_report_data.Reason_code}</td>
                  <td>{posted_grn_report_data.Reason_Descritpions}</td>
                  <td>{posted_grn_report_data.Source_Line_No}</td>
                  <td>{posted_grn_report_data.Description_1}</td>
                  <td>{posted_grn_report_data.Description_2}</td>
                  <td>{posted_grn_report_data.lot_no}</td>
                  <td>{posted_grn_report_data.serial_no}</td>
                  <td>{posted_grn_report_data.item_expiration}</td>
                  <td>{posted_grn_report_data.manufacture}</td>
                  <td>{posted_grn_report_data.warranty}</td>
                  <td>{posted_grn_report_data.cost_price}</td>
                  <td>{posted_grn_report_data.cost_value}</td>
                  <td>{posted_grn_report_data.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PostedGRNReportComponents;
