import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt, MdOutlineClose, MdOutlineRemoveRedEye, MdLocalPrintshop } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge, FaRegEdit } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
        <a href="#" className="pagetitle px-2">
        {" "}
        Posted Manifest List
      </a>
        <div className="d-flex" style={{ gap: "5px" }}>
          <NavLink
            to="/outbound/outbound-create-manifest"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
         
        </div>

     
      </div>
      {/* <div
        className="d-flex justify-content-between p-2"
        onClick={() => {
          setfilters(!filters);
        }}
        style={{
          background: "##f7f7f7",
          border: "1px solid rgba(0, 0, 0, 0.125)",
          borderRadius: "3px",
        }}
      >
        <p
          className="m-0"
          style={{ color: "black", fontWeight: "500" }}
          onClick={() => {
            setfilters(!filters);
          }}
        >
          Advanced Filters
        </p>
      </div>
      <div
        className={
          filters == false
            ? "hidefilter justify-content-between p-2"
            : "showfilter justify-content-between p-2"
        }
        style={{
          background: "#fcfdfe",
          border: "1px solid rgba(0, 0, 0, 0.125)",
          borderBottom: "0px solid white",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
         

     

   

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Invoice No."
              className="form-control"
            />
          </div>

 



 

          <div className="col-3 d-flex" style={{ gap: "6px" }}>
            <a
              href=""
              className="btn btn-primary createbtn m-0"
              style={{ width: "fit-content" }}
            >
              Apply Filter
            </a>
            <a
              href=""
              className="btn btn-primary importbtn m-0"
              style={{ width: "fit-content" }}
            >
              Clear Filter
            </a>
          </div>
        </div>
      </div> */}
    

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">


            <tr>
              <th className="table-header" >
                Action
              </th>
              <th className="table-header" >
                No <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Reference No. <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                DSP Code <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Transport Method <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                No of Box <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Client <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Posted Date <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Handover Date <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Posted By <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Last Modified By <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Vehical Seal No. <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Driver Name <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Driver Mobile No. <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Vehical Seal Image <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Vehical Image <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Invoice Image <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                  <p className='d-flex m-0 justify-content-center' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p>
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                  PS-PP/22-23/100077
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    Manifest-PP/21-22/100162
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>fedex</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>DKB</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>GGN</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    2021-07-21 12:27:51
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>admin</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
               <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>

      </div>
    </div>
  );
};

export default Lpntable;
