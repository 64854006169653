import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const ItemForecastAnalysisComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const ItemForecastAnalysisComp = [
    {
      sku_code: "-",
      opening_inventory: "",
      projected_inventory_octomber_2024: "",
      fulfillment_dispatch_from_plant: "-",
      dispatch_to_plant_warehouse: "-",
      actual_received_by_cfa: "-",
      short: "-",
      sale_return: "",
      adjustment_inven: "",
      expcted_total_inventory: "",
      actual_total_inventory: "",
      Diff_Actual_Inv_Exp_Inv: "-",
      pending_for_receipt: "",
      total_sale_octomber_2024: "",
      Expected_Available_Inventory: "",
      Actual_Available_Inventry_WMS: "-",
      Diff_Act_Exp: "-",
      location: "-",
      client: "",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Item Forecast Analysis
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th>
                  SKU Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Opening Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Projected Inventory October 2024
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Fullfilment (Plant to CFA) Dispatch from Plant (TO IN) +
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  (TO - Out) Dispatch to Plant Warehouse (-)
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Actual Received by CFA
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Short/Excess Diff
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Fresh inward PO/Sale Return
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Adjustment( + - ) Inven
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Expected Total Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Actual Total Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Diff Actual Inv - Exp Inv
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Pending for Receipt
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Total Sale October 2024
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Expected Available Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Actual Available Inventry WMS
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Diff (Act - Exp)
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Location
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {ItemForecastAnalysisComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((item_forecast_analysis_data, index) => (
                <tr key={index}>
                  <td>{item_forecast_analysis_data.sku_code}</td>
                  <td>{item_forecast_analysis_data.opening_inventory}</td>
                  <td>
                    {
                      item_forecast_analysis_data.projected_inventory_octomber_2024
                    }
                  </td>
                  <td>
                    {
                      item_forecast_analysis_data.fulfillment_dispatch_from_plant
                    }
                  </td>
                  <td>
                    {item_forecast_analysis_data.dispatch_to_plant_warehouse}
                  </td>
                  <td>{item_forecast_analysis_data.actual_received_by_cfa}</td>
                  <td>{item_forecast_analysis_data.short}</td>
                  <td>{item_forecast_analysis_data.sale_return}</td>
                  <td>{item_forecast_analysis_data.adjustment_inven}</td>
                  <td>{item_forecast_analysis_data.expcted_total_inventory}</td>
                  <td>{item_forecast_analysis_data.actual_total_inventory}</td>
                  <td>{item_forecast_analysis_data.Diff_Actual_Inv_Exp_Inv}</td>
                  <td>{item_forecast_analysis_data.pending_for_receipt}</td>
                  <td>
                    {item_forecast_analysis_data.total_sale_octomber_2024}
                  </td>
                  <td>
                    {item_forecast_analysis_data.Expected_Available_Inventory}
                  </td>
                  <td>
                    {item_forecast_analysis_data.Actual_Available_Inventry_WMS}
                  </td>
                  <td>{item_forecast_analysis_data.Diff_Act_Exp}</td>
                  <td>{item_forecast_analysis_data.location}</td>
                  <td>{item_forecast_analysis_data.client}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ItemForecastAnalysisComponent;
