import React, { useState, useRef } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  MdSms,
  MdShoppingCart,
  MdOutlineGridView,
  MdWorkspacePremium,
  MdFileUpload,
  MdDownload,
  MdConfirmationNumber,
  MdStorefront,
  MdDescription,
  MdManageAccounts,
} from "react-icons/md";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Style/Profiledropdown.css";

const Mainmenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeSubSubmenu, setActiveSubSubmenu] = useState(null);
  const [submenuTop, setSubmenuTop] = useState(0);
  const menuRefs = useRef([]);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const handleMouseEnter = (index) => {
    const parentMenu = menuRefs.current[index];
    if (parentMenu) {
      setSubmenuTop(parentMenu.offsetHeight);
      setActiveSubmenu(index);
    }
  };

  const handleMouseLeave = () => {
    setActiveSubmenu(null);
    setActiveSubSubmenu(null);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="user-dropdown"
      style={{ border: "none" }}
    >
      <DropdownToggle
        caret
        className="btn no-active btn-primary"
        style={{ border: "none" }}
      >
        <p className="hamburggericon">
          <MdOutlineGridView size={20} />
        </p>
      </DropdownToggle>
      <DropdownMenu right>
        {menuItems.map((menu, index) => (
          <DropdownItem
            key={index}
            className="pb-1"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            ref={(el) => (menuRefs.current[index] = el)}
          >
            {menu.icon}
            <span style={{ color: "#270b79", fontSize: "13px" }}>
              {menu.label}
            </span>
            {activeSubmenu === index && (
              <Submenu
                items={menu.items}
                top={index == 0 || index == 1 ? 0 : submenuTop} // Adjust top position as needed
                onMouseLeave={handleMouseLeave}
                onSubmenuHover={(subIndex) => setActiveSubSubmenu(subIndex)}
                onSubmenuMouseEnter={() => setActiveSubSubmenu(null)} // Reset on submenu enter
                activeSubSubmenu={activeSubSubmenu}
              />
            )}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const Submenu = ({
  items,
  top,
  onMouseLeave,
  onSubmenuHover,
  activeSubSubmenu,
}) => {
  return (
    <div
      className="submenu"
      style={{
        position: "absolute",
        left: "100%", // Position to the right of the parent
        top: `${top}px`, // Set top based on parent menu height
        backgroundColor: "white",
        border: "1px solid #ccc",
        color: "red",
        zIndex: 1000,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
        minWidth: "210px", // Optional: set a minimum width for the submenu
      }}
      onMouseLeave={onMouseLeave} // Call onMouseLeave for submenu
    >
      {items.map((item, index) => (
        <div
          key={index}
          onMouseEnter={() => onSubmenuHover(index)} // Show SubSubmenu when hovering over the item
          onMouseLeave={() => onSubmenuHover(null)} // Hide SubSubmenu when leaving the item
        >
          <Link
            to={item.link}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <DropdownItem>{item.label}</DropdownItem>
          </Link>
          {activeSubSubmenu === index &&
            item.subItems &&
            item.subItems.length > 0 && <SubSubmenu items={item.subItems} />}
        </div>
      ))}
    </div>
  );
};

const SubSubmenu = ({ items }) => {
  return (
    <div
      className="sub-submenu"
      style={{
        position: "absolute",
        left: "100%", // Position to the right of the submenu
        top: "0", // Set top based on parent submenu
        backgroundColor: "white",
        border: "1px solid #ccc",
        zIndex: 1001,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
        minWidth: "210px", // Optional: set a minimum width for the sub-submenu
      }}
    >
      {items.map((subItem, index) => (
        <Link
          key={index}
          to={subItem.link}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <DropdownItem>{subItem.label}</DropdownItem>
        </Link>
      ))}
    </div>
  );
};

// Menu items with links
const menuItems = [
  // {
  //   icon: <MdWorkspacePremium className="desginicon" />,
  //   label: "Master",
  //   items: [
  //     {
  //       label: "Item",
  //       link: "/master/item",
  //       subItems: [
  //         { label: "Industry Type", link: "/master/item/industry-type" },
  //         {
  //           label: "Industry Sub Type",
  //           link: "/master/item/industry-sub-type",
  //         },
  //         {
  //           label: "Industry Sub Type Client Mapping",
  //           link: "/master/item/industry-sub-type-client-mapping",
  //         },
  //         { label: "Item Attribute", link: "/master/item/item-attribute" },
  //         {
  //           label: "Item Attribute Mapping",
  //           link: "/master/item/item-attribute-mapping",
  //         },
  //         {
  //           label: "Item Attribute Value",
  //           link: "/master/item/item-attribute-value",
  //         },
  //         { label: "Set Combination", link: "/master/item/set-combination" },
  //         { label: "Item", link: "/master/item/item" },
  //         { label: "UOM", link: "/master/item/uom" },
  //         { label: "Item Tracking", link: "/master/item/item-tracking" },
  //         {
  //           label: "Item Cross Reference",
  //           link: "/master/item/item-cross-reference",
  //         },
  //         { label: "Item Forecast", link: "/master/item/item-forecast" },
  //         {
  //           label: "Item Unit of Measure",
  //           link: "/master/item/item-unit-of-measure",
  //         },
  //         { label: "Forgot Attribute", link: "/master/item/forgot-attribute" },
  //       ],
  //     },
  //     { label: "Vendor", link: "/master/vendor" },
  //     { label: "Customers", link: "/master/customers" },
  //   ],
  // },
  {
    icon: <MdFileUpload className="desginicon" />,
    label: "Inbound",
    items: [
      {
        label: "Purchase Order",
        link: "/purchaseorder/purchase-orders",
        subItems: [
          { label: "Purchase Order", link: "/purchaseorder/purchase-orders" },
          {
            label: "Update PO Expiration",
            link: "/purchaseorder/update_po_expiration",
          },
          { label: "ASN", link: "/asn/inboundasn" },
          { label: "Posted ASN", link: "/asn/postedinboundasn" },
        ],
      },
      {
        label: "Purchase Return Order",
        link: "/purchasereturn/purchase-return-order",
        subItems: [
          {
            label: "Purchase Return Order",
            link: "/purchasereturn/purchase-return-order",
          },
        ],
      },
      {
        label: "Create GRN",
        link: "/inbound/create-warehouse-receipt",
        subItems: [
          { label: "Create GRN", link: "/inbound/create-warehouse-receipt" },
          { label: "LPN List", link: "/lpn/lpn-list" },
          { label: "GRN to be Posted", link: "/inbound/warehouse-receipt-list" },
          { label: "Posted GRN", link: "/inbound/posted-warehouse" },
          { label: "Posted Purchase Receipt", link: "/inbound/posted-purchase" },
          { label: "Posted LPN No. List", link: "/lpn/posted-lpn-list" },
          { label: "Lot Information", link: "/lot_info/lot-information" },
          { label: "Serial Information", link: "/lot_info/serial-information" },
        ],
      },
      {
        label: "Create Put-away",
        link: "/inbound/subitem2",
        subItems: [
          { label: "Create Put-away", link: "/putaway/create-in-putaway" },
          { label: "Put-away List", link: "/putaway/inputaway" },
          { label: "Registered Put-away", link: "/putaway/registred-putaway" },
        ],
      },
      {
        label: "Create Sales Return Receipt",
        link: "/return_sales/create-sales-return-receipt",
        subItems: [
          {
            label: "Create Sales Return Receipt",
            link: "/return_sales/create-sales-return-receipt",
          },
          {
            label: "Sales Return to be Posted",
            link: "/return_sales/sales-return-to-be-posted",
          },
          {
            label: "Posted Sales Return",
            link: "/return_sales/posted-sales-return",
          },
        ],
      },
      {
        label: "Create Undo GRN",
        link: "/undo/create-grn",
        subItems: [
          { label: "Create Undo GRN", link: "/undo/create-grn" },
          { label: "Undo GRN to be posted", link: "/undo/undo-grnt-list" },
          {
            label: "Undo Sales Return to be posted",
            link: "/undo/undo-grnt-list-sales",
          },
          { label: "Posted Undo GRN", link: "/undo/undo-posted-grn" },
        ],
      },
    ],
  },
  {
    icon: <MdDownload className="desginicon" />,
    label: "Outbound",
    items: [
      {
        label: "Sales Order",
        link: "/outbound/outbound-sales-order",
        subItems: [
          { label: "Sales Order Manual", link: "/outbound/outbound-sales-order" },
          {
            label: "Sales Order Upload",
            link: "/outbound/outbound-sales-order-upload",
          },
          { label: "Sales order B2C", link: "/outbound/outbound-b2c-sales-order" },
          { label: "Channel Upload", link: "/amazonoredrs" },
        ],
      },
      {
        label: "Sales Return Order",
        link: "/outbound/outbound-return-sales-order",
        subItems: [
          {
            label: "Sales Return Order Manual",
            link: "/outbound/outbound-return-sales-order",
          },
          {
            label: "Sales Return Order Upload",
            link: "/outbound/outbound-return-sales-orderupload",
          },
        ],
      },
      {
        label: "Create Shipment",
        link: "/shipment/outbound-create-shipment",
        subItems: [
          {
            label: "Create Shipment",
            link: "/shipment/outbound-create-shipment",
          },
          { label: "Shipment List", link: "/shipment/outbound-shipment" },
        ],
      },
      {
        label: "Create Pick",
        link: "/pick/create-pick",
        subItems: [
          { label: "Create Pick", link: "/pick/create-pick" },
          { label: "Pick List", link: "/pick/outbound-pick" },
          {
            label: "Create Reverse Pick Putaway",
            link: "/putaway/reverse-pick-putaway",
          },
          {
            label: "Reverse Putaway List",
            link: "/putaway/reverse-pick-putaway",
          },
          {
            label: "Posted Reverse Pick Putaway",
            link: "/putaway/posted-reverse-pick-putaway",
          },
        ],
      },
      {
        label: "Create Box Packing",
        link: "/outbound/outbound-box",
        subItems: [
          { label: "Create Box Packing", link: "/outbound/outbound-box" },
          { label: "Posted Box List", link: "/outbound/outbound-box-list" },
          { label: "Unbox", link: "/outbound/unbox" },
        ],
      },
      {
        label: "Shipment to be posted",
        link: "/shipment/outbound-shippost",
        subItems: [
          {
            label: "Shipment To Be Posted",
            link: "/shipment/outbound-shippost",
          },
          {
            label: "Posted Shipment",
            link: "/shipment/outbound-posted-shipment",
          },
        ],
      },
      {
        label: "Posted Documents",
        link: "/outbound/outbound-posted-pick",
        subItems: [
          { label: "Registered Pick", link: "/outbound/outbound-posted-pick" },
          {
            label: "Posted Purchase Return",
            link: "/outbound/outbound-posted-return",
          },
          { label: "Pick Reverse", link: "/outbound/pick-reverse" },
          { label: "Posted Invoice", link: "/outbound/outbound-posted-sales" },
          { label: "Update Posted Invoice", link: "/invoice/lredit" },
          { label: "Posted Invoice 2", link: "/report/posted-invoice-line" },
          { label: "Posted Invoice Change Log", link: "/outbound/lrlog" },
        ],
      },
      {
        label: "Create Manifest",
        link: "/outbound/outbound-manifest",
        subItems: [
          { label: "Create Manifest", link: "/outbound/outbound-manifest" },
          { label: "Posted Manifest", link: "/outbound/outbound-post-manifest" },
        ],
      },
    ],
  },
  {
    icon: <MdStorefront className="desginicon" />,
    label: "Warehouse",
    items: [
      // Warehouse : Bin Movement
      {
        label: "Bin Movement",
        link: "/Bin-Movement/binmovement",
        subItems: [
          { label: "Bin Movement", link: "/Bin-Movement/binmovement" },
          {
            label: "Posted Bin Movement",
            link: "/Bin-Movement/posted-binmovement",
          },
        ],
      },
      // Warehouse : Adjustment
      {
        label: "Adjustment",
        link: "/adjustment",
        subItems: [
          { label: "Adjustment", link: "/adjustment" },
          {
            label: "Posted Adjustment",
            link: "/adjustment/posted-adjustment",
          },
        ],
      },
      // Warehouse : Cycle Count
      {
        label: "Cycle Count",
        link: "/cycle-count",
        subItems: [
          { label: "Cycle Count", link: "/cycle-count" },
          {
            label: "Posted Cycle Count",
            link: "/cycle-count/posted-cycle-count",
          },
        ],
      },
      // Warehouse : Transfer Order
      {
        label: "Transfer Order",
        link: "/transfer-order",
        subItems: [
          { label: "Transfer Order", link: "/transfer-order" },
          {
            label: "Posted Transfer Shipment",
            link: "/transfer-order/posted-transfer-shipment",
          },
          {
            label: "Create Transfer Receipt",
            link: "/transfer-order/create-transfer-receipt",
          },
          {
            label: "Transfer Receipt to be Posted",
            link: "/transfer-order/transfer-receipt-to-be-posted",
          },
          {
            label: "Posted Transfer Receipt",
            link: "/transfer-order/posted-transfer-receipt",
          },
        ],
      },
      // Warehouse : Assembly Order
      {
        label: "Assembly Order",
        link: "/assembly-order",
        subItems: [
          { label: "Assembly Order", link: "/assembly-order" },
          {
            label: "Assembly BOM Item",
            link: "/assembly-order/assembly-bom-item",
          },
          {
            label: "Assembly Order Create Pick",
            link: "/assembly-order/assembly-order-create-pick",
          },
          {
            label: "Assembly Order Pick List",
            link: "/assembly-order/assembly-order-pick-list",
          },
          {
            label: "Assembly Order To Be Posted",
            link: "/assembly-order/assembly-order-toBe-Posted",
          },
          {
            label: "Posted Assembly List",
            link: "/assembly-order/posted-assembly-list",
          },
          {
            label: "Assembly Order Registerd Pick",
            link: "/assembly-order/assembly-order-registerd-pick",
          },
          {
            label: "Assembly Order Create Put",
            link: "/assembly-order/assembly-order-create-put",
          },
          {
            label: "Assembly Order Put-away List",
            link: "/assembly-order/assembly-order-put-away-list",
          },
          {
            label: "Reverse Assembly Order",
            link: "/assembly-order/reverse-assembly-order",
          },
          {
            label: "BOM Master",
            link: "/assembly-order/BOM-Master",
          },
          {
            label: "Reverse Assembly Order Create Pick",
            link: "/assembly-order/reverse-assembly-order-create-pick",
          },
          {
            label: "Reverse Assembly Pick List",
            link: "/assembly-order/reverse-assembly-pick-list",
          },
          {
            label: "Reverse Assembly Order To Be Posted",
            link: "/assembly-order/reverse-assembly-order-toBe-Posted",
          },
        ],
      },
      // Warehouse : UOM Conversion
      {
        label: "UOM Conversion",
        link: "/UOM-Conversion",
        subItems: [
          { label: "UOM Conversion", link: "/UOM-Conversion" },
          {
            label: "UOM Conversion Posted",
            link: "/UOM-Conversion/UOM-Conversion-posted",
          },
          {
            label: "UOM Conversion Order",
            link: "/UOM-Conversion/UOM-Conversion-order",
          },
          {
            label: "UOM Create Pick",
            link: "/UOM-Conversion/UOM-Create-Pick",
          },
          {
            label: "UOM Pick List",
            link: "/UOM-Conversion/UOM-Pick-List",
          },
          {
            label: "UOM Order To Be Posted",
            link: "/UOM-Conversion/UOM-order-to-be-posted",
          },
          {
            label: "Posted UOM List",
            link: "/UOM-Conversion/posted-uom-list",
          },
          {
            label: "UOM Order Create Put-Away",
            link: "/UOM-Conversion/uom-order-create-put-away",
          },
          {
            label: "UOM Put List",
            link: "/UOM-Conversion/uom-put-list",
          },
          {
            label: "UOM Registered Put-Away",
            link: "/UOM-Conversion/uom-registered-put-away",
          },
        ],
      },
      // Warehouse : RGP
      {
        label: "RGP",
        link: "/RGP",
        subItems: [
          { label: "RGP", link: "/RGP" },
          { label: "Create RGP Receipt", link: "/RGP/Create-RGP-Receipt" },
          { label: "Posted RGP Shipment", link: "/RGP/Posted-RGP-Shipment" },
          { label: "RGP To Be Posted", link: "/RGP/RGP-To-Be-Posted" },
          { label: "RGP Posted", link: "/RGP/RGP-Posted" },
        ],
      },
    ],
  },
  {
    icon: <MdDescription className="desginicon" />,
    label: "Report",
    items: [
      //Report Inventroy
      {
        label: "Inventory",
        link: "/dashboard/",
        subItems: [
          { label: "Inventory Report", link: "/Inventory/Inventory-Report" },
          {
            label: "Inventory Bin Wise",
            link: "/Inventory/Inventory-Bin-Wise",
          },
          {
            label: "Inventory Item Wise",
            link: "/Inventory/Inventory-Item-Wise",
          },
          {
            label: "Inventory Client Wise",
            link: "/Inventory/Inventory-Client-Wise",
          },
          {
            label: "Lot Inventory Bin Wise",
            link: "/Inventory/Lot-Inventory-Bin-Wise",
          },
          {
            label: "Blank Bin Report",
            link: "/Inventory/Blank-Bin-Report",
          },
          {
            label: "Lot Inventory Item Wise",
            link: "/Inventory/Lot-Inventory-Item-Wise",
          },
          {
            label: "Pivot Inventory Bin Wise",
            link: "/Inventory/Pivot-Inventory-Bin-Wise",
          },
          {
            label: "Item Aging",
            link: "/Inventory/Item-Aging",
          },
          {
            label: "Bin Aging",
            link: "/Inventory/Bin-Aging",
          },
          {
            label: "Item Movement",
            link: "/Inventory/Item-Movement",
          },
          {
            label: "Item Qauntities",
            link: "/Inventory/Item-Qauntities",
          },
          {
            label: "UOM Report",
            link: "/Inventory/UOM-Report",
          },
        ],
      },
      //Report Inbound
      {
        label: "Inbound",
        link: "/dashboard/",
        subItems: [
          {
            label: "Inbound Cycle Details",
            link: "/Inbound/Inbound-Cycle-Details",
          },
          {
            label: "Posted GRN Report",
            link: "/Inbound/Posted-GRN-Report",
          },
          {
            label: "Purchase Receipt Report",
            link: "/Inbound/Purchase-Receipt-Reports",
          },
          {
            label: "Put-away Report",
            link: "/Inbound/Put-away-Reports",
          },
          {
            label: "Purchase Order Cycle",
            link: "/Inbound/Purchase-Order-Cycle",
          },
          {
            label: "Inbound Cycle Reports",
            link: "/Inbound/Inbound-Cycle-Reports",
          },
          {
            label: "Pending Put Away",
            link: "/Inbound/Pending-Put-Away",
          },
        ],
      },

      //Report Outbound
      {
        label: "Outbound",
        link: "/dashboard/",
        subItems: [
          {
            label: "Posted Shipment Report",
            link: "/Outbound/Posted-Shipment-Report",
          },
          {
            label: "Posted Pick Report",
            link: "/Outbound/Posted-Pick-Report",
          },
          {
            label: "Posted Box Report",
            link: "/Outbound/Posted-Box-Reports",
          },
          {
            label: "Sales Order Cycle",
            link: "/Outbound/Sales-Order-Cycle",
          },
          {
            label: "Pending Pick",
            link: "/Outbound/Pending-Pick",
          },
          {
            label: "Pending Shipment",
            link: "/Outbound/Pending-Shipment",
          },
          {
            label: "Delivery Report",
            link: "/Outbound/Delivery-Report",
          },
          {
            label: "Outbound Cycle",
            link: "/Outbound/Outbound-Cycle",
          },
          {
            label: "Back Order Report",
            link: "/Outbound/Back-Order-Report",
          },
          {
            label: "Sales Return Report",
            link: "/Outbound/Sales-Return-Report",
          },
          {
            label: "Posted Manifest",
            link: "/Outbound/Posted-Manifest",
          },
          {
            label: "Posted Box",
            link: "/Outbound/Posted-Box",
          },
          {
            label: "Outbound Cycle Details",
            link: "/Outbound/Outbound-Cycle-Details",
          },
          {
            label: "Pick Reservation",
            link: "/Outbound/Pick-Reservation",
          },
        ],
      },
      //Report : Ledger Entries
      {
        label: "Ledger Entries",
        link: "/Ledger-Entries-Report/",
        subItems: [
          {
            label: "Item Ledger Entry",
            link: "/LedgerEntries/Ledger-Entries-Report",
          },
          {
            label: "Warehouse Entry",
            link: "/LedgerEntries/Warehouse-Entry",
          },
          {
            label: "Value Entry Report",
            link: "/LedgerEntries/Value-Entry-Report",
          },
          {
            label: "GST Ledger Report",
            link: "/LedgerEntries/GST-Ledger-Report",
          },
          {
            label: "Item Forecast Analysis",
            link: "/LedgerEntries/Item-Forecast-Analysis",
          },
        ],
      },
      //Report : Auto Email
      {
        label: "Auto Email",
        link: "/Auto-Email",
        subItems: [
          {
            label: "Email Internal Reports",
            link: "/Auto-Email",
          },
        ],
      },
      //Report : Billing Reports
      {
        label: "Billing Reports",
        link: "/Billing-Reports",
        subItems: [
          {
            label: "Client Billing Reports",
            link: "/Billing-Reports",
          },
        ],
      },
      //Report : Final Posting Date
      {
        label: "Final Posting Date",
        link: "/Final-Posting-Date",
      },
      //Report : Warehouse Report
      {
        label: "Warehouse Report",
        link: "/dashboard",
        subItems: [
          {
            label: "Adjustment Report",
            link: "/Warehouse-Report/Adjustment-Report",
          },
          {
            label: "Binmovement Report",
            link: "/Warehouse-Report/Binmovement-Report",
          },
          {
            label: "Assembly Consolidated",
            link: "/Warehouse-Report/Assembly-Consolidated",
          },
          {
            label: "Assembly Report",
            link: "/Warehouse-Report/Assembly-Report",
          },
          {
            label: "TO Report",
            link: "/Warehouse-Report/TO-Report",
          },
        ],
      },
    ],
  },
  // {
  //   icon: <MdConfirmationNumber className="desginicon" />,
  //   label: "Gate Entry",
  //   items: [
  //     { label: "Subitem 1", link: "/gate-entry/subitem1" },
  //     { label: "Subitem 2", link: "/gate-entry/subitem2" },
  //   ],
  // },
  {
    icon: <MdManageAccounts className="desginicon" />,
    label: "User Management",
    link: "/manage-users",
    items: [
      {
        label: "User Management",
        link: "/manage-users",
        subItems: [
          { label: "User Management", link: "/manage-users" },
          { label: "Roles", link: "/manage-users/Roles" },
          { label: "Login Users", link: "/manage-users/Login-Users" },
          { label: "Activity Log", link: "/manage-users/Activity-Log" },
        ],
      },
    ],
  },
  // {
  //   icon: <MdShoppingCart className="desginicon" />,
  //   label: "Order Management",
  //   items: [
  //     { label: "Subitem 1", link: "/order-management/subitem1" },
  //     { label: "Subitem 2", link: "/order-management/subitem2" },
  //   ],
  // },
  // {
  //   icon: <MdSms className="desginicon" />,
  //   label: "Ticket Raise",
  //   items: [
  //     { label: "Subitem 1", link: "/ticket-raise/subitem1" },
  //     { label: "Subitem 2", link: "/ticket-raise/subitem2" },
  //   ],
  // },
];

export default Mainmenu;

{
  /* <ul class="submenu submenu-level2">                                 <li><a href="https://dev.stackerbee.com/outbound/outbound-posted-pick" "target="blank">Registered Pick</a></li>
                                                                <li><a href="https://dev.stackerbee.com/outbound/outbound-posted-return" "target="blank">Posted Purchase Return</a></li>
                                                                <li><a href="https://dev.stackerbee.com/outbound/pick-reverse" "target="blank">Pick Reverse</a></li>
                                                                <li><a href="https://dev.stackerbee.com/outbound/outbound-posted-sales" "target="blank">Posted Invoice</a></li>
                                                                <li><a href="https://dev.stackerbee.com/invoice/lredit" "target="blank">Update Posted Invoice</a></li>
                                                                <li><a href="https://dev.stackerbee.com/report/posted-invoice-line" "target="blank">Posted Invoice 2</a></li>
                                                                <li><a href="https://dev.stackerbee.com/outbound/lrlog" "target="blank">Posted Invoice Change Log</a></li>
                                </ul> */
}
