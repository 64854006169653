import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPage } from "./store/state/pagehistory";

const Pagetracker = () => {
    const location = useLocation();
    const dispatch = useDispatch();


    console.log("qwerty",location);
    useEffect(() => {
        dispatch(addPage(location.pathname));
    }, [location.pathname, dispatch]);

    return null; // This component doesn’t render anything
};

export default Pagetracker;
