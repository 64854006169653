// import Header from "../../../../Components/Header";
// import PostedPickReportComponent from "./Components/PostedBoxReports-Components";
import Notfound from "../../../Notfound";
// import Footer from "../../../../Components/Footer";

const PostedBoxReport = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      {/* <Header /> */}
      {/* <PostedPickReportComponent /> */}
      <Notfound />
      {/* <Footer /> */}
    </div>
  );
};
export default PostedBoxReport;
