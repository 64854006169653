import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../BinMovement.css";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import {
  FaFileAlt,
  FaSort,
  FaList,
  FaThLarge,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl, FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";

import { NavLink } from "react-router-dom";
import PostedTransferRceiptDropdown from "./Dropdown";

const PostedTransferReceiptComponents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const PostedTransferReceipt = [
    {
      doc_no: "1",
      doc_type: "-",
      source_code: "-",
      source_name: "-",
      get_entry_no: "-",
      no_of_line: "-",
      total_quantity: "-",
      asn_no: "-",
      client: "-",
      location: "-",
      create_date: "-",
      last_update_date: "-",
      created_by: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          {/* <div>
        <a href="#" className="pagetitle px-2" style={{borderBottom:'0px solid'}}>
        {" "}
        Purchase Order
      </a>
        </div> */}
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "5px", width: "100%" }}
          >
            <a
              href="#"
              className="pagetitle px-2"
              style={{ borderBottom: "0px solid" }}
            >
              {" "}
              Posted Transfer Receipt
            </a>
            <a
              to="#"
              style={{ textDecoration: "none" }}
              className="createbtn m-0"
            >
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
            {/* <a href="#" style={{backgroundColor:'#fff',textDecoration:'none'}}  className="importbtn">
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18}  />{" "}
            </span>{" "}
            Import
          </a> */}
          </div>

          {/* filter start here  */}
          {/* <div className="d-flex" style={{gap:'4px'}}>
        <div className=" filterbtn "> <span className="iconbox">  <MdFilterAlt fontSize={18} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <AiOutlineFileExcel fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaFileAlt fontSize={15} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaSort fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <LuRefreshCw fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaListUl fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaThLarge fontSize={14} color="#270b79" /></span> </div>
        </div> */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="table-responsive"
          //  style={{overflow:'visible'}}
        >
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">Action</th>
                <th className="table-header">
                  Document No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Document Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Source Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Source Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gate Entry No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No.of Lines
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  ASN No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Last Update Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {PostedTransferReceipt.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((posted_bin_data, index) => (
                <tr key={index}>
                  <td>
                    <PostedTransferRceiptDropdown />
                  </td>
                  <td>{posted_bin_data.doc_no}</td>
                  <td>{posted_bin_data.doc_type}</td>
                  <td>{posted_bin_data.source_code}</td>
                  <td>{posted_bin_data.source_name}</td>
                  <td>{posted_bin_data.get_entry_no}</td>
                  <td>{posted_bin_data.no_of_line}</td>
                  <td>{posted_bin_data.asn_no}</td>
                  <td>{posted_bin_data.client}</td>
                  <td>{posted_bin_data.location}</td>
                  <td>{posted_bin_data.last_update_date}</td>
                  <td>{posted_bin_data.create_date}</td>
                  <td>{posted_bin_data.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PostedTransferReceiptComponents;
