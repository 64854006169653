import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useState } from "react";
// import "../../PurchaseOrder.css";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport,MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt,FaSort,FaList,FaThLarge} from 'react-icons/fa';
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";

const Purchaseordercomponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "PO-PP/24-25/00015",
      orderDate: "2024-10-01",
      vendorNo: "-",
      orderStatus: "Pending",
      vendorName: "Vendor A",
      address: "123 Street",
      postCode: "12345",
      city: "City A",
      state: "State A",
      country: "Country A",
      masterClient: "DKB",
      locationCode: "GGN",
      creationDate: "2024-09-30",
      createdBy: "Admin",
    },
  ];

  const totalPages = 28;

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     
      <div className="d-flex justify-content-between align-items-center p-2" style={{background:'#fcfdfe'}}>
      <a href="#" className="pagetitle px-2">
        {" "}
        ASN
      </a>
        <div className="d-flex">
          <NavLink to="/purchaseorder/create-purchase-order" className="createbtn m-0">
            <span className="iconbox">
              <MdAdd fontSize={18}/>{" "}
            </span>{" "}
            Add New
          </NavLink>
       
        </div>

        {/* filter start here  */}
        {/* <div className="d-flex" style={{gap:'4px'}}>
        <div className=" filterbtn "> <span className="iconbox">  <MdFilterAlt fontSize={18} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <AiOutlineFileExcel fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaFileAlt fontSize={15} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaSort fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <LuRefreshCw fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaListUl fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaThLarge fontSize={14} color="#270b79" /></span> </div>
        </div> */}
      </div> 
      {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

      <div className="table-responsive" style={{overflow:'visible'}}>
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Action</th>
              <th className="table-header">No.</th>
              <th className="table-header">PO No.</th>
              <th className="table-header">Vendor Code</th>
              <th className="table-header">Vendor Name</th>
              <th className="table-header">Transport Method</th>
              <th className="table-header">PO Received Date</th>
              <th className="table-header">Asn Type</th>
              <th className="table-header">Planned Receipt Date</th>
              <th className="table-header">Creation Date</th>
              <th className="table-header">Last Update</th>
            </tr>
          </thead>
          <tbody>
           <tr>
            <td colSpan={11}>No matching records found</td>
           </tr>
          </tbody>
        </table>
      </div>

      {/* <div className="container mt-2 d-flex justify-content-center align-items-center">
      <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    forcePage={currentPage - 1}
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                        
                                          &lt;
                                         
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                      
                              &gt;
                                      
                                      </li>
                                    }
                                  />
    </div> */}
    </div>
  );
};

export default Purchaseordercomponent;
