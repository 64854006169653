import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Purchaseorder from "./pages/Inbound/Purchase-Order/Purchase-order";
import Create from "./pages/Inbound/Purchase-Order/purchaseorder/Create";
import Updatepoexpiration from "./pages/Inbound/Purchase-Order/update-po-expiration/Update-po-expiration";
import Asn from "./pages/Inbound/Purchase-Order/ASN/Asn";
import Postedasn from "./pages/Inbound/Purchase-Order/Posted-asn/Posted-asn";
import Purchasereturnorder from "./pages/Inbound/Purchase-Return-Order/Purchase-return-order";
import Creategrn from "./pages/Inbound/Create-GRN/Create-GRN";
import Lpnlist from "./pages/Inbound/Create-GRN/LPN-list/Lpnlist";
import Grntobeposted from "./pages/Inbound/Create-GRN/GRN-to-be-Posted/GRN-to-be-Posted";
import Postedgrn from "./pages/Inbound/Create-GRN/Posted-GRN/Posted-grn";
import Postedpurchasereceipt from "./pages/Inbound/Create-GRN/Posted-Purchase-Receipt/Posted-purchase-receipt";
import Postedlpnlist from "./pages/Inbound/Create-GRN/Posted-LPN-List/Posted-lpn-list";
import Lotinformation from "./pages/Inbound/Create-GRN/Lot-Information/Lot-information";
import Serialinformation from "./pages/Inbound/Create-GRN/Serial-Information/Serial-information";
import Createputaway from "./pages/Inbound/Create-Put-away/Create-Put-away/Create-put-away";
import Putawaylist from "./pages/Inbound/Create-Put-away/Put-away-List/Put-away-list";
import Registeredputaway from "./pages/Inbound/Create-Put-away/Registered-Put-away/Registered-put-away";
import Createsalesreturngrn from "./pages/Inbound/Create-Sales-Return-GRN/Create-Sales-Return-GRN/Createsalesreturngrn";
import Salereturntobeposted from "./pages/Inbound/Create-Sales-Return-GRN/Sale-return-to-be-Posted/Salereturntobeposted";
import Postedsalesreturn from "./pages/Inbound/Create-Sales-Return-GRN/Posted-Sales-Return/Postedsalesreturn";
import Createundogrn from "./pages/Inbound/Create-Undo-GRN/Create-Undo-GRN/Create-undo-grn";
import Grntobepostedundo from "./pages/Inbound/Create-Undo-GRN/GRN-to-be-Posted/Grn-to-be-posted";
import Undosalesreturntobeposted from "./pages/Inbound/Create-Undo-GRN/Undo-Sales-Return-to-be-posted/Undo-sales-return-to-be-posted";
import Postedundogrn from "./pages/Inbound/Create-Undo-GRN/Posted-Undo-GRN/Posted-undo-grn";
import Salesordermanual from "./pages/Outbound/Sales-Order/Sales-Order-Manual/Sales-order-manual";
import Createsales from "./pages/Outbound/Sales-Order/Sales-Order-Manual/Create";
import Createsalesorder from "./pages/Outbound/Sales-Order/Sales-Order-Upload/Create";
import Salesorderupload from "./pages/Outbound/Sales-Order/Sales-Order-Upload/Sales-order-upload";
import Salesorderb2c from "./pages/Outbound/Sales-Order/Sales-Order-B2C/Sales-order-b2c";
import Outboundcreatesalesorder from "./pages/Outbound/Sales-Order/Sales-Order-B2C/Create";
import Channelupload from "./pages/Outbound/Sales-Order/Channel-Upload/Channel-upload";
import Salesordermanualreturn from "./pages/Outbound/Sales-Return-Order/Sales-Order-Manual-Return/Sales-order-manual-return";
import Createsalesreturn from "./pages/Outbound/Sales-Return-Order/Sales-Order-Manual-Return/Create";
import Salesorderuploadreturn from "./pages/Outbound/Sales-Return-Order/Sales-Order-Upload-Return/Sales-order-upload-return";
import Createsalesorderreturn from "./pages/Outbound/Sales-Return-Order/Sales-Order-Upload-Return/Create";
import Createshipment from "./pages/Outbound/Create-Shipment/Create-Shipment/Create-shipment";
import Shipmentlist from "./pages/Outbound/Create-Shipment/Shipment-list/Shipment-list";
import Createpick from "./pages/Outbound/Create-Pick/Create-Pick/Create-pick";
import Picklist from "./pages/Outbound/Create-Pick/Pick-list/Pick-list";
import Createreversepickputaway from "./pages/Outbound/Create-Pick/Create-Reverse-Pick-Putaway/Create-reverse-pick-putaway";
import Reverseputawaylist from "./pages/Outbound/Create-Pick/Reverse-Putaway-List/Reverse-putaway-list";
import Notfound from "./pages/Notfound";
import Createboxpacking from "./pages/Outbound/Create-Box-Packing/Create-Box-Packing/Create-box-packing";
import Postedboxlist from "./pages/Outbound/Create-Box-Packing/Posted-Box-List/Posted-box-list";
import Unbox from "./pages/Outbound/Create-Box-Packing/Unbox/Unbox";
import Shipmenttobeposted from "./pages/Outbound/Shipment-To-Be-Posted/Shipment-To-Be-Posted/Shipment-to-be-posted";
import Postedshipment from "./pages/Outbound/Shipment-To-Be-Posted/Posted-Shipment/Posted-shipment";
import Registeredpick from "./pages/Outbound/Posted-Documents/Registered-pick/Registered-pick";
import Postedpurchasereturn from "./pages/Outbound/Posted-Documents/Posted-Purchase-Return/Posted-purchase-return";
import Pickreverse from "./pages/Outbound/Posted-Documents/Pick-Reverse/Pick-reverse";
import Postedinvoice from "./pages/Outbound/Posted-Documents/Posted-Invoice/Posted-invoice";
import Editpostedinvoice from "./pages/Outbound/Posted-Documents/Posted-Invoice/Edit/Edit";
import Postedinvoice2 from "./pages/Outbound/Posted-Documents/Posted-Invoice2/Posted-invoice2";
import Postedinvoicechangelog from "./pages/Outbound/Posted-Documents/Posted-Invoice-Change-Log/Posted-invoice-change-log";
import Manifestlist from "./pages/Outbound/Create-Manifest/Manifest-List/Manifest-list";
import Createmanifest from "./pages/Outbound/Create-Manifest/Manifest-List/Create/Create";
import Postedmanifest from "./pages/Outbound/Create-Manifest/Posted-Manifest/Posted-manifest";

// Start Warehouse Bin-Movement
import BinMovement from "./pages/Warehouse/Bin-Movement/Bin-Movement";
import BinMovementCreate from "./pages/Warehouse/Bin-Movement/BinMovement/Create";

// Start Warehouse Posted Bin-Movement
import PostedBinMovement from "./pages/Warehouse/Bin-Movement/Posted-BinMovement/PostedBinMovement";
import PostedBinMovementCreate from "./pages/Warehouse/Bin-Movement/Posted-BinMovement/Create";

// Start Warehouse Adjustment
import Adjustment from "./pages/Warehouse/Adjustments/Adjustment";
import AdjustmentCreate from "./pages/Warehouse/Adjustments/Adjustment/Create";
import PostedAdjustment from "./pages/Warehouse/Adjustments/Posted-Adjustment/PostedAdjustment";

// Start Warehouse Cycle Count
import CycleCount from "./pages/Warehouse/Cycle Count/CycleCount";
import CycleCountCreate from "./pages/Warehouse/Cycle Count/Cycle-count/Create";
import PostedCycleCount from "./pages/Warehouse/Cycle Count/Posted-Cycle-count/PostedCycleCount";

// Start Warehouse Transfer Order
import TransferOrder from "./pages/Warehouse/Transfer Order/Transfer-Order";
import TransferOrderCreate from "./pages/Warehouse/Transfer Order/TransferOrder/Create";
import PostedTransferShipment from "./pages/Warehouse/Transfer Order/Posted Transfer Shipment/PostedTransferShipment";
import CreateTransferReceipt from "./pages/Warehouse/Transfer Order/Create Transfer Receipt/CreateTransferReceipt";
import TransferReceiptToBePosted from "./pages/Warehouse/Transfer Order/Transfer Receipt To Be Posted/TransferReceiptToBePosted";
import PostedTransferReceipt from "./pages/Warehouse/Transfer Order/Posted Transfer Receipt/PostedTransferReceipt";

// Start Warehouse Assembly Order
import AssemblyOrder from "./pages/Warehouse/Assembly Order/AssemblyOrder";
import AssemblyOrderCreate from "./pages/Warehouse/Assembly Order/AssemblyOrder/Create";
import AssemblyBOMItem from "./pages/Warehouse/Assembly Order/Assembly BOM Item/AssemblyBOMItem";
import AssemblyOrderCreatePick from "./pages/Warehouse/Assembly Order/Assembly Order Create Pick/AssemblyOrderCreatePick";
import AssemblyOrderPickList from "./pages/Warehouse/Assembly Order/Assembly Order Pick List/AssemblyOrderPickList";
import AssemblyOrderToBePosted from "./pages/Warehouse/Assembly Order/Assembly Order To Be Posted/AssemblyOrderToBePosted";
import PostedAssemblyList from "./pages/Warehouse/Assembly Order/Posted Assembly List/PostedAssemblyList";
import AssemblyOrderRegistredPick from "./pages/Warehouse/Assembly Order/Assembly Order Registerd Pick/AssemblyOrderRegisterdPick";
import AssemblyOrderCreatePut from "./pages/Warehouse/Assembly Order/Assembly Order Create Put/AssemblyOrderCreatePut";
import AssemblyOrderPutAwayList from "./pages/Warehouse/Assembly Order/Assembly Order Put-away List/AssemblyOrderPutAwayList";
import ReverseAssemblyOrder from "./pages/Warehouse/Assembly Order/Reverse Assembly Order/ReverseAssemblyOrder";
import BOMMaster from "./pages/Warehouse/Assembly Order/BOM Master/BOMMaster";
import ReverseAssemblyOrderCreatePick from "./pages/Warehouse/Assembly Order/Reverse Assembly Order Create Pick/ReverseAssemblyOrderCreatePick";
import ReverseAssemblyPickList from "./pages/Warehouse/Assembly Order/Reverse Assembly Pick List/ReverseAssemblyPickList";
import ReverseAssemblyOrderToBePosted from "./pages/Warehouse/Assembly Order/Reverse Assembly Order To Be Posted/ReverseAssemblyOrderToBePosted";

// Start Warehouse UOM Conversion
import UOMConversion from "./pages/Warehouse/UOM Conversion/UOMConversion";
import UOMConverionCreate from "./pages/Warehouse/UOM Conversion/UOMConversion/Create";

import UOMConversionPosted from "./pages/Warehouse/UOM Conversion/UOM Conversion Posted/UOMConversion";
import UOMConversionOrder from "./pages/Warehouse/UOM Conversion/UOM Conversion Order/UOMConversionOrder";
import UOMCreatePick from "./pages/Warehouse/UOM Conversion/UOM Create Pick/UOMCreatePick";
import UOMPickList from "./pages/Warehouse/UOM Conversion/UOM Pick List/UOMPickList";
import UOMOrderToBePosted from "./pages/Warehouse/UOM Conversion/UOM Order To Be Posted/UOMOrderToBePosted";
import PostedUOMList from "./pages/Warehouse/UOM Conversion/Posted UOM List/PostedUOMList";
import UOMOrderCreatePutAway from "./pages/Warehouse/UOM Conversion/UOM Order Create Put-Away/UOMOrderCreatePut-Away";
import UOMPutList from "./pages/Warehouse/UOM Conversion/UOM Put List/UOMPutList";
import UOMRegisteredPutAway from "./pages/Warehouse/UOM Conversion/UOM Registered Put-Away/UOMRegisteredPutAway";

// Warehouse : RGP
import RGP from "./pages/Warehouse/RGP/RGP";
import CreateRGP from "./pages/Warehouse/RGP/RGP LIst/Create";
import CreateRGPReceipt from "./pages/Warehouse/RGP/Create RGP Receipt/CreateRGPReceipt";
import PostedRGPShipment from "./pages/Warehouse/RGP/Posted RGP Shipment/PostedRGPShipment";
import RGPToBePosted from "./pages/Warehouse/RGP/RGP To Be Posted/RGPToBePosted";
import RGPPosted from "./pages/Warehouse/RGP/RGP Posted/RGPPosted";

// Report : Inventory
import InventoryReport from "./pages/Reports/Inventory/Inventory Report/InventoryReport";
import InventoryBinWise from "./pages/Reports/Inventory/Inventory Bin Wise/InventoryBinWise";
import InventoryItemWise from "./pages/Reports/Inventory/Inventory Item Wise/InventoryItemWise";
import InventoryClientWise from "./pages/Reports/Inventory/Inventory Client Wise/InventoryClientWise";
import LotInventoryBinWise from "./pages/Reports/Inventory/Lot Inventory Bin Wise/LotInventoryBinWise";
import BlankBinReport from "./pages/Reports/Inventory/Blank Bin Report/BlankBinReport";
import LotInventoryItemWise from "./pages/Reports/Inventory/Lot Inventory Item Wise/LotInventoryItemWise";
import PivotInventoryBinWise from "./pages/Reports/Inventory/Pivot Inventory Bin Wise/PivotInventoryBinWise";
import ItemAging from "./pages/Reports/Inventory/Item Aging/ItemAging";
import BinAging from "./pages/Reports/Inventory/Bin Aging/BinAging";
import ItemMovement from "./pages/Reports/Inventory/Item Movement/ItemMovement";
import ItemQauntities from "./pages/Reports/Inventory/Item Qauntities/ItemQauntities";
import UOMReport from "./pages/Reports/Inventory/UOM Report/UOMReport";

// Report : Inbound
import InboundCycleDetails from "./pages/Reports/Inbound/Inbound Cycle Details/InboundCycleDetails";
import InboundCycleReports from "./pages/Reports/Inbound/Inbound Cycle Report/InboundCycleReports";
import PurchaseReceiptReport from "./pages/Reports/Inbound/Purchase Receipt Report/PurchaseReceiptReport";
import PutAwayReport from "./pages/Reports/Inbound/Put Away Reports/PutAwayReport";
import PurchaseOderCycle from "./pages/Reports/Inbound/Purchase Order Cycle/PurchaseOderCycle";
import PedingPutAway from "./pages/Reports/Inbound/Pending Put-away/PedingPutAway";
import PostedGRNReport from "./pages/Reports/Inbound/Posted GRN Report/PostedGRNReport";

// Report : Outbound
import PostedShipmentReport from "./pages/Reports/Outbound/Posted Shipment Report/PostedShipmentReport";
import PostedPickReport from "./pages/Reports/Outbound/Posted Pick Report/PostedPickReport";
import PostedBoxReport from "./pages/Reports/Outbound/Posted Box Reports/PostedBoxReports";
import SalesOrderCycle from "./pages/Reports/Outbound/Sales Order Cycle/SalesOrderCycle";
import PendingPick from "./pages/Reports/Outbound/Pending Pick/PendingPick";
import PendingShipment from "./pages/Reports/Outbound/Pending Shipment/PendingShipment";
import DeliveryReport from "./pages/Reports/Outbound/Delivery Report/DeliveryReport";
import OutboundCycle from "./pages/Reports/Outbound/Outbound Cycle/OutboundCycle";
import BackOrderReport from "./pages/Reports/Outbound/Back Order Report/BackOrderReport";
import SalesReturnReport from "./pages/Reports/Outbound/Sales Return Report/SalesReturnReport";
import PostedManifestReport from "./pages/Reports/Outbound/Posted Manifest/PostedManifestReport";
import PostedBox from "./pages/Reports/Outbound/Posted Box/PostedBox";
import OutboundCycleDetails from "./pages/Reports/Outbound/Outbound Cycle Details/OutboundCycleDetails";
import PickReservation from "./pages/Reports/Outbound/Pick Reservation/PickReservation";

// Report : Ledger Entries
import LedgerEntries from "./pages/Reports/Ledger Entries/LedgerEntries";
// import WarehouseEntry from "./pages/Reports/Ledger Entries/Warehouse Entry/WarehouseReport";
import ValueEntryReport from "./pages/Reports/Ledger Entries/Value Entry Report/ValueEntryReport";
import GSTLedgerReport from "./pages/Reports/Ledger Entries/GST Ledger Report/GSTLedgerReport";
import ItemForecastAnalysis from "./pages/Reports/Ledger Entries/Item Forecast Analysis/ItemForecastAnalysis";

// Report : Auto Email
import AutoEmail from "./pages/Reports/Auto Email/AutoEmail";

// Report : Billing Reports
import BillingReports from "./pages/Reports/Billing Reports/BillingReports";

// Report : Final Posting Date
import FinalPostingData from "./pages/Reports/Final Posting Data/FinalPostingData";

// Report : Warehouse Report
import WarehouseReport from "./pages/Reports/Warehouse Report/WarehouseReport";
import BinMovementReport from "./pages/Reports/Warehouse Report/Binmovement Report/BinmovementReport";
import AssemblyConsolidated from "./pages/Reports/Warehouse Report/Assembly Consolidated/AssemblyConsolidated";
import AssemblyReport from "./pages/Reports/Warehouse Report/Assembly Report/AssemblyReport";
import TOReport from "./pages/Reports/Warehouse Report/TO Report/TOReport";

// User Management
import UserManagement from "./pages/User Management/UserManagement";
import UserManagementCreate from "./pages/User Management/UserManagement/Create";
import Roles from "./pages/User Management/Roles/Roles";
import LoginUsers from "./pages/User Management/Login Users/LoginUsers";
import ActivityLog from "./pages/User Management/Activity Log/ActivityLog";
import { useSelector } from "react-redux";
import Pagetracker from "./Pagetracker";
import Supertab from "./pages/Login/Supertab";

const App = () => {

  const pageHistory = useSelector((state) => state);

  console.log("pagehistory is here",pageHistory);
  return (
    <>
    <Pagetracker />
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />

      {/* Inbound --> Purchase Order */}
      <Route path="/purchaseorder/purchase-orders" element={<Purchaseorder />} />
      <Route path="/purchaseorder/create-purchase-order" element={<Create />} />
      <Route
        path="/purchaseorder/update_po_expiration"
        element={<Updatepoexpiration />}
      />
      <Route path="/asn/inboundasn" element={<Asn />} />
      <Route path="/asn/postedinboundasn" element={<Postedasn />} />

      {/* Inbound --> Purchase Return Order */}
      <Route
        path="/purchasereturn/purchase-return-order"
        element={<Purchasereturnorder />}
      />

      {/* Inbound --> Create GRN */}
      <Route path="/inbound/create-warehouse-receipt" element={<Creategrn />} />
      <Route path="/lpn/lpn-list" element={<Lpnlist />} />
      <Route path="/inbound/warehouse-receipt-list" element={<Grntobeposted />} />
      <Route path="/inbound/posted-warehouse" element={<Postedgrn />} />
      <Route
        path="/inbound/posted-purchase"
        element={<Postedpurchasereceipt />}
      />
      <Route path="/lpn/posted-lpn-list" element={<Postedlpnlist />} />
      <Route path="/lot_info/lot-information" element={<Lotinformation />} />
      <Route
        path="/lot_info/serial-information"
        element={<Serialinformation />}
      />

      {/* Inbound --> Put-away List */}
      <Route path="/putaway/create-in-putaway" element={<Createputaway />} />
      <Route path="/putaway/inputaway" element={<Putawaylist />} />
      <Route path="/putaway/registred-putaway" element={<Registeredputaway />} />

      {/* Inbound --> Create Sales Return GRN */}
      <Route
        path="/return_sales/create-sales-return-receipt"
        element={<Createsalesreturngrn />}
      />
      <Route
        path="/return_sales/sales-return-to-be-posted"
        element={<Salereturntobeposted />}
      />
      <Route
        path="/return_sales/posted-sales-return"
        element={<Postedsalesreturn />}
      />

      {/* Inbound --> Create Undo GRN */}
      <Route path="/undo/create-grn" element={<Createundogrn />} />
      <Route path="/undo/undo-grnt-list" element={<Grntobepostedundo />} />
      <Route
        path="/undo/undo-grnt-list-sales"
        element={<Undosalesreturntobeposted />}
      />
      <Route path="/undo/undo-posted-grn" element={<Postedundogrn />} />

      {/* Outbound --> Sales Order */}
      <Route
        path="/outbound/outbound-sales-order"
        element={<Salesordermanual />}
      />
      <Route
        path="/outbound/outbound-create-sales-order"
        element={<Createsales />}
      />
      <Route
        path="/outbound/outbound-sales-order-upload"
        element={<Salesorderupload />}
      />
      <Route
        path="/outbound/outbound-upload-sales-order"
        element={<Createsalesorder />}
      />
      <Route
        path="/outbound/outbound-b2c-sales-order"
        element={<Salesorderb2c />}
      />
      <Route
        path="/outbound/outbound-create-sales-order"
        element={<Outboundcreatesalesorder />}
      />
      <Route path="/amazonoredrs" element={<Channelupload />} />

      {/* Outbound --> Sales Order Return */}
      <Route
        path="/outbound/outbound-return-sales-order"
        element={<Salesordermanualreturn />}
      />
      <Route
        path="/outbound/outbound-create-return-sales-order"
        element={<Createsalesreturn />}
      />
      <Route
        path="/outbound/outbound-return-sales-orderupload"
        element={<Salesorderuploadreturn />}
      />
      <Route
        path="/outbound/outbound-create-return-sales-orderupload"
        element={<Createsalesorderreturn />}
      />

      {/* Outbound --> Create Shipment */}
      <Route
        path="/shipment/outbound-create-shipment"
        element={<Createshipment />}
      />
      <Route path="/shipment/outbound-shipment" element={<Shipmentlist />} />

      {/* Outbound --> Create pick */}
      <Route path="/pick/create-pick" element={<Createpick />} />
      <Route path="/pick/outbound-pick" element={<Picklist />} />
      <Route
        path="/putaway/reverse-pick-putaway"
        element={<Createreversepickputaway />}
      />
      <Route
        path="/putaway/reverse-pick-putaway"
        element={<Reverseputawaylist />}
      />
      <Route
        path="/putaway/posted-reverse-pick-putaway"
        element={<Notfound />}
      />

      {/* Outbound --> Create Box Packing */}
      <Route path="/outbound/outbound-box" element={<Createboxpacking />} />
      <Route path="/outbound/outbound-box-list" element={<Postedboxlist />} />
      <Route path="/outbound/unbox" element={<Unbox />} />

      {/* Outbound --> Shipment To Be Posted */}
      <Route
        path="/shipment/outbound-shippost"
        element={<Shipmenttobeposted />}
      />
      <Route
        path="/shipment/outbound-posted-shipment"
        element={<Postedshipment />}
      />

      {/* Outbound --> Posted Documents */}
      <Route path="/outbound/outbound-posted-pick" element={<Registeredpick />} />
      <Route
        path="/outbound/outbound-posted-return"
        element={<Postedpurchasereturn />}
      />
      <Route path="/outbound/pick-reverse" element={<Pickreverse />} />
      <Route path="/outbound/outbound-posted-sales" element={<Postedinvoice />} />
      <Route path="/invoice/lredit" element={<Editpostedinvoice />} />
      <Route path="/report/posted-invoice-line" element={<Postedinvoice2 />} />
      <Route path="/outbound/lrlog" element={<Postedinvoicechangelog />} />

      {/* Outbound --> Posted Documents */}
      <Route path="/outbound/outbound-manifest" element={<Manifestlist />} />
      <Route
        path="/outbound/outbound-create-manifest"
        element={<Createmanifest />}
      />
      <Route
        path="/outbound/outbound-post-manifest"
        element={<Postedmanifest />}
      />

      {/* Warehouse --> Bin-Movement */}
      <Route path="/Bin-Movement/binmovement" element={<BinMovement />}></Route>
      <Route
        path="/bin-movement/binmovement-create"
        element={<BinMovementCreate />}
      ></Route>

      {/* Warehouse --> Posted Bin-Movement */}
      <Route
        path="/Bin-Movement/posted-binmovement"
        element={<PostedBinMovement />}
      ></Route>
      <Route
        path="/Bin-Movement/posted-binmovement"
        element={<PostedBinMovementCreate />}
      ></Route>

      {/* Warehouse --> Adjustment */}
      <Route path="/adjustment" element={<Adjustment />}></Route>
      <Route
        path="/adjustment/adjustment-create"
        element={<AdjustmentCreate />}
      ></Route>
      <Route
        path="/adjustment/posted-adjustment"
        element={<PostedAdjustment />}
      ></Route>

      {/* Warehouse --> Cycle Count */}
      <Route path="/cycle-count" element={<CycleCount />}></Route>
      <Route
        path="/cycle-count/cyclecount-create"
        element={<CycleCountCreate />}
      ></Route>
      <Route
        path="/cycle-count/posted-cycle-count"
        element={<PostedCycleCount />}
      ></Route>

      {/* Warehouse --> Transfer Order */}
      <Route path="/transfer-order" element={<TransferOrder />}></Route>
      <Route
        path="/transfer-order/transfer-order-create"
        element={<TransferOrderCreate />}
      ></Route>
      <Route
        path="/transfer-order/posted-transfer-shipment"
        element={<PostedTransferShipment />}
      ></Route>
      <Route
        path="/transfer-order/create-transfer-receipt"
        element={<CreateTransferReceipt />}
      ></Route>
      <Route
        path="/transfer-order/transfer-receipt-to-be-posted"
        element={<TransferReceiptToBePosted />}
      ></Route>
      <Route
        path="/transfer-order/posted-transfer-receipt"
        element={<PostedTransferReceipt />}
      ></Route>

      {/* Warehouse --> Assembly Order */}
      <Route path="/assembly-order" element={<AssemblyOrder />}></Route>
      <Route
        path="/assembly-order/assembly-order-create"
        element={<AssemblyOrderCreate />}
      ></Route>
      <Route
        path="/assembly-order/assembly-bom-item"
        element={<AssemblyBOMItem />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-create-pick"
        element={<AssemblyOrderCreatePick />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-pick-list"
        element={<AssemblyOrderPickList />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-toBe-Posted"
        element={<AssemblyOrderToBePosted />}
      ></Route>
      <Route
        path="/assembly-order/posted-assembly-list"
        element={<PostedAssemblyList />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-registerd-pick"
        element={<AssemblyOrderRegistredPick />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-create-put"
        element={<AssemblyOrderCreatePut />}
      ></Route>
      <Route
        path="/assembly-order/assembly-order-put-away-list"
        element={<AssemblyOrderPutAwayList />}
      ></Route>
      <Route
        path="/assembly-order/reverse-assembly-order"
        element={<ReverseAssemblyOrder />}
      ></Route>
      <Route path="/assembly-order/BOM-Master" element={<BOMMaster />}></Route>
      <Route
        path="/assembly-order/reverse-assembly-order-create-pick"
        element={<ReverseAssemblyOrderCreatePick />}
      ></Route>
      <Route
        path="/assembly-order/reverse-assembly-pick-list"
        element={<ReverseAssemblyPickList />}
      ></Route>
      <Route
        path="/assembly-order/reverse-assembly-order-toBe-Posted"
        element={<ReverseAssemblyOrderToBePosted />}
      ></Route>

      {/* Warehouse --> OUM Conversion */}
      <Route path="/UOM-Conversion" element={<UOMConversion />}></Route>
      <Route
        path="/UOM-Conversion/UOMConversion-Create"
        element={<UOMConverionCreate />}
      ></Route>
      <Route
        path="/UOM-Conversion/UOM-Conversion-posted"
        element={<UOMConversionPosted />}
      ></Route>
      <Route
        path="/UOM-Conversion/UOM-Conversion-order"
        element={<UOMConversionOrder />}
      ></Route>
      <Route
        path="/UOM-Conversion/UOM-Create-Pick"
        element={<UOMCreatePick />}
      ></Route>
      <Route
        path="/UOM-Conversion/UOM-Pick-List"
        element={<UOMPickList />}
      ></Route>
      <Route
        path="/UOM-Conversion/UOM-order-to-be-posted"
        element={<UOMOrderToBePosted />}
      ></Route>
      <Route
        path="/UOM-Conversion/posted-uom-list"
        element={<PostedUOMList />}
      ></Route>
      <Route
        path="/UOM-Conversion/uom-order-create-put-away"
        element={<UOMOrderCreatePutAway />}
      ></Route>
      <Route
        path="/UOM-Conversion/uom-put-list"
        element={<UOMPutList />}
      ></Route>
      <Route
        path="/UOM-Conversion/uom-registered-put-away"
        element={<UOMRegisteredPutAway />}
      ></Route>

      {/* Warehouse --> RGP */}
      <Route path="/RGP" element={<RGP />}></Route>
      <Route path="/RGP/RGPCreate" element={<CreateRGP />}></Route>
      <Route
        path="/RGP/Create-RGP-Receipt"
        element={<CreateRGPReceipt />}
      ></Route>
      <Route
        path="/RGP/Posted-RGP-Shipment"
        element={<PostedRGPShipment />}
      ></Route>
      <Route path="/RGP/RGP-To-Be-Posted" element={<RGPToBePosted />}></Route>
      <Route path="/RGP/RGP-Posted" element={<RGPPosted />}></Route>

      {/* Report --> Inventory */}
      <Route
        path="/Inventory/Inventory-Report"
        element={<InventoryReport />}
      ></Route>
      <Route
        path="/Inventory/Inventory-Bin-Wise"
        element={<InventoryBinWise />}
      ></Route>
      <Route
        path="/Inventory/Inventory-Item-Wise"
        element={<InventoryItemWise />}
      ></Route>
      <Route
        path="/Inventory/Inventory-Client-Wise"
        element={<InventoryClientWise />}
      ></Route>
      <Route
        path="/Inventory/Lot-Inventory-Bin-Wise"
        element={<LotInventoryBinWise />}
      ></Route>
      <Route
        path="/Inventory/Blank-Bin-Report"
        element={<BlankBinReport />}
      ></Route>
      <Route
        path="/Inventory/Lot-Inventory-Item-Wise"
        element={<LotInventoryItemWise />}
      ></Route>
      <Route
        path="/Inventory/Pivot-Inventory-Bin-Wise"
        element={<PivotInventoryBinWise />}
      ></Route>
      <Route path="/Inventory/Item-Aging" element={<ItemAging />}></Route>
      <Route path="/Inventory/Bin-Aging" element={<BinAging />}></Route>
      <Route path="/Inventory/Item-Movement" element={<ItemMovement />}></Route>
      <Route
        path="/Inventory/Item-Qauntities"
        element={<ItemQauntities />}
      ></Route>
      <Route path="/Inventory/UOM-Report" element={<UOMReport />}></Route>

      {/* Report --> Inbound */}
      <Route
        path="/Inbound/Inbound-Cycle-Details"
        element={<InboundCycleDetails />}
      ></Route>
      <Route
        path="/Inbound/Posted-GRN-Report"
        element={<PostedGRNReport />}
      ></Route>
      <Route
        path="/Inbound/Inbound-Cycle-Reports"
        element={<InboundCycleReports />}
      ></Route>
      <Route
        path="/Inbound/Purchase-Receipt-Reports"
        element={<PurchaseReceiptReport />}
      ></Route>
      <Route
        path="/Inbound/Put-away-Reports"
        element={<PutAwayReport />}
      ></Route>
      <Route
        path="/Inbound/Purchase-Order-Cycle"
        element={<PurchaseOderCycle />}
      ></Route>
      <Route
        path="/Inbound/Pending-Put-Away"
        element={<PedingPutAway />}
      ></Route>

      {/* Report --> Outbound */}
      <Route
        path="/Outbound/Posted-Shipment-Report"
        element={<PostedShipmentReport />}
      ></Route>
      <Route
        path="/Outbound/Posted-Pick-Report"
        element={<PostedPickReport />}
      ></Route>
      <Route
        path="/Outbound/Posted-Box-Reports"
        element={<PostedBoxReport />}
      ></Route>
      <Route
        path="/Outbound/Sales-Order-Cycle"
        element={<SalesOrderCycle />}
      ></Route>
      <Route path="/Outbound/Pending-Pick" element={<PendingPick />}></Route>
      <Route
        path="/Outbound/Pending-Shipment"
        element={<PendingShipment />}
      ></Route>
      <Route
        path="/Outbound/Delivery-Report"
        element={<DeliveryReport />}
      ></Route>
      <Route
        path="/Outbound/Outbound-Cycle"
        element={<OutboundCycle />}
      ></Route>
      <Route
        path="/Outbound/Back-Order-Report"
        element={<BackOrderReport />}
      ></Route>
      <Route
        path="/Outbound/Sales-Return-Report"
        element={<SalesReturnReport />}
      ></Route>
      <Route
        path="/Outbound/Posted-Manifest"
        element={<PostedManifestReport />}
      ></Route>
      <Route path="/Outbound/Posted-Box" element={<PostedBox />}></Route>
      <Route
        path="/Outbound/Outbound-Cycle-Details"
        element={<OutboundCycleDetails />}
      ></Route>
      <Route
        path="/Outbound/Pick-Reservation"
        element={<PickReservation />}
      ></Route>

      {/* Report --> Ledger Entries */}
      <Route
        path="/LedgerEntries/Ledger-Entries-Report"
        element={<LedgerEntries />}
      ></Route>
      <Route
        path="/LedgerEntries/Warehouse-Entry"
        element={<WarehouseReport />}
      ></Route>
      <Route
        path="/LedgerEntries/Value-Entry-Report"
        element={<ValueEntryReport />}
      ></Route>
      <Route
        path="/LedgerEntries/GST-Ledger-Report"
        element={<GSTLedgerReport />}
      ></Route>
      <Route
        path="/LedgerEntries/Item-Forecast-Analysis"
        element={<ItemForecastAnalysis />}
      ></Route>

      {/* Report --> Auto Email */}
      <Route path="/Auto-Email" element={<AutoEmail />}></Route>

      {/* Report --> Billing Reports*/}
      <Route path="/Billing-Reports" element={<BillingReports />}></Route>

      {/* Report --> Final Posting Date*/}
      <Route path="/Final-Posting-Date" element={<FinalPostingData />}></Route>

      {/* Report --> Warehouse Report*/}
      <Route
        path="/Warehouse-Report/Adjustment-Report"
        element={<WarehouseReport />}
      ></Route>
      <Route
        path="/Warehouse-Report/BinMovement-Report"
        element={<BinMovementReport />}
      ></Route>
      <Route
        path="/Warehouse-Report/Assembly-Consolidated"
        element={<AssemblyConsolidated />}
      ></Route>
      <Route
        path="/Warehouse-Report/Assembly-Report"
        element={<AssemblyReport />}
      ></Route>
      <Route path="/Warehouse-Report/TO-Report" element={<TOReport />}></Route>

      {/* User Management --> User Management*/}
      <Route path="/manage-users" element={<UserManagement />}></Route>
      <Route
        path="/manage-users/User-Management-Create"
        element={<UserManagementCreate />}
      ></Route>
      <Route path="/manage-users/Roles" element={<Roles />}></Route>
      <Route path="super" element={<Supertab />}></Route>
      <Route path="/manage-users/Login-Users" element={<LoginUsers />}></Route>
      <Route
        path="/manage-users/Activity-Log"
        element={<ActivityLog />}
      ></Route>
    </Routes>

    </>
  );
};

export default App;

