import React from "react";

const Footer = () => {
  return (
    <div style={{marginTop:'auto'}} className="footer">
      <p className="m-0">
        © 2018 - All Right Reserve, <span style={{color:'#0d6efd'}}> StackerBee Technologies</span>
      </p>
    </div>
  );
};

export default Footer;
