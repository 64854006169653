import Header from "../../../../Components/Header";
import InventoryItemWiseComponent from "./Components/InventoryItemWise-Components";
import Footer from "../../../../Components/Footer";

const InventoryItemWise = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <InventoryItemWiseComponent />
      <Footer />
    </div>
  );
};
export default InventoryItemWise;
