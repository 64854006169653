import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
// import "./style.css";
import { NavLink } from "react-router-dom";

const Purchaseform = () => {
  const [tab,settab] = useState(1)
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{backgroundColor:'#eff4f9'}}>
              <ul
                className="nav nav-tabs"
                style={{ color: "white", border: "none" }}
              >
                <li
                  className="nav-item"
                  style={{
                    backgroundColor: "#fff"
                  }}
                >
                  <a className={tab == 1 ? "nav-link active text-white" : "nav-link text-white"} onClick={()=>{settab(1)}} href="#">
                    General Information {tab == 1 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />} 
                  </a>
                </li>
             
              </ul>
            </div>
      <div className="row d-flex justify-content-between"  style={{marginTop:'14px'}}>
        <div className="col-12 ">

          {/* tab one is here  */}
         {tab == 1 ? <div
            className="row sidebarbox m-0"
            style={{ padding: "8px", borderRadius: "5px" }}
          >
            
            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Invoice no.</label>
               
                <select name="" id="" className="form-control inputdesign" style={{fontSize:'13px',color:'grey'}} >
                  <option value="">Select No.</option>
                </select>
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">DSP Code</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                 
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">LR No.</label>
                <input type="text" className="form-control inputdesign"    style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Weight</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Eway Bill</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Eway Bill Creation Date</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Pre Invoice Date</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">POD</label>
                <input type="file" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Pre Invoice No</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                  // value="2024-10-07 09:21:02"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">New DSP Code</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">New Lr No.</label>
                <input
                //  type="date"
      
                 className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">New Weight</label>
                <input type="text" 
      
                className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Delivery Status</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                Eway Bill Expiry Date</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Delivery Date</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">LR Date</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            </div>
          </div> : ''} 


 


  
        </div>

      </div>
    </div>
  );
};

export default Purchaseform;
