import Header from "../../../../Components/Header";
import PivotInventoryBinWiseComponent from "./Components/PivotInventoryBinWise-Components";
import Footer from "../../../../Components/Footer";

const PivotInventoryBinWise = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PivotInventoryBinWiseComponent />
      <Footer />
    </div>
  );
};
export default PivotInventoryBinWise;
