import React from "react";
import Header from "../../../Components/Header";
import Adjustmentcomponent from "./Components/Adjustment-components";
import Footer from "../../../Components/Footer";

const Adjustment = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <Adjustmentcomponent />
      <Footer />
    </div>
  );
};

export default Adjustment;