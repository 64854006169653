import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt, MdOutlineClose, MdOutlineRemoveRedEye, MdLocalPrintshop } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge, FaRegEdit } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
        <a href="#" className="pagetitle px-2">
        {" "}
        Posted Sales
      </a>
        <div className="d-flex" style={{ gap: "5px" }}>
          <NavLink
            to="/invoice/lredit"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
          
        </div>

    
      </div>
   
      <div
        className="showfilter justify-content-between p-2" >
        <div className="row" style={{ width: "100%" }}>
         

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value3}
              className="form-control"
              onChange={(e) => setValue3(e.target.value)}
              onFocus={() => setFocused3(true)}
              onBlur={() => setFocused3(false)}
              style={{
                color: value3 || focused3 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value3 && !focused3 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value4}
              className="form-control"
              onChange={(e) => setValue4(e.target.value)}
              onFocus={() => setFocused4(true)}
              onBlur={() => setFocused4(false)}
              style={{
                color: value4 || focused4 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value4 && !focused4 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-29px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Date
              </span>
            )}
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Customer Name"
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Select No."
              className="form-control"
            />
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Order No."
              className="form-control"
            />
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Order Type"
              className="form-control"
            />
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Posted No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="DSP No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Posted No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="AWB No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Posted No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Manifest No."
              className="form-control"
            />
          </div>


          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Posted No."
              className="form-control"
            />
          </div>

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <select name="" id="" className="form-control" style={{color:'gray',fontSize:'13px'}}>
              <option value="">Select the Manifest Yes/No</option>
            </select>
           
          </div>

        </div>
      </div>
     

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Action</th>
              <th className="table-header">No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Posting Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Order No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Pre Invoice No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Customer No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Order Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Document Status <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Address <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Address 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to City <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to State <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Pin Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Country <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Phone <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Phone 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Bill to Email <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Address <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Address 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to City <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to State <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Country <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Pin Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Phone <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Phone 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ship to Email <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Quantity <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Value <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Posted By <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Client Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">AWB No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">DSP Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Delivery Status <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Current Location <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Order Type <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Delivery Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Last Update Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Expected Delivery Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Promised Delivery Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Weight <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Item Weight <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Delivery Location Status <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Manifest No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}><p className='d-flex m-0 justify-content-center' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>INV-PP/21-22/100381</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>2021-11-23 13:17:23</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>SO-PP/21-22/101036</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>EDAC-C00390</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>2021-11-23</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Ready to Dispatch</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Cmr International School</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Nead Malla Reddy Gardens, old Bowenpally</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Secunderab, Hyderabad, Telangana</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Hyderabad</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>TL</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>500011</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>IN</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Cmr International School</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Nead Malla Reddy Gardens, old Bowenpally</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Secunderab, Hyderabad, Telangana</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Hyderabad</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>TL</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>IN</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>500011</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>100.0000</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>100.0000</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>2021-11-23 13:15:09</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Rahul</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>DKB</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>PO</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>37</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Dlvry</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>B2B</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0000-00-00 00:00:00</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>-</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>-</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0000-00-00</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>1</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
               <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>
      </div>
    </div>
  );
};

export default Lpntable;
