import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../PurchaseReturnOrder.css";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const ItemMovementComponents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const ItemMovementComp = [
    {
      item_no: "-",
      description: "-",
      style_code: "-",
      opening: "-",
      grn_qty: "-",
      undo_grn_qty: "-",
      pr_qty: "-",
      sale_qty: "-",
      sale_return: "-",
      positive_adj: "-",
      negative_adj: "-",
      to_qty: "-",
      tr_qty: "-",
      kitting_Consumption_Qty: "-",
      kitting_Output_Qty: "-",
      rgp_in_qty: "-",
      rgp_out_qty: "-",
      uom_conversion: "-",
      closing: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Item Movement
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="col-12 container-fluid mt-2 d-flex justify-content-between align-items-center"
          style={{ background: "#fff" }}
        >
          <div className="col-6">
            <h6 className="table-header mt-2">Bin Aging</h6>
          </div>
          <div className="col-6">
            <h6 className="align-item-start table-header mt-2">
              Thursday 17th of October 2024
            </h6>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Item No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Style Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Opening
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  GRN Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Undo GRN Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  PR Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sale Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sales Return
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Positive Adj.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Negative Adj.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  To Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Tr Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Kitting Consumption Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Kitting Output Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  RGP In Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  RGP Out Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  UOM Conversion
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Closing
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {ItemMovementComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((item_movement_data, index) => (
                <tr key={index}>
                  <td>{item_movement_data.item_no}</td>
                  <td>{item_movement_data.description}</td>
                  <td>{item_movement_data.style_code}</td>
                  <td>{item_movement_data.opening}</td>
                  <td>{item_movement_data.grn_qty}</td>
                  <td>{item_movement_data.undo_grn_qty}</td>
                  <td>{item_movement_data.pr_qty}</td>
                  <td>{item_movement_data.sale_qty}</td>
                  <td>{item_movement_data.sale_return}</td>
                  <td>{item_movement_data.positive_adj}</td>
                  <td>{item_movement_data.negative_adj}</td>
                  <td>{item_movement_data.to_qty}</td>
                  <td>{item_movement_data.tr_qty}</td>
                  <td>{item_movement_data.kitting_Consumption_Qty}</td>
                  <td>{item_movement_data.kitting_Output_Qty}</td>
                  <td>{item_movement_data.rgp_in_qty}</td>
                  <td>{item_movement_data.rgp_out_qty}</td>
                  <td>{item_movement_data.uom_conversion}</td>
                  <td>{item_movement_data.closing}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ItemMovementComponents;
