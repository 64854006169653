import React from "react";
import Header from "../../../Components/Header";
import TransferOrderComponent from "./Components/Transfer-Order-Components";
import Footer from "../../../Components/Footer";

const TransferOrder = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <TransferOrderComponent />
      <Footer />
    </div>
  );
};

export default TransferOrder;
