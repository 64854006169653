import React from 'react'
import Header from '../../../../Components/Header'
import Footer from '../../../../Components/Footer'
import Posted_Binmovementcomponent from './Components/posted-BinMovement-components'

const PostedBinMovement = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
        <Header />
        <Posted_Binmovementcomponent />
        <Footer />
        </div>
  )
}

export default PostedBinMovement;