import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >

<a href="#" className="pagetitle px-2">
        {" "}
        Create Shipment
      </a>
        <div className="d-flex" style={{gap:'5px'}}>
          <NavLink
            to="/outbound/outbound-create-sales-order"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
          Add New
          </NavLink>
          
        </div>

     
      </div>
  
      <div
        className="showfilter justify-content-between p-2"
        style={{
          background: "#fff"
        }}
      >
        <div className="row" style={{ width: "100%" }}>

        <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">No.</option>
            </select>
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value}
              className="form-control"
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              style={{
                color: value || focused ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value && !focused && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Order Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value2}
              className="form-control"
              onChange={(e) => setValue2(e.target.value)}
              onFocus={() => setFocused2(true)}
              onBlur={() => setFocused2(false)}
              style={{
                color: value2 || focused2 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value2 && !focused2 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Order Date
              </span>
            )}
          </div>
          <div className="col-3">
            {" "}
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Document Type">Bill to Customer Name</option>
            </select>{" "}
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Ship to Customer Name</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
                      <option value="Select Document Type">Sales Order</option>
              <option value="Select Document Type">Transfer Order</option>
              <option value="Select Document Type">Purchase Return</option>
              <option value="Select Document Type">RGP Order</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Select Order Type</option>
              <option value="Select Gate Entry No">B2B</option>
              <option value="Select Gate Entry No">B2C</option>
            </select>
          </div>
          <div className="col-3">
            <input type="text" placeholder="AWB No." style={{fontSize:'13px',color:'gray'}} className="form-control" />
            {/* <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">AWB No.</option>
            </select> */}
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Created By</option>
            </select>
          </div>


          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value3}
              className="form-control"
              onChange={(e) => setValue3(e.target.value)}
              onFocus={() => setFocused3(true)}
              onBlur={() => setFocused3(false)}
              style={{
                color: value3 || focused3 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value3 && !focused3 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value4}
              className="form-control"
              onChange={(e) => setValue4(e.target.value)}
              onFocus={() => setFocused4(true)}
              onBlur={() => setFocused4(false)}
              style={{
                color: value4 || focused4 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value4 && !focused4 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-29px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Date
              </span>
            )}
          </div>
      
        </div>
      </div>
  

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header"> <input type="checkbox" name="" id="" /> </th>
              <th className="table-header">No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Type <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Customer No <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Customer Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Lines <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Qty <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Created By <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Creation Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Client <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" name="" id="" />
                  </td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>EDAC-C00454</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>	B2C</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>EDAC-C00390</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Cmr International School</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>1</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>100</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>admin</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>2023-02-02</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>2023-02-02 22:45:21</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>DKB</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>GGN</td>
                  
                </tr>
              ))}
          
          </tbody>
        </table>
      </div>
{/* <div className="container-fuild p-0 mt-2 d-flex justify-content-end align-items-center">
<NavLink
            to="/outbound/outbound-create-sales-order"
            className="btn createbtn m-0 mb-2"
          >

            Create Shipment
          </NavLink>
</div> */}
      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>

      </div>
    </div>
  );
};

export default Lpntable;
