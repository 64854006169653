import React from 'react'
import Header from '../../../../Components/Header'
import Footer from '../../../../Components/Footer'
import Lpntable from './Components/Lpn-table'

const Salesordermanualreturn = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
        <Header />
        <Lpntable />
        <Footer />
    </div>
  )
}

export default Salesordermanualreturn