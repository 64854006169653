import Header from "../../../../Components/Header";
import BackOrderReportComponent from "./Components/BackOrderReport-Components";
import Footer from "../../../../Components/Footer";

const BackOrderReport = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <BackOrderReportComponent />
      <Footer />
    </div>
  );
};
export default BackOrderReport;
