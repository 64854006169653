import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../PurchaseReturnOrder.css";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import AssemblyOrderPickListDropDown from "./Dropdown";

const AssemblyOrderPickListComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const AssemblyOrderPickList = [
    {
      id: "Pick-PP/21-22/101147",
      source_doc_no: "Asmbly-PP/21-22/100438",
      client: "DKB",
      location_code: "GGN",
      pick_type: "Order Wise",
      creation_date: "2021-10-18 10:45:01",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Assembly Order Pick List
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Action <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">No.</th>
                <th className="table-header">
                  Source Document No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Pick Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Creation Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {AssemblyOrderPickList.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((assembly_pick_list_data, index) => (
                <tr key={index}>
                  <td>
                    <AssemblyOrderPickListDropDown />
                  </td>
                  <td>{assembly_pick_list_data.id}</td>
                  <td>{assembly_pick_list_data.source_doc_no}</td>
                  <td>{assembly_pick_list_data.client}</td>
                  <td>{assembly_pick_list_data.location_code}</td>
                  <td>{assembly_pick_list_data.pick_type}</td>
                  <td>{assembly_pick_list_data.creation_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AssemblyOrderPickListComponent;
