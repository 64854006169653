import { React, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import "./style.css";
import { NavLink } from "react-router-dom";

const CycleCountForm = () => {
  const [tab, settab] = useState(1);
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{ backgroundColor: "#eff4f9" }}>
        <ul className="nav nav-tabs" style={{ color: "white", border: "none" }}>
          <li
            className="nav-item"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <a
              className={
                tab == 1 ? "nav-link active text-white" : "nav-link text-white"
              }
              onClick={() => {
                settab(1);
              }}
              href="#"
            >
              General Information{" "}
              {/* {tab == 1 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />} */}
            </a>
          </li>
        </ul>
      </div>
      <div
        className="row d-flex justify-content-between"
        style={{ marginTop: "14px" }}
      >
        <div className="col-5 ">
          {/* tab one is here  */}
          {tab == 1 ? (
            <div
              className="row sidebarbox m-0"
              style={{
                padding: "8px",
                borderRadius: "5px",
                height: "70vh",
                overflowY: "scroll",
              }}
            >
              <div className="col-md-6 mt-4">
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    No.<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="PO-PP/24-25/00021"
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Posting Date
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="DKB"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Document Date
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="2024-10-07 09:21:02"
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Location Code
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="GGN"
                    readOnly
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-7">
          <div className="table-responsive newscroller">
            <table className="table table-bordered">
              <thead style={{ backgroundColor: "#4B0082", color: "white" }}>
                <tr>
                  {/* <th style={{textWrap:'nowrap',textAlign:'left'}}>Action</th> */}
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Item No.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Entry Type
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Zone Code
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Bin Code
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Qty.(Calculated)
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Qty.(Phys. Inventory)
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Qauntity
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Ok Qauntity
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Not Ok Quantity
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Reason Code
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Reason Descriptions
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Unit Amount
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Lot No.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Serial No.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Expiration Date.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Warranty Date.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Manufacture Date.
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    MRP Price
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>UOM</th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Line Amount
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Document Amount
                  </th>
                  <th style={{ textWrap: "nowrap", textAlign: "left" }}>
                    Line Id
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>
                    <button className="btn btn-primary btn-sm" style={{backgroundColor:'#ec642a',border:'1px solid #ec642a',borderRadius:'0px'}}>
                      <FaPlus />
                    </button>
                  </td> */}
                  <td>
                    <input
                      type="text"
                      className="form-control tableinput"
                      placeholder="Select Item No"
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      style={{ width: "55px" }}
                      className="form-control tableinput"
                      // value={0}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      style={{ width: "67px" }}
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`0 %`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      style={{ width: "165px" }}
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      style={{ width: "140px" }}
                      className="form-control tableinput"
                      value={`10`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      style={{ width: "140px" }}
                      className="form-control tableinput"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      style={{ width: "140px" }}
                      className="form-control tableinput"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      style={{ width: "140px" }}
                      className="form-control tableinput"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      style={{ width: "67px" }}
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`0 %`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      style={{ width: "165px" }}
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      style={{ width: "67px" }}
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`0 %`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      style={{ width: "165px" }}
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      style={{ width: "165px" }}
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      className="form-control tableinput"
                      value={`₹00.00`}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p style={{ color: "#270B79", fontSize: "13px" }}>
              <FiPlus /> Add Row
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CycleCountForm;
