import Header from "../../../../Components/Header";
import TOReportComponent from "./Components/TOReport-Components";
import Footer from "../../../../Components/Footer";

const TOReport = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <TOReportComponent />
      <Footer />
    </div>
  );
};
export default TOReport;
