import Header from "../../../Components/Header";
import BillingReportsComponents from "./Components/BillingReports-Components";
import Footer from "../../../Components/Footer";

const BillingReports = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <BillingReportsComponents />
      <Footer />
    </div>
  );
};
export default BillingReports;
