import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const PurchaseReceiptReportComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const PurchaseReceiptReportComp = [
    {
      Location_Code: "-",
      Id: "-",
      Linenum: "-",
      Asnno: "-",
      Asnqty: "-",
      Outstandingquantity: "-",
      Receivedqty: "-",
      Qty_To_Received: "-",
      Quantity: "-",
      Discount: "-",
      Lot_No: "-",
      Serial_No: "-",
      Item_Expiration_Date: "-",
      Discount_Amount: "-",
      Price: "-",
      Unit_Cost: "-",
      Line_Amount: "-",
      Barcode: "-",
      Masterclient: "-",
      Mrpprice: "-",
      Description: "-",
      Description2: "-",
      Unitofmessure: "-",
      Purchase_Unitofmeasure: "-",
      Sales_Unitofmeasure: "-",
      Blocked: "-",
      Itemtrackingcode: "-",
      Stylecode: "-",
      Length: "-",
      Height: "-",
      Breadth: "-",
      Cubecapacity: "-",
      Weight: "-",
      Fragile: "-",
      Isiemei: "-",
      Outboundqc: "-",
      Itemcategory: "-",
      Isdangrous: "-",
      Itemgroupcode: "-",
      Netweight: "-",
      Vendornumber: "-",
      Hsnsaccode: "-",
      Item_Type: "-",
      Gstgroupcode: "-",
      Warranty_Date: "-",
      Manufacture_Date: "-",
      Expiration_Date: "-",
      Assemblybom: "-",
      Qc_Policy: "-",
      Lead_Time: "-",
      Minimum_Stock: "-",
      Cycle_Count_Code: "-",
      Last_Phys_Invt_Date: "-",
      Last_Counting_Period_Update: "-",
      Next_Counting_Start_Date: "-",
      Next_Counting_End_Date: "-",
      Item_Cost_After_Discount: "-",
      Cost_Amount_Without_Discount: "-",
      Igst_Amount: "-",
      Cgst_Amount: "-",
      Sgst_Amount: "-",
      Igst: "-",
      Cgst: "-",
      Sgst: "-",
      Gst_Group_Type: "-",
      gst_base_amount: "-",
      Gst: "-",
      Total_Gst_Amount: "-",
      Pick_Type: "-",
      Purch_Rcpt_Header_Id: "-",
      Coup114_On_Category: "-",
      capacity_unit: "-",
      capacity: "-",
      class: "-",
      product: "-",
      size: "-",
      Subcategorycode: "-",
      Contain: "-",
      Family: "-",
      Itemcategorycode: "-",
      Productgroupcode: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Purchase Receipt Report
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Id
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Line No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Asn No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Asn Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Out Standing Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Received Qauntity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qty To Received
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qauntity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Lot No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Serial No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Expiration Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Discount Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Price
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Unit Cost
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Line Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Barcode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Master Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  MRP Price
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description 2
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Unitofmessure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Purchase Unitofmeasure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sales Unitofmeasure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Blocked
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Tracking Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Style Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Length
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Height
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Breadth
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cube Capacity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Fragile
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Isiemei
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  OutBoundQc
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Category
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  IsDangrous
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  ItemGroupCode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Net Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Hsnsaccode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gstgroupcode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Warranty Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Manufacture Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Expiration Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Assemblybom
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Qc Policy
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Lead Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Minimum Stock
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cycle Count Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Last Phys Invt Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Last Counting Period Update
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Next Counting Start Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Next Counting End Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Cost After Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Igst Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cgst Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sgst Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Igst
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Cgst
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sgst
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gst Group Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gst Base Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gst
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Total Gst Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Pick Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Purch Rcpt Header Id
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Coup114 On Category
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Capacityunit
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Capacity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Class
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Product
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Size
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sub Category Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Contain
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Family
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Category Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Product Group Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {PurchaseReceiptReportComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((inbound_cycle_reports_data, index) => (
                <tr key={index}>
                  <td>{inbound_cycle_reports_data.Location_Code}</td>
                  <td>{inbound_cycle_reports_data.Id}</td>
                  <td>{inbound_cycle_reports_data.Linenum}</td>
                  <td>{inbound_cycle_reports_data.Asnno}</td>
                  <td>{inbound_cycle_reports_data.Asnqty}</td>
                  <td>{inbound_cycle_reports_data.Outstandingquantity}</td>
                  <td>{inbound_cycle_reports_data.Receivedqty}</td>
                  <td>{inbound_cycle_reports_data.Qty_To_Received}</td>
                  <td>{inbound_cycle_reports_data.Quantity}</td>
                  <td>{inbound_cycle_reports_data.Discount}</td>
                  <td>{inbound_cycle_reports_data.Lot_No}</td>
                  <td>{inbound_cycle_reports_data.Serial_No}</td>
                  <td>{inbound_cycle_reports_data.Item_Expiration_Date}</td>
                  <td>{inbound_cycle_reports_data.Discount_Amount}</td>
                  <td>{inbound_cycle_reports_data.Price}</td>
                  <td>{inbound_cycle_reports_data.Unit_Cost}</td>
                  <td>{inbound_cycle_reports_data.Line_Amount}</td>
                  <td>{inbound_cycle_reports_data.Barcode}</td>
                  <td>{inbound_cycle_reports_data.Masterclient}</td>
                  <td>{inbound_cycle_reports_data.Mrpprice}</td>
                  <td>{inbound_cycle_reports_data.Description}</td>
                  <td>{inbound_cycle_reports_data.Description2}</td>
                  <td>{inbound_cycle_reports_data.Unitofmessure}</td>
                  <td>{inbound_cycle_reports_data.Purchase_Unitofmeasure}</td>
                  <td>{inbound_cycle_reports_data.Sales_Unitofmeasure}</td>
                  <td>{inbound_cycle_reports_data.Blocked}</td>
                  <td>{inbound_cycle_reports_data.Itemtrackingcode}</td>
                  <td>{inbound_cycle_reports_data.Stylecode}</td>
                  <td>{inbound_cycle_reports_data.Length}</td>
                  <td>{inbound_cycle_reports_data.Height}</td>
                  <td>{inbound_cycle_reports_data.Breadth}</td>
                  <td>{inbound_cycle_reports_data.Cubecapacity}</td>
                  <td>{inbound_cycle_reports_data.Weight}</td>
                  <td>{inbound_cycle_reports_data.Fragile}</td>
                  <td>{inbound_cycle_reports_data.Isiemei}</td>
                  <td>{inbound_cycle_reports_data.Outboundqc}</td>
                  <td>{inbound_cycle_reports_data.Itemcategory}</td>
                  <td>{inbound_cycle_reports_data.Isdangrous}</td>
                  <td>{inbound_cycle_reports_data.Itemgroupcode}</td>
                  <td>{inbound_cycle_reports_data.Netweight}</td>
                  <td>{inbound_cycle_reports_data.Vendornumber}</td>
                  <td>{inbound_cycle_reports_data.Hsnsaccode}</td>
                  <td>{inbound_cycle_reports_data.Item_Type}</td>
                  <td>{inbound_cycle_reports_data.Gstgroupcode}</td>
                  <td>{inbound_cycle_reports_data.Warranty_Date}</td>
                  <td>{inbound_cycle_reports_data.Manufacture_Date}</td>
                  <td>{inbound_cycle_reports_data.Expiration_Date}</td>
                  <td>{inbound_cycle_reports_data.Assemblybom}</td>
                  <td>{inbound_cycle_reports_data.Qc_Policy}</td>
                  <td>{inbound_cycle_reports_data.Lead_Time}</td>
                  <td>{inbound_cycle_reports_data.Minimum_Stock}</td>
                  <td>{inbound_cycle_reports_data.Cycle_Count_Code}</td>
                  <td>{inbound_cycle_reports_data.Last_Phys_Invt_Date}</td>
                  <td>
                    {inbound_cycle_reports_data.Last_Counting_Period_Update}
                  </td>
                  <td>{inbound_cycle_reports_data.Next_Counting_Start_Date}</td>
                  <td>{inbound_cycle_reports_data.Next_Counting_End_Date}</td>
                  <td>{inbound_cycle_reports_data.Item_Cost_After_Discount}</td>
                  <td>
                    {inbound_cycle_reports_data.Cost_Amount_Without_Discount}
                  </td>
                  <td>{inbound_cycle_reports_data.Igst_Amount}</td>
                  <td>{inbound_cycle_reports_data.Cgst_Amount}</td>
                  <td>{inbound_cycle_reports_data.Sgst_Amount}</td>
                  <td>{inbound_cycle_reports_data.Igst}</td>
                  <td>{inbound_cycle_reports_data.Cgst}</td>
                  <td>{inbound_cycle_reports_data.Sgst}</td>
                  <td>{inbound_cycle_reports_data.Gst_Group_Type}</td>
                  <td>{inbound_cycle_reports_data.gst_base_amount}</td>
                  <td>{inbound_cycle_reports_data.Gst}</td>
                  <td>{inbound_cycle_reports_data.Total_Gst_Amount}</td>
                  <td>{inbound_cycle_reports_data.Pick_Type}</td>
                  <td>{inbound_cycle_reports_data.Purch_Rcpt_Header_Id}</td>
                  <td>{inbound_cycle_reports_data.Coup114_On_Category}</td>
                  <td>{inbound_cycle_reports_data.capacity_unit}</td>
                  <td>{inbound_cycle_reports_data.capacity}</td>
                  <td>{inbound_cycle_reports_data.class}</td>
                  <td>{inbound_cycle_reports_data.product}</td>
                  <td>{inbound_cycle_reports_data.size}</td>
                  <td>{inbound_cycle_reports_data.Subcategorycode}</td>
                  <td>{inbound_cycle_reports_data.Contain}</td>
                  <td>{inbound_cycle_reports_data.Family}</td>
                  <td>{inbound_cycle_reports_data.Itemcategorycode}</td>
                  <td>{inbound_cycle_reports_data.Productgroupcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PurchaseReceiptReportComponent;
