import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
         <a href="#" className="pagetitle px-2">
        {" "}
        Create Pick
      </a>
        <div className="d-flex" style={{gap:'5px'}}>
          <NavLink
            to="/outbound/outbound-create-sales-order"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
         
        </div>

      
      </div>
     
      <div
        className="showfilter bg-white justify-content-between p-2"
        
    
      >
        <div className="row" style={{ width: "100%" }}>

        <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Pick Type</option>
              <option value="Select Gate Entry No">Order Wise</option>
              <option value="Select Gate Entry No">Zone Wise</option>
              <option value="Select Gate Entry No">Shipment Wise</option>
              <option value="Select Gate Entry No">Customer Wise</option>
              <option value="Select Gate Entry No">Per Item Wise</option>
            </select>
          </div>
          <div className="col-3">
            {" "}
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Document Type">Pick Policy</option>
              <option value="Select Document Type">FIFO</option>
              <option value="Select Document Type">MAX</option>
              <option value="Select Document Type">FEFP</option>
              <option value="Select Document Type">FMFO</option>
            </select>{" "}
          </div>
 
          
        </div>
      </div>
   

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">

            <tr><th className="table-header" >#</th>
									<th className="table-header" >Shipment No <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Total Order <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Total Line <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Order No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Qty. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Client <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Location <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Source Type <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
									<th className="table-header" >Created By <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
								</tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>
                   <input type="checkbox" name="" id="" />
                  </td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>SO-PP/22-23/101114</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>1</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>1</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>SO-PP/22-23/101091</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>100</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>DKB</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>GGN</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Sales Order</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>2024-10-14 12:46:24</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Alam21</td>
                   
                </tr>
              ))}
          
          </tbody>
        </table>
      </div>

  
      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
        {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}

        <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>

        
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
             <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>

      {/* <div className="container-fluid p-0 mt-2 mb-2 d-flex justify-content-end align-items-center">
      <NavLink
            to="/outbound/outbound-create-sales-order"
            className="btn createbtn m-0"
          >
            Create Pick
          </NavLink>
      </div> */}
      </div>
    </div>
  );
};

export default Lpntable;
