import Header from "../../../Components/Header";
import RolesComponents from "./Components/Roles-Components";
import Footer from "../../../Components/Footer";

const Roles = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <RolesComponents />
      <Footer />
    </div>
  );
};
export default Roles;
