import Header from "../../../../Components/Header";
import PostedBoxComponent from "./Components/PostedBox-Components";
import Footer from "../../../../Components/Footer";

const PostedBox = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedBoxComponent />
      <Footer />
    </div>
  );
};
export default PostedBox;
