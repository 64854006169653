import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../BinMovement.css";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import {
  FaFileAlt,
  FaSort,
  FaList,
  FaThLarge,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl, FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";

import { NavLink } from "react-router-dom";
import CycleCountDropdown from "./Dropdown";

const CycleCountComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const CycleCount = [
    {
      id: "BB-PP/21-22/100067	",
      postingdate: "-",
      entry_type: "-",
      document_date: "2024-06-07",
      location_code: "GGN",
      status: " ",
      created_by: " ",
      createDate: "2024-09-30",
      lastUpdated: "2024-09-30",
      Updated_by: "-",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          {/* <div>
        <a href="#" className="pagetitle px-2" style={{borderBottom:'0px solid'}}>
        {" "}
        Purchase Order
      </a>
        </div> */}
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "5px", width: "100%" }}
          >
            <a
              href="#"
              className="pagetitle px-2"
              style={{ borderBottom: "0px solid" }}
            >
              {" "}
              Cycle Count
            </a>
            <NavLink
              to="/cycle-count/cyclecount-create"
              style={{ textDecoration: "none" }}
              className="createbtn m-0"
            >
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </NavLink>
            {/* <a href="#" style={{backgroundColor:'#fff',textDecoration:'none'}}  className="importbtn">
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18}  />{" "}
            </span>{" "}
            Import
          </a> */}
          </div>

          {/* filter start here  */}
          {/* <div className="d-flex" style={{gap:'4px'}}>
        <div className=" filterbtn "> <span className="iconbox">  <MdFilterAlt fontSize={18} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <AiOutlineFileExcel fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaFileAlt fontSize={15} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaSort fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <LuRefreshCw fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaListUl fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaThLarge fontSize={14} color="#270b79" /></span> </div>
        </div> */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="table-responsive"
          //  style={{overflow:'visible'}}
        >
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">Action</th>
                <th className="table-header">
                  No. <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posting Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Entry Type{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Document Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Status <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Create By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Create Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Last Updated{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Updated By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {CycleCount.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((adjustment_data, index) => (
                <tr key={index}>
                  <td>
                    <CycleCountDropdown />
                  </td>
                  <td>{adjustment_data.id}</td>
                  <td>{adjustment_data.postingdate}</td>
                  <td>{adjustment_data.entry_type}</td>
                  <td>{adjustment_data.document_date}</td>
                  <td>{adjustment_data.location_code}</td>
                  <td>{adjustment_data.status}</td>
                  <td>{adjustment_data.created_by}</td>
                  <td>{adjustment_data.createDate}</td>
                  <td>{adjustment_data.lastUpdated}</td>
                  <td>{adjustment_data.Updated_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CycleCountComponent;
