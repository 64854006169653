import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaUser, FaCog } from 'react-icons/fa';
import { MdLogout,MdOutlinePayment,MdTrackChanges } from "react-icons/md";
import './Style/Profiledropdown.css';

const Profiledropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="user-dropdown">
      <DropdownToggle caret className="btn btn-primary">
        <FaUser className="user-icon" /> Alam21
      </DropdownToggle>
      <DropdownMenu right>
        {/* <DropdownItem header>Alam21</DropdownItem> */}
        <DropdownItem className='pb-1'>
          <FaUser color='#0d6efd' fontSize={14} /> <span style={{color:'#0d6efd',fontSize:'13px'}}>
             Alam21
            </span>
        </DropdownItem>
        <DropdownItem className='pb-1'>
          <MdTrackChanges color='#0d6efd' fontSize={14} /><span style={{color:'#0d6efd',fontSize:'13px'}}> Change Password </span>
        </DropdownItem>
        <DropdownItem className='pb-1'>
          <FaCog color='#0d6efd' fontSize={14} />
          <span style={{color:'#0d6efd',fontSize:'13px'}}> Settings</span>
        </DropdownItem>
        <DropdownItem className='pb-1'>
          <MdOutlinePayment color='#0d6efd' fontSize={14} /> <span style={{color:'#0d6efd',fontSize:'13px'}}> Payment Info </span>
        </DropdownItem>
        <DropdownItem className='pb-1'>
          <MdLogout color='#0d6efd' fontSize={14} /> <span style={{color:'#0d6efd',fontSize:'13px'}}> Logout
 </span>        </DropdownItem>
        {/* <DropdownItem divider /> */}
        {/* <DropdownItem className="text-danger">
          <FaSignOutAlt /> Logout
        </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Profiledropdown;
