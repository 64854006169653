import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport,MdFilterAlt, MdLocalPrintshop, MdOutlineRemoveRedEye, MdOutlineClose } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt,FaSort,FaList,FaThLarge, FaRegEdit} from 'react-icons/fa';
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Table = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
//   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div style={{backgroundColor:'white'}}>
      <div className="d-flex justify-content-between align-items-center p-2" style={{background:'#fcfdfe'}}>
      <a href="#" className="pagetitle px-2">
        Posted Sales Return
      </a>
        <div className="d-flex">
          <NavLink to="/purchaseorder/create-purchase-order" className="createbtn m-0">
            <span className="iconbox">
              <MdAdd fontSize={18}/>{" "}
            </span>{" "}
            Add New
          </NavLink>
          {/* <a href="#" style={{backgroundColor:'#fff'}} className="btn importbtn">
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18}  />{" "}
            </span>{" "}
            Import
          </a> */}
        </div>

       
      </div> 
    
      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Action <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Order No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Ref. No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Gate Entry No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Customer Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Customer City <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Created by <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Posting Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>  <p className='d-flex justify-content-center m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p> </td>
                  <td>{order.id}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.vendorNo}</td>
                  <td>{order.orderStatus}</td>
                  <td>Bright Angles Education Centre</td>
                  <td>{order.address}</td>
                  <td>Krish</td>
                  <td>{order.creationDate}</td>
                  <td>2021-11-26 06:07:58</td>
                </tr>
              ))}
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>  <p className='d-flex justify-content-center m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p> </td>
                  <td>{order.id}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.vendorNo}</td>
                  <td>{order.orderStatus}</td>
                  <td>Bright Angles Education Centre</td>
                  <td>{order.address}</td>
                  <td>Krish</td>
                  <td>{order.creationDate}</td>
                  <td>2021-11-26 06:07:58</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
      <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                    //   filterdata(e.selected * 40);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    forcePage={currentPage - 1}
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                        
                                        <FaAnglesLeft color="grey" size={13} />
                                         
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                      
                                      <FaAnglesRight color="grey" size={13} />
                                      
                                      </li>
                                    }
                                  />
    </div>
    </div>
    </div>
  );
};

export default Table;
