import React from "react";
import Header from "../../../Components/Header";
import OUMConversionComponent from "./Components/UOMConversion-Components";
import Footer from "../../../Components/Footer";

const UOMConversion = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <OUMConversionComponent />
      <Footer />
    </div>
  );
};

export default UOMConversion;
