import Header from "../../../../Components/Header";
import PurchaseReceiptReportComponent from "./Components/PurchaseReceiptReport-Components";
import Footer from "../../../../Components/Footer";

const PurchaseReceiptReport = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PurchaseReceiptReportComponent />
      <Footer />
    </div>
  );
};
export default PurchaseReceiptReport;
