import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../PurchaseReturnOrder.css";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const Purchasereturnordercomponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const purchaseOrders = [
    {
      id: "PO-PP/24-25/00015",
      orderDate: "2024-10-01",
      vendorNo: "-",
      orderStatus: "Pending",
      vendorName: "Vendor A",
      address: "123 Street",
      postCode: "12345",
      city: "City A",
      state: "State A",
      country: "Country A",
      masterClient: "DKB",
      locationCode: "GGN",
      creationDate: "2024-09-30",
      createdBy: "Admin",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Purchase Return Order
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <NavLink
              to="/purchaseorder/create-purchase-order"
              className="createbtn m-0"
            >
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </NavLink>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="d-flex justify-content-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <div className="row" style={{ width: "100%", height: "35px" }}>
            <div className="col-2">
              {" "}
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Document Type"> Order No</option>
              </select>{" "}
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No"> Order Status</option>
              </select>
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No"> Vendor Name</option>
              </select>
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No"> Vendor No</option>
              </select>
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No">Vendor City</option>
              </select>
            </div>
            <div className="col-2">
              {/* <input type="date" style={{fontSize:"13px"}} className="form-control" /> */}
              <input
                type="date"
                value={value}
                className="form-control"
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                style={{
                  color: value || focused ? "black" : "gray",
                  fontSize: "13px",
                }}
                placeholder="Select date"
              />
              {!value && !focused && (
                <span
                  style={{
                    position: "relative",
                    pointerEvents: "none",
                    color: "gray",
                    top: "-30px",
                    left: "2px",
                    backgroundColor: "#fff",
                    padding: "2px 43px 2px 8px",
                    fontSize: "13px",
                  }}
                >
                  Date
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Action <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No. <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Status{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Name{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Address{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Post Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor City{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor State{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Country{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Master Client{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Creation Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <p className="d-flex m-0" style={{ gap: "5px" }}>
                        <span>
                          <MdLocalPrintshop className="actionbtn" />
                        </span>
                        <span>
                          <MdOutlineRemoveRedEye className="actionbtn" />
                        </span>
                        <span>
                          <FaRegEdit className="actionbtn" />
                        </span>

                        <span>
                          <MdOutlineClose className="actionbtn" />
                        </span>
                      </p>
                    </td>
                    <td style={{ textWrap: "nowrap" }}>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <p className="d-flex m-0" style={{ gap: "5px" }}>
                        <span>
                          <MdLocalPrintshop className="actionbtn" />
                        </span>
                        <span>
                          <MdOutlineRemoveRedEye className="actionbtn" />
                        </span>
                        <span>
                          <FaRegEdit className="actionbtn" />
                        </span>

                        <span>
                          <MdOutlineClose className="actionbtn" />
                        </span>
                      </p>
                    </td>
                    <td>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <p className="d-flex m-0" style={{ gap: "5px" }}>
                        <span>
                          <MdLocalPrintshop className="actionbtn" />
                        </span>
                        <span>
                          <MdOutlineRemoveRedEye className="actionbtn" />
                        </span>
                        <span>
                          <FaRegEdit className="actionbtn" />
                        </span>

                        <span>
                          <MdOutlineClose className="actionbtn" />
                        </span>
                      </p>
                    </td>
                    <td>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Purchasereturnordercomponent;
