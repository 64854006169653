import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
// import "./style.css";
import { NavLink } from "react-router-dom";

const Purchaseform = () => {
  const [tab,settab] = useState(1)
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{backgroundColor:'#eff4f9'}}>
              <ul
                className="nav nav-tabs"
                style={{ color: "white", border: "none" }}
              >
                <li
                  className="nav-item"
                  style={{
                    backgroundColor: "#fff"
                  }}
                >
                  <a className={tab == 1 ? "nav-link active text-white" : "nav-link text-white"} onClick={()=>{settab(1)}} href="#">
                    General Information {tab == 1 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />} 
                  </a>
                </li>
                <li className="nav-item"  style={{
                    backgroundColor: "#fff"
                  }}>
                  <a className={tab == 3 ? "nav-link active text-white" : "nav-link text-white"} onClick={()=>{settab(3)}} href="#">
                    Details {tab == 3 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
                  </a>
                </li>
              </ul>
            </div>
      <div className="row d-flex justify-content-between"  style={{marginTop:'14px'}}>
        <div className="col-12 ">

          {/* tab one is here  */}
         {tab == 1 ? <div
            className="row sidebarbox m-0"
            style={{ padding: "8px", borderRadius: "5px" }}
          >
            
            <div className="col-md-4 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">No.<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                  value="PO-PP/24-25/00021"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Client Code</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                  value="GGN"
                  readOnly
                />
              </div>
         
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Location Code</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to State</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to PIN Code</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Shipment Method</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to GST</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
             
            </div>

            <div className="col-md-4 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Customer No.<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                  // value="2024-10-07 09:21:02"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to Name<span style={{color:'red'}}>*</span></label>
                <input type="text" className="form-control inputdesign"    style={{fontSize:'0.8rem'}} />
              </div>
              
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Order Date<span style={{color:'red'}}>*</span></label>
                <input type="text" 
                  value="2024-10-07 09:21:02"
                className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to Address</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Reference No.</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">DSP Code</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
             
            </div>

            <div className="col-md-4 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Order Type<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                  value="DKB"
                  readOnly
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Status<span style={{color:'red'}}>*</span></label>
                <input
                //  type="date"
                value="Open"
                 className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to Phone</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Bill to city</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Channel</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">AWB No.(Assign)</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            
            </div>

          </div> : ''} 


 


          {/* tab three is here  */}
          {tab == 3 ? <div
            className="row sidebarbox m-0"
            style={{ padding: "8px", borderRadius: "5px",height:'70vh',overflowY:'scroll' }}
          >
            <div className="col-md-4" >
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Code</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                />
              </div>
            
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Name</label>
                <input type="text" className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Address</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
           
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Address 2</label>
                <input type="text" className="form-control inputdesign" readOnly  style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Country</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Requested Delivery Date </label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
            
            </div>

            <div className="col-md-4" >
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to GST</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"
                />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to Phone</label>
                <input type="text" className="form-control inputdesign"  readOnly  style={{fontSize:'0.8rem'}} />
              </div>
            
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end"> Ship to Phone 2</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to E-Mail ID</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
 
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Currency</label>
                <input type="text" className="form-control inputdesign" readOnly  style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Transport Method</label>
                <input type="text" className="form-control inputdesign" readOnly  style={{fontSize:'0.8rem'}} />
              </div>
            </div>



            <div className="col-md-4">
            <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Remarks</label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                />
              </div>

              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to State</label>
                <input type="text" className="form-control inputdesign" style={{fontSize:'0.8rem'}}  />
              </div>

              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to City</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Ship to PIN Code</label>
                <input type="text" className="form-control inputdesign" readOnly  style={{fontSize:'0.8rem'}} />
              </div>

              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Is Hold</label>
                <input type="text" className="form-control inputdesign"   style={{fontSize:'0.8rem'}} />
              </div>

            </div>
          </div> : ''} 
        </div>
      </div>
    </div>
  );
};

export default Purchaseform;
