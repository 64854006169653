import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
         <a href="#" className="pagetitle px-2">
        {" "}
        Lr Edit Log
      </a>
        <div className="d-flex" style={{ gap: "5px" }}>
          <NavLink
            to="/invoice/lredit"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New 
          </NavLink>
         
        </div>

    
      </div>
   
      <div
        className="showfilter justify-content-between p-2"
      >
        <div className="row" style={{ width: "100%" }}>
         

     

   

          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Invoice No."
              className="form-control"
            />
          </div>

 



 

          
        </div>
      </div>
 

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              {/* <th className="table-header">Action</th> */}
              <th className="table-header">Invoice No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Old Lr <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">New Lr <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Old DSP <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">New DSP <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Updated Time <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header"> Updated By <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>INV-PP/21-22/100381</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>20</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>331</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>0</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>Demo</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>2021-11-11 12:38:56</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>GYL</td>
          
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="container-fuild mt-2 d-flex justify-content-between align-items-center px-1">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
               <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>
      </div>
    </div>
  );
};

export default Lpntable;
