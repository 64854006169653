import Header from "../../../../Components/Header";
import PendingPickComponent from "./Components/PendingPick-Components";
import Footer from "../../../../Components/Footer";

const PendingPick = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PendingPickComponent />
      <Footer />
    </div>
  );
};
export default PendingPick;
