import React from "react";
import Header from "../../../../Components/Header";
import CreateRGPReceiptComponents from "./Components/CreateRGPReceipt-Components";
import Footer from "../../../../Components/Footer";
import { FaOtter } from "react-icons/fa";

const CreateRGPReceipt = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <CreateRGPReceiptComponents />
      <Footer />
    </div>
  );
};

export default CreateRGPReceipt;
