import Header from "../../Components/Header";
import UserManagementComponents from "./Components/UserManagement-Components";
import Footer from "../../Components/Footer";

const UserManagement = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <UserManagementComponents />
      <Footer />
    </div>
  );
};
export default UserManagement;
