import Header from "../../../Components/Header";
import LoginUsersComponents from "./Components/LoginUsers-Components";
import Footer from "../../../Components/Footer";

const LoginUsers = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <LoginUsersComponents />
      <Footer />
    </div>
  );
};
export default LoginUsers;
