import Footer from "../../../Components/Footer"
import Header from "../../../Components/Header"
import Grntable from "./Components/GRN-table"

const Creategrn = () => {
    return (
      <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
      <Header />
      <Grntable />
      <Footer />
      </div>
    )
  }
  
  export default Creategrn