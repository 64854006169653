import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";

const Form = () => {
  const [tab,settab] = useState(1)
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{backgroundColor:'#eff4f9'}}>
              <ul
                className="nav nav-tabs"
                style={{ color: "white", border: "none" }}
              >
                <li
                  className="nav-item"
                  style={{
                    backgroundColor: "#fff"
                  }}
                >
                  <a className={tab == 1 ? "nav-link active text-white" : "nav-link text-white"} onClick={()=>{settab(1)}} href="#">
                    General Information
                  </a>
                </li>
              </ul>
            </div>
      <div className="row d-flex justify-content-between"  style={{marginTop:'14px'}}>
        <div className="col-12 ">

          {/* tab one is here  */}
         <div
            className="row  m-0"
            style={{ padding: "8px", borderRadius: "5px" }}
          >
            
            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Select PO<span style={{color:'red'}}>*</span></label>
                {/* <input
                  type="text"
                  style={{fontSize:'0.8rem'}}
                  className="form-control inputdesign"
                  readOnly
                /> */}

                <select name="" style={{fontSize:'13px'}} className="form-control" id="">
                    <option value="">Select Purchase Order</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <div className="mb-3 " >
                <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">Expiration<span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  style={{fontSize:'0.8rem'}} 
                  className="form-control inputdesign"

                  readOnly
                />
              </div>
            </div>
          </div> 

        </div>

      </div>
    </div>
  );
};

export default Form;
