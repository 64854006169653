import React from "react";
import { MdApartment } from "react-icons/md";
import img from "../assets/sb_logo.svg";
import { IoMdSettings, IoMdNotifications } from "react-icons/io";
import { IoAppsSharp } from "react-icons/io5";
import Profiledropdown from "./Profiledropdown";
import Mainmenu from "./Main-menu";
import { useNavigate } from "react-router-dom";
import Tabs from "./Tabs";

const Header = () => {

  const nvg = useNavigate()
  return (
    <>
    <header className="header d-flex justify-content-between align-items-center">
      {/* Left side: Hamburger and Logo */}
      <div className="d-flex align-items-center">
        <p className="m-0">
          <Mainmenu />
        </p>
        <div className="logo mt-0">
          <img src={img} onClick={()=>{nvg('/dashboard')}} style={{ maxHeight: "34px",cursor:'pointer' }} alt="logo" />
        </div>
      </div>
      {/* Right side: Icons and Dropdown */}
      <div className="d-flex align-items-center mx-1">
        <a href="#" className="me-3 d-flex">
          <IoMdSettings color="white" size={20} />
        </a>
        <a href="#" className="me-3 d-flex">
          <IoMdNotifications color="white" size={20} />
        </a>
        <a href="#" className="me-3 d-flex">
          <IoAppsSharp color="white" size={19} />
        </a>
        {/* <a
          href="#"
          className="me-2 d-flex justify-content-center align-items-center text-decoration-none custom-design-btn"
        >
          <MdApartment color="white" size={16} />
          &nbsp;
          <p className="p-0 m-0" style={{ color: "white", fontSize: "13px" }}>
            DKB |{" "}
          </p>
          <p className="p-0 m-0" style={{ color: "white", fontSize: "13px" }}>
            &nbsp;GGN
          </p>
        </a> */}
        <div className="dropdown">
          <Profiledropdown />
        </div>
      </div>
    </header>
    <Tabs />
    </>
  );
};

export default Header;
