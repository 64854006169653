import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import token from './state/token'
import history from './state/pagehistory'
import { adminApi } from './api/adminapi'

export const store = configureStore({
  reducer: {
    token:token,
    history:history,
    [adminApi.reducerPath]: adminApi.reducer,

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminApi.middleware),
})

setupListeners(store.dispatch)