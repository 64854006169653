import Header from "../../../../Components/Header";
import OutboundCycleComponent from "./Components/OutboundCycle-Components";
import Footer from "../../../../Components/Footer";

const OutboundCycle = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <OutboundCycleComponent />
      <Footer />
    </div>
  );
};
export default OutboundCycle;
