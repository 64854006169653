import React from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import Purchasereturnordercomponent from './Components/Purchase-return-order-component'

const Purchasereturnorder = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
    <Header />
    <Purchasereturnordercomponent />
    <Footer />
    </div>
  )
}

export default Purchasereturnorder