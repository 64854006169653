import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../PurchaseReturnOrder.css";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const AssemblyOrderCreatePutComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const AssemblyOrderCreatePut = [
    {
      posted_ware_receipt: "PAO-PP/21-22/100183",
      ware_receipt: "Asmbly-PP/21-22/100436",
      order_no: "Asmbly-PP/21-22/100436",
      no_of_lines: "1",
      total_qty: "1",
      assigned_user_id: "akash",
      assignment_date: "2021-11-10",
      assignment_time: " ",
      master_client: "DKB",
      location_code: "GGN",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Assembly Order Create Put-away
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="d-flex justify-content-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <div className="row" style={{ width: "100%", height: "35px" }}>
            <div className="col-2">
              {" "}
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Document Type">Select Assembly No</option>
              </select>{" "}
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No">
                  Select Posted Assembly No
                </option>
              </select>
            </div>
            <div className="col-2">
              {/* <input type="date" style={{fontSize:"13px"}} className="form-control" /> */}
              <input
                type="date"
                value={value}
                className="form-control"
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                style={{
                  color: value || focused ? "black" : "gray",
                  fontSize: "13px",
                }}
                placeholder="Select date"
              />
              {!value && !focused && (
                <span
                  style={{
                    position: "relative",
                    pointerEvents: "none",
                    color: "gray",
                    top: "-30px",
                    left: "2px",
                    backgroundColor: "#fff",
                    padding: "2px 43px 2px 8px",
                    fontSize: "13px",
                  }}
                >
                  Assigned Date
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Id
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posted Warehouse Receipt
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Warehouse Receipt
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No.of Lines
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Total Qty
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Assigned User Id
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Assignment Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Assignment Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Master Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {AssemblyOrderCreatePut.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((assembly_order_create_put_away_data, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" readOnly></input>
                  </td>
                  <td>
                    {assembly_order_create_put_away_data.posted_ware_receipt}
                  </td>
                  <td>{assembly_order_create_put_away_data.ware_receipt}</td>
                  <td>{assembly_order_create_put_away_data.order_no}</td>
                  <td>{assembly_order_create_put_away_data.no_of_lines}</td>
                  <td>{assembly_order_create_put_away_data.total_qty}</td>
                  <td>
                    {assembly_order_create_put_away_data.assigned_user_id}
                  </td>
                  <td>{assembly_order_create_put_away_data.assignment_date}</td>
                  <td>{assembly_order_create_put_away_data.assignment_time}</td>
                  <td>{assembly_order_create_put_away_data.master_client}</td>
                  <td>{assembly_order_create_put_away_data.location_code}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AssemblyOrderCreatePutComponent;
