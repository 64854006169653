import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";

const UserManagementForm = () => {
  const [tab, settab] = useState(1);
  return (
    <div style={{ width: "100%", overflow: "hidden", backgroundColor: "#fff" }}>
      <div className="col-12 p-0" style={{ backgroundColor: "#eff4f9" }}>
        <ul className="nav nav-tabs" style={{ color: "white", border: "none" }}>
          <li
            className="nav-item"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <a
              className={
                tab == 1 ? "nav-link active text-white" : "nav-link text-white"
              }
              onClick={() => {
                settab(1);
              }}
              href="#"
            >
              General Information{" "}
              {tab == 1 ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
            </a>
          </li>
        </ul>
      </div>
      <div
        className="row d-flex justify-content-between"
        style={{ marginTop: "14px" }}
      >
        <div className="col-12 ">
          {/* tab one is here  */}
          {tab == 1 ? (
            <div
              className="row sidebarbox m-0"
              style={{
                padding: "8px",
                borderRadius: "5px",
                height: "70vh",
                overflowY: "scroll",
              }}
            >
              <div className="col-md-6 mt-4">
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    User Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="PO-PP/24-25/00021"
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Create Password
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="DKB"
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Company<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Location<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Select Department
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value=""
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Mobile
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "0.8rem" }}
                    className="form-control inputdesign"
                    value="GGN"
                    readOnly
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Client <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Select Role <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="mb-3 ">
                  <label className="labelinpruchaseorder form-label m-0 d-flex align-items-end">
                    Bin Editable Permission{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control inputdesign"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagementForm;
