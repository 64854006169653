import React, { useState } from "react";

const Supertab = () => {
  const [tabs, setTabs] = useState([
    { id: 1, title: "New Tab" },
    { id: 2, title: "New Tab" },
  ]);
  const [activeTab, setActiveTab] = useState(1);

  const addTab = () => {
    const newTab = {
      id: Date.now(),
      title: "New Tab",
    };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
  };

  const closeTab = (tabId) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(updatedTabs);
    if (tabId === activeTab && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].id);
    }
  };

  return (
    <div className="chrome-tabs-container">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={`tab ${tab.id === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(tab.id)}
          >
            <span className="tab-title">{tab.title}</span>
            <button
              className="close-btn"
              onClick={(e) => {
                e.stopPropagation(); // Prevent tab activation on close
                closeTab(tab.id);
              }}
            >
              ✕
            </button>
          </div>
        ))}
        <button className="add-tab" onClick={addTab}>
          ＋
        </button>
      </div>
    </div>
  );
};

export default Supertab;
