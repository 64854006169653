import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaUser, FaCog, FaRegEdit } from "react-icons/fa";
import {
  MdLogout,
  MdOutlinePayment,
  MdTrackChanges,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
} from "react-icons/md";
// import '../../../../Components/Style/Profiledropdown.css';

const TransferReceiptToBePostedDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <p className="d-flex m-0" style={{ gap: "5px" }}>
      <span>
        <MdLocalPrintshop className="actionbtn" />
      </span>
      <span>
        <MdOutlineRemoveRedEye className="actionbtn" />
      </span>
    </p>
  );
};

export default TransferReceiptToBePostedDropdown;
