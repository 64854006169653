import React from 'react'
import Header from '../../../../Components/Header'
import Footer from '../../../../Components/Footer'
import Purchaseordercomponent from './Components/Purchase-order-component'

const Postedasn = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
        <Header />
        <Purchaseordercomponent />
        <Footer />
        </div>
  )
}

export default Postedasn