import React from "react";
import Header from "../../../../Components/Header";
import BOMMasterComponent from "./Components/BOMMaster-Component";
import Footer from "../../../../Components/Footer";

const BOMMaster = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <BOMMasterComponent />
      <Footer />
    </div>
  );
};

export default BOMMaster;
