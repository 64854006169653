import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { removePage } from "../store/state/pagehistory";

const Tabs = () => {
    const pageHistory = useSelector((state) => state.history.pageHistory);
    const dispatch = useDispatch();
    const location = useLocation();
    // Function to format the endpoint
    const formatEndpoint = (url) => {
        const segments = url.split("/");
        const endpoint = segments[segments.length - 1] || segments[segments.length - 2]; // Handle trailing slash
        return endpoint
            ? endpoint
                  .replace(/-/g, " ") // Replace hyphens with spaces
                  .split(" ") // Split into words
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                  .join(" ") // Join words back into a string
            : "Home"; // Fallback for empty endpoint
    };

    return (
        <div className="chrome-tabs-container" 
        style={{ display: pageHistory.length > 0 ? "flex" : 'none',
            //  borderBottom: "2px solid #ddd", padding: "10px", background: "#f9f9f9"
             }}
        >
            <div className="tabs" style={{paddingLeft:'2px'}}>
            {pageHistory.map((page, index) => (
                <div
                    key={index}
                    style={{
                        // display: "flex",
                        // alignItems: "center",
                        // margin: "0 3px",
                        // borderRadius: "4px",
                        padding: "5px 10px",
                        // background: '#fd8c5c',
                        // background: 'linear-gradient(90deg, #391F84 0%, #FF8D5C 100%)',
                    }}
            className={`tab ${page == location.pathname ? "active" : ""}`}
                >
                    <Link
                        to={page}
                        style={{
                            textDecoration: "none",
                            color: "#000",
                            marginRight: "10px",
                            fontSize:'15px'
                        }}
                    >
                        {formatEndpoint(page)}
                    </Link>
                    <button
                        onClick={() => dispatch(removePage(page))}
                        style={{
                            border: "none",
                            background: "transparent",
                            color: "#000",
                            fontSize: "16px",
                            cursor: "pointer",
                        }}
                    >
                        &times;
                    </button>
                </div>
            ))}
            </div>
        </div>
    );
};

export default Tabs;
