import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const ValueEntryReportComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const ValueEntryReportComp = [
    {
      id: "",
      item_no: "",
      posting_date: "",
      item_ledger_entry_type: "",
      source_no: "",
      document_no: "",
      description: "",
      location_code: "",
      inventory_posting_group: "",
      source_posting_group: "",
      item_ledger_entry_no: "",
      valued_qauntity: "",
      Item_Ledger_Entry_Quantity: "",
      Invoiced_Quantity: "",
      cost_per_unit: "",
      line_amount: "",
      sale_amount: "",
      Salespers_Purch_Code: "",
      Discount_Amount: "",
      Discount: "",
      user_id: "",
      source_code: "",
      department_code: "",
      source_type: "-",
      Gen_Bus_Posting_Group: "-",
      Gen_Prod_Posting_Group: "-",
      Document_Date: "-",
      Document_type: "",
      Document_Line_No: "",
      Sale_Amount_Expected: "",
      Cost_Amount_Expected: "",
      Igst_Amount: "",
      Cgst_Amount: "",
      Sgst_Amount: "",
      Igst: "",
      Cgst: "",
      Sgst: "",
      Gst_Group_Type: "",
      Gst_Base_Amount: "",
      Gst: "",
      Total_Gst_amount: "",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Value Entry Report
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th>
                  Id <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Posting Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Ledger Entry Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Inventory Posting Group
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source Posting Group
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Ledger Entry No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Valued Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Ledger Entry Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Invoiced Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Cost per Unit
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Line Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sale Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sales/Purchasing Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Discount Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  User ID
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Department Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  General Business Posting Group
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  General Product Posting Group
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Line No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sale Amount Expected
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Cost Amount Expected
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  IGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  CGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  SGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  IGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  CGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  SGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST Group Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST Base Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Total GST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {ValueEntryReportComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((value_entry_report_data, index) => (
                <tr key={index}>
                  <td>{value_entry_report_data.id}</td>
                  <td>{value_entry_report_data.item_no}</td>
                  <td>{value_entry_report_data.posting_date}</td>
                  <td>{value_entry_report_data.item_ledger_entry_type}</td>
                  <td>{value_entry_report_data.source_no}</td>
                  <td>{value_entry_report_data.document_no}</td>
                  <td>{value_entry_report_data.description}</td>
                  <td>{value_entry_report_data.location_code}</td>
                  <td>{value_entry_report_data.inventory_posting_group}</td>
                  <td>{value_entry_report_data.source_posting_group}</td>
                  <td>{value_entry_report_data.item_ledger_entry_no}</td>
                  <td>{value_entry_report_data.valued_qauntity}</td>
                  <td>{value_entry_report_data.Item_Ledger_Entry_Quantity}</td>
                  <td>{value_entry_report_data.Invoiced_Quantity}</td>
                  <td>{value_entry_report_data.cost_per_unit}</td>
                  <td>{value_entry_report_data.line_amount}</td>
                  <td>{value_entry_report_data.sale_amount}</td>
                  <td>{value_entry_report_data.Salespers_Purch_Code}</td>
                  <td>{value_entry_report_data.Discount_Amount}</td>
                  <td>{value_entry_report_data.Discount}</td>
                  <td>{value_entry_report_data.user_id}</td>
                  <td>{value_entry_report_data.source_code}</td>
                  <td>{value_entry_report_data.department_code}</td>
                  <td>{value_entry_report_data.source_type}</td>
                  <td>{value_entry_report_data.Gen_Bus_Posting_Group}</td>
                  <td>{value_entry_report_data.Gen_Prod_Posting_Group}</td>
                  <td>{value_entry_report_data.Document_Date}</td>
                  <td>{value_entry_report_data.Document_type}</td>
                  <td>{value_entry_report_data.Document_Line_No}</td>
                  <td>{value_entry_report_data.Sale_Amount_Expected}</td>
                  <td>{value_entry_report_data.Cost_Amount_Expected}</td>
                  <td>{value_entry_report_data.Igst_Amount}</td>
                  <td>{value_entry_report_data.Cgst_Amount}</td>
                  <td>{value_entry_report_data.Sgst_Amount}</td>
                  <td>{value_entry_report_data.Igst}</td>
                  <td>{value_entry_report_data.Cgst}</td>
                  <td>{value_entry_report_data.Sgst}</td>
                  <td>{value_entry_report_data.Gst_Group_Type}</td>
                  <td>{value_entry_report_data.Gst_Base_Amount}</td>
                  <td>{value_entry_report_data.Gst}</td>
                  <td>{value_entry_report_data.Total_Gst_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ValueEntryReportComponent;
