import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({ baseUrl: "https://teentogether.stackerbee.com", prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('teentogether');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },}),
  endpoints: (builder) => ({
    postLogin: builder.mutation({
      query: (data) => ({
        url: `/user/admin_login/`,
        method:'POST',
        body:data
      })
    }),
  }),
})

export const { usePostLoginMutation } = adminApi