import Header from "../../../../Components/Header";
import InventoryBinWiseComponent from "./Components/InventoryBinWise-Components";
import Footer from "../../../../Components/Footer";

const InventoryBinWise = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <InventoryBinWiseComponent />
      <Footer />
    </div>
  );
};
export default InventoryBinWise;
