import { useNavigate } from "react-router-dom";
import img from "../.././assets/login_logo.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useState } from "react";

const Login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const nvg = useNavigate();

  const loginsubmit = (e) => {
    if (username === "dev" && password === "dev@123") {
      nvg("/dashboard");
    }
  };

  return (
    <div className="login-container">
      <div className="upper-section">
        <div className="login-box">
          <img src={img} alt="logo" style={{ maxWidth: "70px" }} />
          <div className="logo">Login to StackerBee WMS</div>
        </div>
      </div>
      <form
        onSubmit={() => {
          loginsubmit();
        }}
      >
        <div className="lower-section">
          <div className="login-box" style={{ paddingBottom: "0px" }}>
            <div className="login-form">
              <div className="controlinput">
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => {
                    setusername(e.target.value);
                  }}
                  placeholder="Username"
                  required
                />
              </div>
              <div className="controlinput">
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  placeholder="Password"
                  required
                />
              </div>
            </div>
          </div>
          <div className="login-box">
            <div className="login-form">
              <button type="submit" style={{ background: "#ec642a" }}>
                LOG IN &nbsp; <FaLongArrowAltRight size={16} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
