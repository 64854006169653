import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const BinmovementReportComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const BinmovementReportComp = [
    {
      no: "-",
      posting_date: "",
      remarks: "-",
      item_no: "",
      from_zone: "",
      from_bin: "-",
      to_zone: "",
      to_bin: "-",
      qauntity: "",
      ok_qty: "",
      reject_qty: "-",
      reason_code: "",
      reason_desc: "-",
      lot_no: "",
      serial_no: "",
      expiration_date: "",
      warranty_date: "",
      manufacture_date: "-",
      status: "",
      created_date: "-",
      user: "",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Binmovement Report
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th>
                  No <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Posting Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Remarks{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Item No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  From Zone{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  From Bin{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  To Zone{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  To Bin <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Qauntity{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Ok Qty.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reject Qty.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reason Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reason Desc.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Lot No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Serial No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Expiration Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Warranty Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Manufacture Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  UOM <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Status <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Created Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  User <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
              </tr>
            </thead>
            <tbody>
              {BinmovementReportComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((binmovement_report_data, index) => (
                <tr key={index}>
                  <td>{binmovement_report_data.no}</td>
                  <td>{binmovement_report_data.posting_date}</td>
                  <td>{binmovement_report_data.remarks}</td>
                  <td>{binmovement_report_data.item_no}</td>
                  <td>{binmovement_report_data.from_zone}</td>
                  <td>{binmovement_report_data.from_bin}</td>
                  <td>{binmovement_report_data.to_zone}</td>
                  <td>{binmovement_report_data.to_bin}</td>
                  <td>{binmovement_report_data.qauntity}</td>
                  <td>{binmovement_report_data.ok_qty}</td>
                  <td>{binmovement_report_data.reject_qty}</td>
                  <td>{binmovement_report_data.reason_code}</td>
                  <td>{binmovement_report_data.reason_desc}</td>
                  <td>{binmovement_report_data.lot_no}</td>
                  <td>{binmovement_report_data.serial_no}</td>
                  <td>{binmovement_report_data.expiration_date}</td>
                  <td>{binmovement_report_data.warranty_date}</td>
                  <td>{binmovement_report_data.manufacture_date}</td>
                  <td>{binmovement_report_data.status}</td>
                  <td>{binmovement_report_data.created_date}</td>
                  <td>{binmovement_report_data.user}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BinmovementReportComponent;
