import React from "react";
import Header from "../../../../Components/Header";
import { NavLink } from "react-router-dom";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaThLarge, FaSave } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import Form from "./Form";


const Updatepoexpiration = () => {
  return (
    <>
      <Header />
      <div className="container-fluid maindiv p-0">
       
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
 <a href="#" className="pagetitle px-2" style={{border:'0px solid'}}>
          {" "}
          Update PO Expiration
        </a>

          {/* filter start here  */}
          <div className="d-flex" style={{ gap: "6px" }}>
            <a href="" style={{textDecoration:'none'}} className="createbtn2 m-0">
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <FaSave fontSize={13} color="#fff" />{" "}
              </span>{" "} &nbsp;
              Save
            </a>
            <a href="" style={{textDecoration:'none'}} className="createbtn-orange m-0">
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <IoCloseSharp fontSize={14} fontWeight={700} />{" "}
              </span>{" "}
              
            </a>
          </div>
        </div>
      </div>
      <div style={{background:'#eff4f9',height:"20px"}}></div>
      <div className="container-fluid maindiv">
      {/* <Purchaseform /> */}
      <Form />
      </div>
    </>
  );
};

export default Updatepoexpiration;
