import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport,MdFilterAlt, MdLocalPrintshop, MdOutlineRemoveRedEye, MdOutlineClose } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt,FaSort,FaList,FaThLarge, FaRegEdit} from 'react-icons/fa';
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Table = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter 
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      address: "akash",
      vendorName: "East Delhi",
      orderStatus: "PLPN-21-22-081",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
//   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div style={{backgroundColor:'white'}}>
      <div className="d-flex justify-content-between align-items-center p-2" style={{background:'#fcfdfe'}}>
      <a href="#" className="pagetitle px-2">
        {" "}
        GRN to be Posted
      </a>
        <div className="d-flex" style={{gap:'7px'}}>
          <NavLink to="/purchaseorder/create-purchase-order" className="createbtn m-0">
            <span className="iconbox">
              <MdAdd fontSize={18}/>{" "}
            </span>{" "}
            Add New
          </NavLink>
          {/* <a href="#" style={{backgroundColor:'#fff'}} className="btn importbtn">
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18}  />{" "}
            </span>{" "}
            Import
          </a> */}
        </div>

      
      </div> 
      <div className="showfilter justify-content-between p-2"  >
       <div className="row" style={{width:'100%'}}>
       <div className="col-3" style={{minHeight:'57.5px'}}>
          <input
        type="date"
        value={value}
className="form-control"
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          color: value || focused ? 'black' : 'gray',fontSize:'13px'
        }}
        placeholder="Select date"
      />
      {!value && !focused && (
        <span
          style={{
            position: 'relative',
            pointerEvents: 'none',
            color: 'gray',
            paddingLeft: '10px',
            top:'-30px',
            backgroundColor:'#fff',
          padding:'2px 13px',
          fontSize:'13px'
          }}
        >
         From Date
        </span>
      )}
        </div>
        <div className="col-3">
          <input
        type="date"
        value={value2}
className="form-control"
        onChange={(e) => setValue2(e.target.value)}
        onFocus={() => setFocused2(true)}
        onBlur={() => setFocused2(false)}
        style={{
          color: value2 || focused2 ? 'black' : 'gray',fontSize:'13px'
        }}
        placeholder="Select date"
      />
      {!value2 && !focused2 && (
        <span
          style={{
            position: 'relative',
            pointerEvents: 'none',
            color: 'gray',
            paddingLeft: '10px',
            top:'-30px',
            backgroundColor:'#fff',
            right:'-5px',
          padding:'0px 26px 0 8px',
          fontSize:'13px'
          }}
        >
         To Date
        </span>
      )}
        </div>
        <div className="col-3"><select name="" className="form-control" style={{fontSize:'13px',color:'gray'}} id="">
        <option value="Select Gate Entry No">GRN No.</option>
        </select></div>
        <div className="col-3"> <select name="" className="form-control" style={{fontSize:'13px',color:'gray'}} id="">
          <option value="Select Document Type">Po No.</option>
          </select> </div>
      
        <div className="col-3"><select name="" className="form-control" style={{fontSize:'13px',color:'gray'}} id="">
        <option value="Select Gate Entry No">Gate Entry No.</option>
        </select></div>
        <div className="col-3"><select name="" className="form-control" style={{fontSize:'13px',color:'gray'}} id="">
        <option value="Select Gate Entry No">Created by</option>
        </select></div>
       
       
       </div>
      </div> 

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Action <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ref. Doc. No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Gate Entry No.  <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">No. of Lines <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Total Quantity <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Master Client <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Created by <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
        <td>  <p className='d-flex justify-content-center m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p>  </td>
                  <td>PLPN-21-22-080</td>
                  <td>{order.orderStatus}</td>
                  <td>	2</td>
                  <td>2021-08-31</td>
                  <td>	10:08:40</td>
                  <td>{order.orderDate}</td>
                  <td>{1}</td>
                  <td>2021-08-31</td>
                  <td>Akash</td>
                  </tr>
              ))}
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
        <td>  <p className='d-flex justify-content-center m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p>  </td>
                  <td>PLPN-21-22-080</td>
                  <td>{order.orderStatus}</td>
                  <td>	2</td>
                  <td>2021-08-31</td>
                  <td>	10:08:40</td>
                  <td>{order.orderDate}</td>
                  <td>{1}</td>
                  <td>2021-08-31</td>
                  <td>Akash</td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
      <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                    //   filterdata(e.selected * 40);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    forcePage={currentPage - 1}
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                        
                                        <FaAnglesLeft color="grey" size={13} />
                                         
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                      
                                      <FaAnglesRight color="grey" size={13} />
                                      
                                      </li>
                                    }
                                  />
    </div>
    </div>
    </div>
  );
};

export default Table;
