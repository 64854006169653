import React from "react";
import Header from "../../../../Components/Header";
import PostedRGPShipmentComponents from "./Components/PostedRGPShipment-Components";
import Footer from "../../../../Components/Footer";

const PostedRGPShipment = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedRGPShipmentComponents />
      <Footer />
    </div>
  );
};
export default PostedRGPShipment;
