import React from "react";
import Header from "../../../../Components/Header";
import { NavLink } from "react-router-dom";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaThLarge, FaSave } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import BinMovementForm from "./BinMovementForm";

const Create = () => {
  return (
    <>
      <Header />
      <div className="container-fluid maindiv p-0">
        <div
          className="d-flex justify-content-between p-2"
          style={{ background: "#fcfdfe" }}
        >
          {/* <div className="d-flex" style={{ gap: "5px" }}>
            <a href="" className="btn btn-primary createbtn m-0">
              <span className="iconbox">
                <MdOutlineImportExport fontSize={18} />{" "}
              </span>{" "}
              Import
            </a>
            <a href="" className="btn btn-secondary importbtn">
              <span className="iconbox">
                <MdOutlineImportExport fontSize={18} />{" "}
              </span>{" "}
              Export Format
            </a>
          </div> */}
          <a
            href="#"
            className="pagetitle"
            style={{ borderBottom: "0px solid", textWrap: "nowrap" }}
          >
            {" "}
            Bin Movement
          </a>

          {/* filter start here  */}
          <div
            className="d-flex justify-content-end align-items-center"
            style={{ gap: "6px", width: "100%" }}
          >
            {/* <div className=" filterbtn d-flex justify-content-space-between" style={{gap:'13px'}}>
              <span className="iconbox"><MdFilterAlt fontSize={18} color="#270b79" /></span>
              <span className="iconbox-left"><AiOutlineFileExcel fontSize={16} color="#270b79" /></span>
              <span className="iconbox-left"><FaFileAlt fontSize={15} color="#270b79" /></span>
            </div>
            <div className=" filterbtn d-flex justify-content-space-between" style={{gap:'13px'}}>
              <span className="iconbox-left"><FaSort fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><LuRefreshCw fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><FaListUl fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><FaThLarge fontSize={14} color="#270b79" /></span>
            </div> */}
            {/* <div className=" filterbtn "></div>
            <div className=" filterbtn "></div>
            <div className=" filterbtn "></div> */}

            <a
              href=""
              style={{ textDecoration: "none" }}
              className="createbtn2 m-0"
            >
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <FaSave fontSize={13} color="#fff" />{" "}
              </span>{" "}
              &nbsp; Save
            </a>
            <a
              href=""
              style={{ textDecoration: "none" }}
              className="createbtn-orange m-0"
            >
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <IoCloseSharp fontSize={14} fontWeight={700} />{" "}
              </span>{" "}
            </a>
          </div>
        </div>
      </div>
      <div style={{ background: "#eff4f9", height: "20px" }}></div>
      <div className="container-fluid maindiv">
        <BinMovementForm />
      </div>
    </>
  );
};

export default Create;
