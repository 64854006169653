import React from "react";
import Header from "../../../../Components/Header";
import PostedTransferShipmentComponent from "./Components/Posted-Transfer-Shipment-Components";
import Footer from "../../../../Components/Footer";

const PostedTransferShipment = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedTransferShipmentComponent />
      <Footer />
    </div>
  );
};

export default PostedTransferShipment;
