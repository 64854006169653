import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
// import "../PurchaseReturnOrder.css";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import CreateTransferRceiptDropdown from "./Dropdown";

const CreateTransferReceiptComponents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const TransferReceipt = [
    {
      doc_no: "1",
      doc_type: "-",
      source_code: "-",
      source_name: "-",
      get_entry_no: "-",
      no_of_line: "-",
      total_quantity: "-",
      asn_no: "-",
      client: "-",
      location: "-",
      create_date: "-",
      last_update_date: "-",
      created_by: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Create Transfer Receipt
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a to="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="d-flex justify-content-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <div className="row" style={{ width: "100%", height: "35px" }}>
            <div className="col-2">
              {" "}
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Document Type">
                  Select Gate Entry No
                </option>
              </select>{" "}
            </div>
            <div className="col-2">
              <select
                name=""
                className="form-control"
                style={{ fontSize: "13px" }}
                id=""
              >
                <option value="Select Gate Entry No">Select TO No</option>
              </select>
            </div>
            <div className="col-2">
              {/* <input type="date" style={{fontSize:"13px"}} className="form-control" /> */}
              <input
                type="date"
                value={value}
                className="form-control"
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                style={{
                  color: value || focused ? "black" : "gray",
                  fontSize: "13px",
                }}
                placeholder="Select date"
              />
              {!value && !focused && (
                <span
                  style={{
                    position: "relative",
                    pointerEvents: "none",
                    color: "gray",
                    top: "-30px",
                    left: "2px",
                    backgroundColor: "#fff",
                    padding: "2px 43px 2px 8px",
                    fontSize: "13px",
                  }}
                >
                  Date
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">Action</th>
                <th className="table-header">
                  Document No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Document Type{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Source <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Source Name{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gate Entry No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No.of Lines{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  ASN No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Client <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Last Update Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {TransferReceipt.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((posted_bin_data, index) => (
                <tr key={index}>
                  <td>
                    <CreateTransferRceiptDropdown />
                  </td>
                  <td>{posted_bin_data.doc_no}</td>
                  <td>{posted_bin_data.doc_type}</td>
                  <td>{posted_bin_data.source_code}</td>
                  <td>{posted_bin_data.source_name}</td>
                  <td>{posted_bin_data.get_entry_no}</td>
                  <td>{posted_bin_data.no_of_line}</td>
                  <td>{posted_bin_data.asn_no}</td>
                  <td>{posted_bin_data.client}</td>
                  <td>{posted_bin_data.location}</td>
                  <td>{posted_bin_data.last_update_date}</td>
                  <td>{posted_bin_data.create_date}</td>
                  <td>{posted_bin_data.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTransferReceiptComponents;
