import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageHistory: JSON.parse(localStorage.getItem("pageHistory")) || [], // Load from localStorage
};

const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        addPage: (state, action) => {
            if(action.payload != '/' && action.payload != '/putaway/posted-reverse-pick-putaway'){
            const updatedHistory = [...new Set([...state.pageHistory, action.payload])]; // Avoid duplicates
            state.pageHistory = updatedHistory.slice(-5); // Keep only the last 8 pages
            localStorage.setItem("pageHistory", JSON.stringify(state.pageHistory)); // Save to localStorage
            }
        },
        removePage: (state, action) => {
            state.pageHistory = state.pageHistory.filter((page) => page !== action.payload);
            localStorage.setItem("pageHistory", JSON.stringify(state.pageHistory)); // Update localStorage
        },
    },
});

export const { addPage, removePage } = historySlice.actions;

export default historySlice.reducer;
