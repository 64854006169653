import Header from "../../../../Components/Header";
import GSTLedgerReportComponents from "./Components/GSTLedgerReport-Components";
import Footer from "../../../../Components/Footer";

const GSTLedgerReport = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <GSTLedgerReportComponents />
      <Footer />
    </div>
  );
};
export default GSTLedgerReport;
