import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const LedgerEntriesComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const LedgerEntriesComp = [
    {
      id: "",
      item_no: "",
      postng_date: "",
      entry_type: "",
      source_no: "",
      document_no: "",
      whse_document_no: "",
      descrbiption: "",
      location_code: "",
      qauntity: "",
      uom_qty: "",
      remaining_qauntity: "",
      qty_per_unit_of_measure: "",
      invoice_qauntity: "-",
      qty_on_shipment: "-",
      availble_qty_to_ship: "-",
      qc_result: "-",
      unit_cost: "-",
      unit_of_measure: "-",
      Base_Unit_Of_Measure: "-",
      Sales_Unitofmeasure: "-",
      Lot_No: "-",
      serial_no: "",
      item_expiration_date: "",
      manufacture_date: "",
      warranty_date: "",
      discount_amount: "",
      applies_to_entry: "",
      source_type: "",
      transport_method: "",
      transport_method_code: "",
      region_code: "",
      document_date: "",
      external_document_no: "",
      document_type: "",
      document_line_no: "",
      order_type: "",
      order_no: "",
      order_line_no: "",
      assemble_to_order: "",
      barcode: "",
      master_client: "",
      mr_price: "",
      description_2: "",
      blocked: "",
      inventory: "",
      Itemtrackingcode: "",
      Stylecode: "",
      Length: "",
      Height: "",
      Breadth: "",
      Cubecapacity: "",
      Weight: "",
      Fragile: "",
      Isiemei: "",
      Outboundqc: "",
      Itemcategory: "",
      Isdangrous: "",
      Itemgroupcode: "",
      Netweight: "",
      Vendornumber: "",
      Hsnsaccode: "",
      Item_Type: "",
      line_amount: "",
      Gstgroupcode: "",
      Price: "",
      Discount: "",
      Asnno: "",
      Asnqty: "",
      Expiration_Date: "",
      Assemblybom: "",
      Qc_Policy: "",
      Pick_Type: "",
      Lead_Time: "",
      Minimum_Stock: "",
      Cycle_Count_Code: "",
      Last_Phys_Invt_Date: "",
      Last_Counting_Period_Update: "",
      Next_Counting_Start_Date: "",
      Next_Counting_End_Date: "",
      Assembly_No: "",
      Item_Cost_After_Discount: "",
      Cost_Amount_Without_Discount: "",
      Igst_Amount: "",
      Cgst_Amount: "",
      Sgst_Amount: "",
      Igst: "",
      Cgst: "",
      Sgst: "",
      Gst_Group_Type: "",
      Gst_Base_Amount: "",
      Gst: "",
      Total_Gst_amount: "",
      Linenum: "",
      Coup114_On_Category: "",
      Capacityunit: "",
      Capacity: "",
      Class: "",
      Product: "",
      Size: "",
      Subcategorycode: "",
      Contain: "",
      Family: "",
      Itemcategorycode: "",
      Productgroupcode: "",
      Lpn_No: "",
      Posted_Lpn: "",
      Pallate_No: "",
      Vendor: "",
      Hand_Over_To: "",
      Stock_Type: "",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Ledger Entries Report
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th>
                  Id
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Posting Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Entry Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Warehouse Document No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  UOM Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Remaining Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Quantity per Unit
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Invoice Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Qty on Shipment
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Available Qty to Ship
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  QC Result
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Unit Cost
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Unit of Measure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Base Unit of Measure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sales Unit of Measure
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Lot No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Serial No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Expiration Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Manufacture Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Warranty Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Discount Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Applies to Entry
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Source Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Transport Method
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Transport Method Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Region Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  External Document No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Line No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Order Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Order No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Order Line No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Assemble to Order
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Barcode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Master Client
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  MR Price
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Description 2
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Blocked
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Inventory
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Tracking Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Style Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Length
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Height
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Breadth
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Cube Capacity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Fragile
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  IMEI
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Outbound QC
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Category
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Is Dangerous
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Group Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Net Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Vendor No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  HSN/SAC Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Line Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST Group Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Price
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  ASN No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  ASN Qty
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Expiration Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Assembly BOM
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  QC Policy
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Pick Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Lead Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Minimum Stock
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Cycle Count Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Last Physical Inventory Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Last Counting Period Update
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Next Counting Start Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Next Counting End Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Assembly No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Cost After Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Cost Amount Without Discount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  IGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  CGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  SGST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  IGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  CGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  SGST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST Group Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST Base Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  GST
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Total GST Amount
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Line Num
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Coupon on Category
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Capacity Unit
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Capacity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Class
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Product
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Size
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Subcategory Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Contain
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Family
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Category Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Product Group Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  LPN No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Posted LPN
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Pallet No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Vendor
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Hand Over To
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Stock Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {LedgerEntriesComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((ledger_entry_report_data, index) => (
                <tr key={index}>
                  <td>{ledger_entry_report_data.id}</td>
                  <td>{ledger_entry_report_data.item_no}</td>
                  <td>{ledger_entry_report_data.postng_date}</td>
                  <td>{ledger_entry_report_data.entry_type}</td>
                  <td>{ledger_entry_report_data.source_no}</td>
                  <td>{ledger_entry_report_data.document_no}</td>
                  <td>{ledger_entry_report_data.whse_document_no}</td>
                  <td>{ledger_entry_report_data.descrbiption}</td>
                  <td>{ledger_entry_report_data.location_code}</td>
                  <td>{ledger_entry_report_data.qauntity}</td>
                  <td>{ledger_entry_report_data.uom_qty}</td>
                  <td>{ledger_entry_report_data.remaining_qauntity}</td>
                  <td>{ledger_entry_report_data.qty_per_unit_of_measure}</td>
                  <td>{ledger_entry_report_data.invoice_qauntity}</td>
                  <td>{ledger_entry_report_data.qty_on_shipment}</td>
                  <td>{ledger_entry_report_data.availble_qty_to_ship}</td>
                  <td>{ledger_entry_report_data.qc_result}</td>
                  <td>{ledger_entry_report_data.unit_cost}</td>
                  <td>{ledger_entry_report_data.unit_of_measure}</td>
                  <td>{ledger_entry_report_data.Base_Unit_Of_Measure}</td>
                  <td>{ledger_entry_report_data.Sales_Unitofmeasure}</td>
                  <td>{ledger_entry_report_data.Lot_No}</td>
                  <td>{ledger_entry_report_data.serial_no}</td>
                  <td>{ledger_entry_report_data.item_expiration_date}</td>
                  <td>{ledger_entry_report_data.manufacture_date}</td>
                  <td>{ledger_entry_report_data.warranty_date}</td>
                  <td>{ledger_entry_report_data.discount_amount}</td>
                  <td>{ledger_entry_report_data.applies_to_entry}</td>
                  <td>{ledger_entry_report_data.source_type}</td>
                  <td>{ledger_entry_report_data.transport_method}</td>
                  <td>{ledger_entry_report_data.transport_method_code}</td>
                  <td>{ledger_entry_report_data.region_code}</td>
                  <td>{ledger_entry_report_data.document_date}</td>
                  <td>{ledger_entry_report_data.external_document_no}</td>
                  <td>{ledger_entry_report_data.document_type}</td>
                  <td>{ledger_entry_report_data.document_line_no}</td>
                  <td>{ledger_entry_report_data.order_type}</td>
                  <td>{ledger_entry_report_data.order_no}</td>
                  <td>{ledger_entry_report_data.order_line_no}</td>
                  <td>{ledger_entry_report_data.assemble_to_order}</td>
                  <td>{ledger_entry_report_data.barcode}</td>
                  <td>{ledger_entry_report_data.master_client}</td>
                  <td>{ledger_entry_report_data.mr_price}</td>
                  <td>{ledger_entry_report_data.description_2}</td>
                  <td>{ledger_entry_report_data.blocked}</td>
                  <td>{ledger_entry_report_data.inventory}</td>
                  <td>{ledger_entry_report_data.Itemtrackingcode}</td>
                  <td>{ledger_entry_report_data.Stylecode}</td>
                  <td>{ledger_entry_report_data.Length}</td>
                  <td>{ledger_entry_report_data.Height}</td>
                  <td>{ledger_entry_report_data.Breadth}</td>
                  <td>{ledger_entry_report_data.Cubecapacity}</td>
                  <td>{ledger_entry_report_data.Weight}</td>
                  <td>{ledger_entry_report_data.Fragile}</td>
                  <td>{ledger_entry_report_data.Isiemei}</td>
                  <td>{ledger_entry_report_data.Outboundqc}</td>
                  <td>{ledger_entry_report_data.Itemcategory}</td>
                  <td>{ledger_entry_report_data.Isdangrous}</td>
                  <td>{ledger_entry_report_data.Itemgroupcode}</td>
                  <td>{ledger_entry_report_data.Netweight}</td>
                  <td>{ledger_entry_report_data.Vendornumber}</td>
                  <td>{ledger_entry_report_data.Hsnsaccode}</td>
                  <td>{ledger_entry_report_data.Item_Type}</td>
                  <td>{ledger_entry_report_data.line_amount}</td>
                  <td>{ledger_entry_report_data.Gstgroupcode}</td>
                  <td>{ledger_entry_report_data.Price}</td>
                  <td>{ledger_entry_report_data.Discount}</td>
                  <td>{ledger_entry_report_data.Asnno}</td>
                  <td>{ledger_entry_report_data.Asnqty}</td>
                  <td>{ledger_entry_report_data.Expiration_Date}</td>
                  <td>{ledger_entry_report_data.Assemblybom}</td>
                  <td>{ledger_entry_report_data.Qc_Policy}</td>
                  <td>{ledger_entry_report_data.Pick_Type}</td>
                  <td>{ledger_entry_report_data.Lead_Time}</td>
                  <td>{ledger_entry_report_data.Minimum_Stock}</td>
                  <td>{ledger_entry_report_data.Cycle_Count_Code}</td>
                  <td>{ledger_entry_report_data.Last_Phys_Invt_Date}</td>
                  <td>
                    {ledger_entry_report_data.Last_Counting_Period_Update}
                  </td>
                  <td>{ledger_entry_report_data.Next_Counting_Start_Date}</td>
                  <td>{ledger_entry_report_data.Next_Counting_End_Date}</td>
                  <td>{ledger_entry_report_data.Assembly_No}</td>
                  <td>{ledger_entry_report_data.Item_Cost_After_Discount}</td>
                  <td>
                    {ledger_entry_report_data.Cost_Amount_Without_Discount}
                  </td>
                  <td>{ledger_entry_report_data.Igst_Amount}</td>
                  <td>{ledger_entry_report_data.Cgst_Amount}</td>
                  <td>{ledger_entry_report_data.Sgst_Amount}</td>
                  <td>{ledger_entry_report_data.Igst}</td>
                  <td>{ledger_entry_report_data.Cgst}</td>
                  <td>{ledger_entry_report_data.Sgst}</td>
                  <td>{ledger_entry_report_data.Gst_Group_Type}</td>
                  <td>{ledger_entry_report_data.Gst_Base_Amount}</td>
                  <td>{ledger_entry_report_data.Gst}</td>
                  <td>{ledger_entry_report_data.Total_Gst_amount}</td>
                  <td>{ledger_entry_report_data.Linenum}</td>
                  <td>{ledger_entry_report_data.Coup114_On_Category}</td>
                  <td>{ledger_entry_report_data.Capacityunit}</td>
                  <td>{ledger_entry_report_data.Capacity}</td>
                  <td>{ledger_entry_report_data.Class}</td>
                  <td>{ledger_entry_report_data.Product}</td>
                  <td>{ledger_entry_report_data.Size}</td>
                  <td>{ledger_entry_report_data.Subcategorycode}</td>
                  <td>{ledger_entry_report_data.Contain}</td>
                  <td>{ledger_entry_report_data.Family}</td>
                  <td>{ledger_entry_report_data.Itemcategorycode}</td>
                  <td>{ledger_entry_report_data.Productgroupcode}</td>
                  <td>{ledger_entry_report_data.Lpn_No}</td>
                  <td>{ledger_entry_report_data.Posted_Lpn}</td>
                  <td>{ledger_entry_report_data.Pallate_No}</td>
                  <td>{ledger_entry_report_data.Vendor}</td>
                  <td>{ledger_entry_report_data.Hand_Over_To}</td>
                  <td>{ledger_entry_report_data.Stock_Type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LedgerEntriesComponent;
