import Header from "../../../../Components/Header";
import PickReservationComponent from "./Components/PickReservation-Components";
import Footer from "../../../../Components/Footer";

const PickReservation = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PickReservationComponent />
      <Footer />
    </div>
  );
};
export default PickReservation;
