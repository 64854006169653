import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const OutboundCycleDetailsComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const OutboundCycleDetailsComp = [
    {
      location_code: "",
      customer_code: "",
      customer: "",
      sale_order_no: "",
      Sale_Order_Received_Date: "",
      Sale_Order_Received_Time: "",
      sku_code: "",
      description: "",
      description_2: "",
      qauntity: "",
      uom: "",
      pre_invoice_no: "-",
      wms_order: "-",
      order_dispatch_date_and_time: "-",
      box_id: "-",
      no_of_boxes: "-",
      mrp: "-",
      waybill_number: "-",
      lr_date: "-",
      eway_bill: "-",
      delivery_name: "-",
      designation: "-",
      designation_state: "-",
      pin_code: "-",
      pyment_mode: "-",
      mode: "-",
      vehicle_no: "-",
      actual_weight: "-",
      item_weight: "-",
      transmit_time_as_per_agrement: "-",
      overdue_day: "-",
      order_type: "-",
      lot_no: "-",
      serial_no: "-",
      expiration: "-",
      manufacture: "-",
      warranty: "-",
      status: "-",
      delivery_status: "-",
      pod_image: "-",
      receive_name: "-",
      receive_conatact_number: "-",
      created_by: "-",
      remarks: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Outbound Cycle Details
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Customer Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Customer
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sale Order Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sale Order Received Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Sale Order Received Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  SKU Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Description 2
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Quantity
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  UOM
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Pre Invoice No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  WMS Order/Invoice Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Order Dispatch Date and Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Box ID
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  No. of Boxes
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  MRP
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Waybill(LRN) Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  LR Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  EWay Bill
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Delivery Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Designation
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Designation State
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Pin Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Payment Mode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Mode
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Vehicle No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Actual Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Item Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Transmit Time(TAT) As Per Agreement
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Overdue Day
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Order Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Lot No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Serial No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Expiration
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Manufacture
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Warranty
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Status
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Delivery Status
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Delivery Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  POD Image
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Receiver Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Receiver Contact Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Created By
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Remarks
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {OutboundCycleDetailsComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((outbound_cycle_data, index) => (
                <tr key={index}>
                  <td>{outbound_cycle_data.location_code}</td>
                  <td>{outbound_cycle_data.customer_code}</td>
                  <td>{outbound_cycle_data.customer}</td>
                  <td>{outbound_cycle_data.sale_order_no}</td>
                  <td>{outbound_cycle_data.Sale_Order_Received_Date}</td>
                  <td>{outbound_cycle_data.Sale_Order_Received_Time}</td>
                  <td>{outbound_cycle_data.sku_code}</td>
                  <td>{outbound_cycle_data.description}</td>
                  <td>{outbound_cycle_data.description_2}</td>
                  <td>{outbound_cycle_data.qauntity}</td>
                  <td>{outbound_cycle_data.uom}</td>
                  <td>{outbound_cycle_data.pre_invoice_no}</td>
                  <td>{outbound_cycle_data.wms_order}</td>
                  <td>{outbound_cycle_data.order_dispatch_date_and_time}</td>
                  <td>{outbound_cycle_data.box_id}</td>
                  <td>{outbound_cycle_data.no_of_boxes}</td>
                  <td>{outbound_cycle_data.mrp}</td>
                  <td>{outbound_cycle_data.waybill_number}</td>
                  <td>{outbound_cycle_data.lr_date}</td>
                  <td>{outbound_cycle_data.eway_bill}</td>
                  <td>{outbound_cycle_data.delivery_name}</td>
                  <td>{outbound_cycle_data.designation}</td>
                  <td>{outbound_cycle_data.designation_state}</td>
                  <td>{outbound_cycle_data.pin_code}</td>
                  <td>{outbound_cycle_data.pyment_mode}</td>
                  <td>{outbound_cycle_data.mode}</td>
                  <td>{outbound_cycle_data.vehicle_no}</td>
                  <td>{outbound_cycle_data.actual_weight}</td>
                  <td>{outbound_cycle_data.item_weight}</td>
                  <td>{outbound_cycle_data.transmit_time_as_per_agrement}</td>
                  <td>{outbound_cycle_data.overdue_day}</td>
                  <td>{outbound_cycle_data.order_type}</td>
                  <td>{outbound_cycle_data.lot_no}</td>
                  <td>{outbound_cycle_data.serial_no}</td>
                  <td>{outbound_cycle_data.expiration}</td>
                  <td>{outbound_cycle_data.manufacture}</td>
                  <td>{outbound_cycle_data.warranty}</td>
                  <td>{outbound_cycle_data.status}</td>
                  <td>{outbound_cycle_data.delivery_status}</td>
                  <td>{outbound_cycle_data.pod_image}</td>
                  <td>{outbound_cycle_data.receive_name}</td>
                  <td>{outbound_cycle_data.receive_conatact_number}</td>
                  <td>{outbound_cycle_data.created_by}</td>
                  <td>{outbound_cycle_data.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OutboundCycleDetailsComponent;
