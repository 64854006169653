import React from "react";
import Header from "../../../../Components/Header";
import OUMOrderToBePostedComponents from "./Components/UOMOrderToBePosted-Components";
import Footer from "../../../../Components/Footer";

const UOMOrderToBePosted = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <OUMOrderToBePostedComponents />           
      <Footer />
    </div>
  );
};

export default UOMOrderToBePosted;
