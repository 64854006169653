import React from 'react'
import Header from '../../../../Components/Header'
import Footer from '../../../../Components/Footer'
import Table from './Components/Table'

const Postedsalesreturn = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
        <Header />
        <Table />
        <Footer />
    </div>
  )
}

export default Postedsalesreturn