import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const Notfound = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
        <Header />
<div className="row m-0">
  <div className="col-12">
    <div className="card-box">
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3 style={{textAlign:'center'}}>Oops! Page not found</h3>
            <h1><span>4</span><span>0</span><span>4</span></h1>
          </div>
          <h2>we are sorry, but the page you requested was not found</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<Footer />

    </div>
  )
}

export default Notfound