import React from "react";
import Header from "../../../../Components/Header";
import CreateTransferReceiptComponents from "./Components/CreateTransferReceipt-components";
import Footer from "../../../../Components/Footer";

const CreateTransferReceipt = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <CreateTransferReceiptComponents />
      <Footer />
    </div>
  );
};

export default CreateTransferReceipt;
