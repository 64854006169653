import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const OutboundCycleComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const OutboundCycleComp = [
    {
      location_code: "",
      Consignee_Name: "",
      sale_order_no: "",
      pre_invoice_no: "",
      Sale_Order_Received_Date: "",
      posted_date: "",
      wms_order: "",
      order_dispatch_date: "",
      waybill_number: "-",
      mode: "-",
      posted_by: "-",
      delivery_name: "-",
      destination: "-",
      destination_state: "-",
      pin_code: "-",
      delivery_gateway: "-",
      actual_weight: "-",
      item_weight: "-",
      no_of_boxes: "-",
      promise: "-",
      add: "-",
      transit_time: "-",
      overdue_days: "-",
      status: "-",
      remarks: "-",
      receive_name: "-",
      receive_contact_number: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Outbound Cycle
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Consignee Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sale Order Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Pre Invoice No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Sale Order Received Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posted Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  WMS Order /Invoice Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Dispatch Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Waybill (LRN) Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  MODE <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posted By
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Delivery Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Destination
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Destination State
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Pin Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Delivery Gateway
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Actual Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item Weight
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No. of Boxes
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  EDD /Promise
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Add
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Transit Time (TAT) As Per Agreement
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Overdue Days
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Status
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Remarks
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Receiver Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Receiver Contact Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {OutboundCycleComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((outbound_cycle_data, index) => (
                <tr key={index}>
                  <td>{outbound_cycle_data.location_code}</td>
                  <td>{outbound_cycle_data.Consignee_Name}</td>
                  <td>{outbound_cycle_data.sale_order_no}</td>
                  <td>{outbound_cycle_data.pre_invoice_no}</td>
                  <td>{outbound_cycle_data.Sale_Order_Received_Date}</td>
                  <td>{outbound_cycle_data.posted_date}</td>
                  <td>{outbound_cycle_data.wms_order}</td>
                  <td>{outbound_cycle_data.order_dispatch_date}</td>
                  <td>{outbound_cycle_data.waybill_number}</td>
                  <td>{outbound_cycle_data.mode}</td>
                  <td>{outbound_cycle_data.posted_by}</td>
                  <td>{outbound_cycle_data.delivery_name}</td>
                  <td>{outbound_cycle_data.destination}</td>
                  <td>{outbound_cycle_data.destination_state}</td>
                  <td>{outbound_cycle_data.pin_code}</td>
                  <td>{outbound_cycle_data.delivery_gateway}</td>
                  <td>{outbound_cycle_data.actual_weight}</td>
                  <td>{outbound_cycle_data.item_weight}</td>
                  <td>{outbound_cycle_data.no_of_boxes}</td>
                  <td>{outbound_cycle_data.promise}</td>
                  <td>{outbound_cycle_data.add}</td>
                  <td>{outbound_cycle_data.transit_time}</td>
                  <td>{outbound_cycle_data.overdue_days}</td>
                  <td>{outbound_cycle_data.status}</td>
                  <td>{outbound_cycle_data.remarks}</td>
                  <td>{outbound_cycle_data.receive_name}</td>
                  <td>{outbound_cycle_data.receive_contact_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OutboundCycleComponent;
