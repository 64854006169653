import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const InboundCycleDetailsComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const InboundCycleDetailsComp = [
    {
      location_code: "",
      po_no: "",
      vendor_number: "",
      vendor_name: "",
      gate_entry_no: "",
      vehicle_number: "-",
      vehicle_reach_date_time: "-",
      item_no: "",
      description: "-",
      po_qauntity: "-",
      ok_quantity: "-",
      reject_quantity: "-",
      total_grn_quantity: "-",
      put_away_outstanding_quantity: "-",
      grn_no: "-",
      grn_date: "-",
      posted_grn_no: "-",
      posting_grn_date: "-",
      invoice_no: "-",
      invoice_date: "-",
      invoice_qty: "-",
      invoice_value: "-",
      no_of_bags: "-",
      lot_no: "-",
      serial_no: "-",
      expiry_date: "-",
      mrp: "-",
      mfg_date: "-",
      status: "-",
      created_by: "-",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Inbound Cycle Details
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">
                  Location Code
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  PO. No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Name
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Gate Entry No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vehicle Number
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vehicle Reach Date Time
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Item No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Description
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  PO Qauntity.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Ok Qauntity.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Reject Qauntity.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Total GRN Qauntity.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Put-away Outstanding Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  GRN No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  GRN Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Posted GRN No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  GRN Posting Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Invoice No.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Invoice Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Invoice Qty.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Invoice Value
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  No.of Bags.
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Lot No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Serial No
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Expiry Date
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  MRP
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  MFGDate
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Status
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {InboundCycleDetailsComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((inbound_cycle_details_data, index) => (
                <tr key={index}>
                  <td>{inbound_cycle_details_data.location_code}</td>
                  <td>{inbound_cycle_details_data.po_no}</td>
                  <td>{inbound_cycle_details_data.vendor_number}</td>
                  <td>{inbound_cycle_details_data.vendor_name}</td>
                  <td>{inbound_cycle_details_data.gate_entry_no}</td>
                  <td>{inbound_cycle_details_data.vehicle_number}</td>
                  <td>{inbound_cycle_details_data.vehicle_reach_date_time}</td>
                  <td>{inbound_cycle_details_data.item_no}</td>
                  <td>{inbound_cycle_details_data.description}</td>
                  <td>{inbound_cycle_details_data.po_qauntity}</td>
                  <td>{inbound_cycle_details_data.ok_quantity}</td>
                  <td>{inbound_cycle_details_data.reject_quantity}</td>
                  <td>{inbound_cycle_details_data.total_grn_quantity}</td>
                  <td>
                    {inbound_cycle_details_data.put_away_outstanding_quantity}
                  </td>
                  <td>{inbound_cycle_details_data.grn_no}</td>
                  <td>{inbound_cycle_details_data.grn_date}</td>
                  <td>{inbound_cycle_details_data.posted_grn_no}</td>
                  <td>{inbound_cycle_details_data.posting_grn_date}</td>
                  <td>{inbound_cycle_details_data.invoice_no}</td>
                  <td>{inbound_cycle_details_data.invoice_date}</td>
                  <td>{inbound_cycle_details_data.invoice_qty}</td>
                  <td>{inbound_cycle_details_data.invoice_value}</td>
                  <td>{inbound_cycle_details_data.no_of_bags}</td>
                  <td>{inbound_cycle_details_data.lot_no}</td>
                  <td>{inbound_cycle_details_data.serial_no}</td>
                  <td>{inbound_cycle_details_data.expiry_date}</td>
                  <td>{inbound_cycle_details_data.mrp}</td>
                  <td>{inbound_cycle_details_data.mfg_date}</td>
                  <td>{inbound_cycle_details_data.status}</td>
                  <td>{inbound_cycle_details_data.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InboundCycleDetailsComponent;
