import React from "react";
import Header from "../../../../Components/Header";
import PostedUOMListComponents from "./Components/PostedUOMList-Components";
import Footer from "../../../../Components/Footer";

const PostedUOMList = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedUOMListComponents />
      <Footer />
    </div>
  );
};

export default PostedUOMList;
