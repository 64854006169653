import Header from "../../../../Components/Header";
import PostedPickReportComponent from "./Components/PostedPickLines-Components";
import Footer from "../../../../Components/Footer";

const PostedPickReport = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedPickReportComponent />
      <Footer />
    </div>
  );
};
export default PostedPickReport;
