import React from 'react'
import Header from '../../../Components/Header'
import Purchaseordercomponent from './Components/Purchase-order-component'
import Footer from '../../../Components/Footer'

const Purchaseorder = () => {
  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
    <Header />
    <Purchaseordercomponent />
    <Footer />
    </div>
  )
}

export default Purchaseorder