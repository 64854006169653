import Header from "../../../../Components/Header";
import PostedShipmentReportComponent from "./Components/PostedShipmentReport-Components";
import Footer from "../../../../Components/Footer";

const PostedShipmentReport = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedShipmentReportComponent />
      <Footer />
    </div>
  );
};
export default PostedShipmentReport;
