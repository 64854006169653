import Header from "../../../Components/Header";
import AutoEmailComponents from "./Components/AutoEmail-Components";
import Footer from "../../../Components/Footer";

const AutoEmail = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <AutoEmailComponents />
      <Footer />
    </div>
  );
};
export default AutoEmail;
