import React from "react";
import Header from "../../../Components/Header";
import BinMovementcomponent from "./Components/BinMovement-components";
import Footer from "../../../Components/Footer";

const BinMovement = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <BinMovementcomponent />
      <Footer />
    </div>
  );
};

export default BinMovement;
