import React from 'react';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis,ResponsiveContainer, CartesianGrid,LabelList, Tooltip, Legend, Text } from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
// import './Dashboard.css';

const Dashboard = () => {

  const data = [
    {
      name: 'current',
      Inventory: 84,
      CostValue: 100,
      MRPValue: 150,
    },
    {
      name: 'last day',
      Inventory: 80,
      CostValue: 100,
      MRPValue: 150,
    },
  ];

  const data2 = [
    { name: 'Cost Value', value: 500, color: '#823455' },
    { name: 'Quantity', value: 300, color: '#fe8d5c' },
    { name: 'MRP Value', value: 200, color: '#391f84' },
  ];

  const data3 = [
    { name: 'No. of Invoice', value: 700, color: '#fe8d5c' }, // Purple
    { name: 'Not Dispatched', value: 300, color: '#823455' }, // Orange
  ];

  const data4 = [
    {
      name: 'current',
      Quantity: 84,
      CostValue: 100,
      MRPValue: 150,
    },
    {
      name: 'last day',
      Quantity: 80,
      CostValue: 100,
      MRPValue: 150,
    },
  ];







  return (
    <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
      <Header />
    <div className="container-fluid dashboard-container">
      <div className="row dashboard-header" style={{marginBottom:'2px'}}>
        <div className="col-12">
          <h5 style={{color:'#823455'}}> Dashboard</h5>
        </div>
      </div>

      {/* Row 1: Inventory and Pendency */}
      <div className="row mb-4">
        <div className="col-md-4">
          <div className="dashboard-card m-0" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Inventory</h5>
            {/* <BarChart width={400} height={250} data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Inventory" fill="#FF6F00" />
              <Bar dataKey="Cost" fill="#6A1B9A" />
              <Bar dataKey="MRP" fill="#FFEB3B" />
            </BarChart> */}

<ResponsiveContainer width="100%" height={250}>
      <BarChart data={data} margin={{ top: 20, right: 50, left: 20, bottom: 5 }}>
        {/* Removed Y-axis labels */}
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        {/* <YAxis tick={false} /> */}
        <Tooltip contentStyle={{ fontSize: '12px' }} />
        <Legend layout="vertical" align="right" verticalAlign="middle"  wrapperStyle={{ fontSize: '12px' }} />
        <Bar dataKey="Inventory" fill="#fe8d5c">
          {/* Added value labels on top of each bar */}
          <LabelList dataKey="Inventory" position="top" style={{ fontSize: '10px' }} />
        </Bar>
        <Bar dataKey="CostValue" fill="#823455">
          <LabelList dataKey="CostValue" position="top" style={{ fontSize: '10px' }} />
        </Bar>
        <Bar dataKey="MRPValue" fill="#391f84">
          <LabelList dataKey="MRPValue" position="top" style={{ fontSize: '10px' }} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
          </div>
        </div>

        <div className="col-md-4" >
          <div className="dashboard-card mb-0" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Pendency</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Transaction</th>
                  <th>No.</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>GRN</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>Put Away</td>
                  <td>565</td>
                  <td>565</td>
                </tr>
                <tr>
                  <td>Shipment</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
                <tr>
                  <td>Pick</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-card mb-0">
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Receipts</h5>
            <h5 style={{textAlign:'center',borderBottom:'0px solid white',fontSize:'13px'}}>Total Receipts :- 1000</h5>
            <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            data={data2}
            cx="50%"
            cy="50%"
            innerRadius={56}
            outerRadius={100}
            paddingAngle={2}
            dataKey="value"
          >
            {data2.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            iconType="rect"
            layout="horizontal"
            verticalAlign="top"
            align="center"
            wrapperStyle={{ fontSize: '12px' }}
          />
        </PieChart>
      </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Row 2: Receipts and Invoiced But Not Dispatched */}
      <div className="row mb-4">
      <div className="col-md-4">
          <div className="dashboard-card" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Inward</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Transaction</th>
                  <th>No.</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Gate Entry</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>GRN</td>
                  <td>565</td>
                  <td>565</td>
                </tr>
                <tr>
                  <td>Put Away</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-4" >
          <div className="dashboard-card" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Return</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Transaction</th>
                  <th>No.</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sales Return Order</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>Receipts</td>
                  <td>565</td>
                  <td>565</td>
                </tr>
                <tr>
                  <td>Invoice Posted</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-4">
          <div className="dashboard-card" style={{height:'100%',padding:'0px'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700',margin:'0px 20px 0px 20px',paddingTop:'20px'}}>Invoiced But Not Dispatched</h5>
            <ResponsiveContainer width="100%" height={240}>
        <PieChart>
          <Pie
            data={data3}
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={100}
            paddingAngle={2}
            dataKey="value"
          >
            {data3.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            iconType="rect"
            layout="horizontal"
            verticalAlign="top"
            align="center"
            wrapperStyle={{ fontSize: '12px' }}
          />
        </PieChart>
      </ResponsiveContainer>
          </div>
        </div>
      </div>




            {/* Row 3: Receipts and Invoiced But Not Dispatched */}
            <div className="row mb-4">
      <div className="col-md-4">
          <div className="dashboard-card" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Outward</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Transaction</th>
                  <th>No.</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sales Order</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>Pick completed</td>
                  <td>565</td>
                  <td>565</td>
                </tr>
                <tr>
                  <td>Invoice Posted</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-4" >
          <div className="dashboard-card" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Kitting</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Transaction</th>
                  <th>No.</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pending</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>Today's</td>
                  <td>565</td>
                  <td>565</td>
                </tr>
                <tr>
                  <td>Pick</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
                <tr>
                  <td>Completed</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
                <tr>
                  <td>Put Away</td>
                  <td>56</td>
                  <td>56</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

   

        <div className="col-md-4">
          <div className="dashboard-card" style={{height:'100%'}}>
            <h5 style={{fontSize:'15px',fontWeight:'700'}}>Invoiced</h5>
            <ResponsiveContainer width="100%" height={250}>
      <BarChart data={data4} margin={{ top: 20, right: 50, left: 20, bottom: 5 }}>
        {/* Removed Y-axis labels */}
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis  tickCount={10}   interval={0} tick={{fontSize:12}} />
        <Tooltip contentStyle={{ fontSize: '12px' }} />
        <Legend layout="vertical" align="right" verticalAlign="middle"  wrapperStyle={{ fontSize: '12px' }} />
        <Bar dataKey="Quantity" fill="#fe8d5c">
          {/* Added value labels on top of each bar */}
          <LabelList dataKey="Quantity" position="top" style={{ fontSize: '10px' }} />
        </Bar>
        <Bar dataKey="CostValue" fill="#823455">
          <LabelList dataKey="CostValue" position="top" style={{ fontSize: '10px' }} />
        </Bar>
        <Bar dataKey="MRPValue" fill="#391f84">
          <LabelList dataKey="MRPValue" position="top" style={{ fontSize: '10px' }} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
          </div>
        </div>
      </div>

    </div>
  <Footer />
    </div>
  );
};

export default Dashboard;
