import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import {
  MdAdd,
  MdLocalPrintshop,
  MdOutlineRemoveRedEye,
  MdOutlineClose,
  MdOutlineImportExport,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const WarehouseReportComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const WarehouseReportComp = [
    {
      no: "",
      entry_type: "",
      document_date: "",
      posting_date: "",
      remarks: "",
      description: "",
      description_2: "",
      line_no: "",
      item_no: "",
      qauntity: "",
      ok_qty: "",
      reject_qty: "",
      reason_code: "",
      reason_desc: "",
      unit_amount: "",
      unit_cost: "",
      amount: "-",
      discount_amount: "-",
      bin_code: "-",
      zone_code: "-",
      qty_per_unit: "",
      uom: "",
      serial_no: "",
      lot_no: "",
      manufacture_date: "",
      warranty_date: "",
      expiration_date: "",
      status: "",
      mrp: "",
      created_date: "",
      created_by: "",
      last_updated: "",
      last_updated_by: "",
    },
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
            {" "}
            Adjustment Report
          </a>
          <div className="d-flex" style={{ gap: "5px" }}>
            <a href="#" className="createbtn m-0">
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </a>
          </div>

          {/* filter start here  */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th>
                  No <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Entry Type
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th>
                  Document Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Posting Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Remarks{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Description{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Description 2{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Line No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Item No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Qauntity{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Ok Qty.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reject Qty.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reason Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Reason Desc.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Unit Amount{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Unit Cost{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Amount <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Discount Amount{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Bin Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Zone Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Qty. Per Unit{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  UOM <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Serial No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Lot No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Manufacture Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Warranty Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Expiration Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Status <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  MRP <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Created Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Created By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Last Updated{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
                <th>
                  Last Updated By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />
                </th>
              </tr>
            </thead>
            <tbody>
              {WarehouseReportComp.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
              ).map((warehouse_report_data, index) => (
                <tr key={index}>
                  <td>{warehouse_report_data.no}</td>
                  <td>{warehouse_report_data.entry_type}</td>
                  <td>{warehouse_report_data.document_date}</td>
                  <td>{warehouse_report_data.posting_date}</td>
                  <td>{warehouse_report_data.remarks}</td>
                  <td>{warehouse_report_data.description}</td>
                  <td>{warehouse_report_data.description_2}</td>
                  <td>{warehouse_report_data.line_no}</td>
                  <td>{warehouse_report_data.item_no}</td>
                  <td>{warehouse_report_data.qauntity}</td>
                  <td>{warehouse_report_data.ok_qty}</td>
                  <td>{warehouse_report_data.reject_qty}</td>
                  <td>{warehouse_report_data.reason_code}</td>
                  <td>{warehouse_report_data.reason_desc}</td>
                  <td>{warehouse_report_data.unit_amount}</td>
                  <td>{warehouse_report_data.unit_cost}</td>
                  <td>{warehouse_report_data.amount}</td>
                  <td>{warehouse_report_data.discount_amount}</td>
                  <td>{warehouse_report_data.bin_code}</td>
                  <td>{warehouse_report_data.zone_code}</td>
                  <td>{warehouse_report_data.qty_per_unit}</td>
                  <td>{warehouse_report_data.uom}</td>
                  <td>{warehouse_report_data.serial_no}</td>
                  <td>{warehouse_report_data.lot_no}</td>
                  <td>{warehouse_report_data.manufacture_date}</td>
                  <td>{warehouse_report_data.warranty_date}</td>
                  <td>{warehouse_report_data.expiration_date}</td>
                  <td>{warehouse_report_data.status}</td>
                  <td>{warehouse_report_data.mrp}</td>
                  <td>{warehouse_report_data.created_date}</td>
                  <td>{warehouse_report_data.created_by}</td>
                  <td>{warehouse_report_data.last_updated}</td>
                  <td>{warehouse_report_data.last_updated_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WarehouseReportComponent;
