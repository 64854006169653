import React from "react";
import Header from "../../../../Components/Header";
import Footer from "../../../../Components/Footer";
import PostedAdjustmentComponent from "./Components/Posted-Adjustment-component";

const PostedAdjustment = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PostedAdjustmentComponent />
      <Footer />
    </div>
  );
};

export default PostedAdjustment;
