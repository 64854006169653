import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     
<div style={{backgroundColor:'white'}}>

      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
         <a href="#" className="pagetitle px-2">
        {" "}
        Sales order
      </a>
        <div className="d-flex" style={{ gap: "5px" }}>
          <NavLink
            to="/outbound/outbound-create-sales-order"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
         
        </div>

       
      </div>
    
     

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header" >
                # <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Channel <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Source <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Num <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Order Item Id <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                order_date <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                billname <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                bill_phone1 <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                bill_email <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                shipname <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                ship_state <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                shipaddress1 <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                ship_city <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                shipaddress2 <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                ship_pincode <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                ship_country <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                courier_code <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                item_no <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                quantity <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                description <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header" >
                Transfer Status <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
            </tr>

          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td
                    colSpan={21}
                    style={{
                      textWrap: "nowrap",
                      fontSize: "13px",
                    }}
                  >
                    No Records Found
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>
      </div>
    </div>
  );
};

export default Lpntable;
