import React from "react";
import Header from "../../../../Components/Header";
import { NavLink } from "react-router-dom";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaThLarge, FaSave } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import Purchaseform from "./Purchaseform";


const Create = () => {
  return (
    <>
      <Header />
      <div className="container-fluid maindiv p-0">
        
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          <a href="#" className="pagetitle px-2">
          {" "}
          Create Return Sales order
        </a>
         

          {/* filter start here  */}
          <div className="d-flex" style={{ gap: "6px" }}>
            {/* <div className=" filterbtn d-flex justify-content-space-between" style={{gap:'13px'}}>
              <span className="iconbox"><MdFilterAlt fontSize={18} color="#270b79" /></span>
              <span className="iconbox-left"><AiOutlineFileExcel fontSize={16} color="#270b79" /></span>
              <span className="iconbox-left"><FaFileAlt fontSize={15} color="#270b79" /></span>
            </div> */}
            {/* <div className=" filterbtn "></div>
            <div className=" filterbtn "></div> */}
            {/* <div className=" filterbtn d-flex justify-content-space-between" style={{gap:'13px'}}>
              <span className="iconbox-left"><FaSort fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><LuRefreshCw fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><FaListUl fontSize={14} color="#270b79" /></span>
              <span className="iconbox-left"><FaThLarge fontSize={14} color="#270b79" /></span>
            </div> */}
            {/* <div className=" filterbtn "></div>
            <div className=" filterbtn "></div>
            <div className=" filterbtn "></div> */}
            <a href="" className="createbtn2 m-0" style={{textDecoration:'none'}}>
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <FaSave fontSize={13} color="#fff" />{" "}
              </span>{" "} &nbsp;
              Save
            </a>
            <a href="" className="createbtn-orange m-0">
              <span className="iconbox" style={{ borderWidth: "0px" }}>
                <IoCloseSharp fontSize={14} fontWeight={700} />{" "}
              </span>{" "}
              
            </a>
          </div>
        </div>
      </div>
      <div style={{background:'#eff4f9',height:"20px"}}></div>
      <div className="container-fluid maindiv">
      <Purchaseform />
      </div>
    </>
  );
};

export default Create;
