import Footer from "../../../../Components/Footer"
import Header from "../../../../Components/Header"
import Table from "./Components/Table"

const Createreversepickputaway = () => {
    return (
      <div className='d-flex flex-column' style={{minHeight:'100vh'}}>
      <Header />
      <Table />
      <Footer />
      </div>
    )
  }
  
  export default Createreversepickputaway