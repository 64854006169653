import Header from "../../../../Components/Header";
import InventoryClientWiseComponent from "./Components/InventoryClientWise-Components";
import Footer from "../../../../Components/Footer";

const InventoryClientWise = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <InventoryClientWiseComponent />
      <Footer />
    </div>
  );
};
export default InventoryClientWise;
