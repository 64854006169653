import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt, MdLocalPrintshop, MdOutlineRemoveRedEye, MdOutlineClose } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge, FaRegEdit } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     <div style={{backgroundColor:"white"}}>
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
         <a href="#" className="pagetitle px-2">
        {" "}
        Sales order
      </a>
        <div className="d-flex" style={{gap:'5px'}}>
          <NavLink
            to="/outbound/outbound-create-sales-order"
            className="createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
          {/* <a
            href="#"
            style={{ backgroundColor: "#fff" }}
            className="btn importbtn"
          >
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18} />{" "}
            </span>{" "}
            Import
          </a> */}
        </div>

     
      </div>
 
      <div
        className="showfilter justify-content-center p-2"   
        style={{backgroundColor:'#fff'}}
      >
        <div className="row" style={{ width: "100%" }}>

        <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Order No.</option>
            </select>
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value}
              className="form-control"
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              style={{
                color: value || focused ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value && !focused && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Order Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value2}
              className="form-control"
              onChange={(e) => setValue2(e.target.value)}
              onFocus={() => setFocused2(true)}
              onBlur={() => setFocused2(false)}
              style={{
                color: value2 || focused2 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value2 && !focused2 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Order Date
              </span>
            )}
          </div>
          <div className="col-3">
            {" "}
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Document Type">Bill to Customer Name</option>
            </select>{" "}
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Ship to Customer Name</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Customer City</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Customer State</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Document Status</option>
            </select>
          </div>
          <div className="col-3">
            <select
              name=""
              className="form-control"
              style={{ fontSize: "13px", color: "gray" }}
              id=""
            >
              <option value="Select Gate Entry No">Created By</option>
            </select>
          </div>


          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value3}
              className="form-control"
              onChange={(e) => setValue3(e.target.value)}
              onFocus={() => setFocused3(true)}
              onBlur={() => setFocused3(false)}
              style={{
                color: value3 || focused3 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value3 && !focused3 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value4}
              className="form-control"
              onChange={(e) => setValue4(e.target.value)}
              onFocus={() => setFocused4(true)}
              onBlur={() => setFocused4(false)}
              style={{
                color: value4 || focused4 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value4 && !focused4 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-29px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Date
              </span>
            )}
          </div>
        </div>
      </div>
     

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Action <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Customer No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Created By <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Document Status <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Address <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to City <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to State <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Phone <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Quantity <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Value <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order Type <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">AWB No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">DSP Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Currency <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Client Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Payment Mode <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Business Type <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Priority <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Phone 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Address 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Email <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Pin Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bill to Country <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Name <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Phone <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to State <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Address <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Phone 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>

              <th className="table-header">Ship to City <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Address 2 <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Email <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Pin Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Ship to Country <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Form C <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Is Gift <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Shipment Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Is Bundle <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Is Hold <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Bundle Id <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Tracking No. <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Discount Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Credit Coupon <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Quick Ship <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Remarks <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>
                  <p className='d-flex m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p>
                  </td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>SO-PP/22-23/101114</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>EDAC-C00454</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>2023-02-11</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Bright Angles Education Centre</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>2023-02-11 22:02:21</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>admin</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Pick Created</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Bright Angles Intermediate College</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Panki</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Uttar Pradesh</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>0</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>100.0000</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>200.0000</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>B2B</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>DKB</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>GGN</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>0</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>218 B block, Panki</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>208020</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>IN</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Bright Angles Education Centre</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>
                      Bright Angles Intermediate College,218 B block, Panki
                    </td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>Panki</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>City : Panki,State : Uttar Pradesh</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}>208020</td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
                    <td style={{textWrap:'nowrap',fontSize:'13px'}}></td>
               
                </tr>
              ))}
          
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>
      </div>
    </div>
  );
};

export default Lpntable;
