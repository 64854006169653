import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport,MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt,FaSort,FaList,FaThLarge} from 'react-icons/fa';
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
//   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     <div style={{backgroundColor:'white'}}>
      <div className="d-flex justify-content-between align-items-center p-2" style={{background:'#fcfdfe'}}>

      <a href="#" className="pagetitle px-2">
        {" "}
        LPN List
      </a>
        <div className="d-flex">
          <NavLink to="/purchaseorder/create-purchase-order" className="createbtn m-0">
            <span className="iconbox">
              <MdAdd fontSize={18}/>{" "}
            </span>{" "}
            Add New
          </NavLink>
        
        </div>

        {/* filter start here  */}
        {/* <div className="d-flex" style={{gap:'4px'}}>
        <div className=" filterbtn "> <span className="iconbox">  <MdFilterAlt fontSize={18} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <AiOutlineFileExcel fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaFileAlt fontSize={15} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaSort fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <LuRefreshCw fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaListUl fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaThLarge fontSize={14} color="#270b79" /></span> </div>
        </div> */}
      </div> 
      {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

      <div className="table-responsive" style={{overflow:'visible'}}>
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">Id <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Gate Entry No <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Lpn No <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Posted Lpn <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Lpn Status <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">User Id <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Last Updated <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /></th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
        
                  <td>{order.id}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.vendorNo}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.vendorName}</td>
                  <td>{order.address}</td>
                  <td>{order.locationCode}</td>
                  <td>{order.creationDate}</td>
                  <td>{order.createdBy}</td>
                </tr>
              ))}
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
        
                  <td>{order.id}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.vendorNo}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.vendorName}</td>
                  <td>{order.address}</td>
                  <td>{order.locationCode}</td>
                  <td>{order.creationDate}</td>
                  <td>{order.createdBy}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}

      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
      <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                    //   filterdata(e.selected * 40);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    forcePage={currentPage - 1}
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                        
                                        <FaAnglesLeft color="grey" size={13} />

                                         
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                      
                                      <FaAnglesRight color="grey" size={13} />

                                      
                                      </li>
                                    }
                                  />
    </div>
    </div>
    </div>
  );
};

export default Lpntable;
