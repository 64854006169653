import Header from "../../../../Components/Header";
import PendingShipmentComponent from "./Components/PendingShipment-Components";
import Footer from "../../../../Components/Footer";

const PendingShipment = () => {
  return (
    <div className="flex-column d-flex" style={{ minHeight: "100vh" }}>
      <Header />
      <PendingShipmentComponent />
      <Footer />
    </div>
  );
};
export default PendingShipment;
