import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt, MdLocalPrintshop, MdOutlineRemoveRedEye, MdOutlineClose } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt, FaSort, FaList, FaThLarge, FaRegEdit } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdownbtn from "./Dropdown";

const Lpntable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setfilters] = useState(false);

  // for Date Filter
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [focused3, setFocused3] = useState(false);
  const [focused4, setFocused4] = useState(false);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "1271",
      orderDate: "GE-PP/21-22/100752",
      vendorNo: "LP-21-22-01322",
      orderStatus: "PLPN-21-22-081",
      vendorName: "Used",
      address: "akash",
      locationCode: "2021-09-09 03:15:46",
      creationDate: "	2021-09-09 03:15:46",
      createdBy: "GGN",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
     <div className="bg-white">
      <div
        className="d-flex justify-content-between align-items-center p-2"
        style={{ background: "#fcfdfe" }}
      >
         <a href="#" className="pagetitle px-2">
        {" "}
        Shipment To Be Posted
      </a>
        <div className="d-flex" style={{ gap: "5px" }}>
          <NavLink
            to="/outbound/outbound-create-sales-order"
            className=" createbtn m-0"
          >
            <span className="iconbox">
              <MdAdd fontSize={18} />{" "}
            </span>{" "}
            Add New
          </NavLink>
         
        </div>

      </div>
   
      <div
        className="showfilter justify-content-center p-2"
        style={{
          background: "#fff"
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Shipment No."
              className="form-control"
            />
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="date"
              value={value3}
              className="form-control"
              onChange={(e) => setValue3(e.target.value)}
              onFocus={() => setFocused3(true)}
              onBlur={() => setFocused3(false)}
              style={{
                color: value3 || focused3 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value3 && !focused3 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-30px",
                  backgroundColor: "#fff",
                  padding: "2px 13px",
                  fontSize: "13px",
                }}
              >
                From Date
              </span>
            )}
          </div>
          <div className="col-3">
            <input
              type="date"
              value={value4}
              className="form-control"
              onChange={(e) => setValue4(e.target.value)}
              onFocus={() => setFocused4(true)}
              onBlur={() => setFocused4(false)}
              style={{
                color: value4 || focused4 ? "black" : "gray",
                fontSize: "13px",
              }}
              placeholder="Select date"
            />
            {!value4 && !focused4 && (
              <span
                style={{
                  position: "relative",
                  pointerEvents: "none",
                  color: "gray",
                  paddingLeft: "10px",
                  top: "-29px",
                  backgroundColor: "#fff",
                  right: "-5px",
                  padding: "0px 26px 0 8px",
                  fontSize: "13px",
                }}
              >
                To Date
              </span>
            )}
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Order Type"
              className="form-control"
            />
          </div>
          <div className="col-3" style={{ minHeight: "57.5px" }}>
            <input
              type="text"
              style={{ fontSize: "13px", color: "grey" }}
              placeholder="Created By"
              className="form-control"
            />
          </div>
        </div>
      </div>
      {/* <div>
        <input
          type="text"
          placeholder="Search"
          style={{
            borderRadius: "0px",
            marginTop: "4px",
            fontSize: "13px",
            padding: "8px 0px",
          }}
          className="form-control"
        />
      </div> */}

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead className="thead-dark">
          

            <tr>
              <th className="table-header">
                Action
              </th>
              <th className="table-header">
                Shipment No <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Order No. <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Total Order <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Total Line <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Client <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Created Date <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
              <th className="table-header">
                Created By <MdOutlineImportExport fontSize={18} color="#6c757d"  />
              </th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((order, index) => (
                <tr key={index}>
                  <td>
                  <p className='d-flex justify-content-center m-0' style={{gap:'5px'}}>
      <span >
      <MdLocalPrintshop className='actionbtn'  />
      </span>
      <span>
      <MdOutlineRemoveRedEye className='actionbtn'  />
      </span>
      <span>
      <FaRegEdit className='actionbtn'  />
      </span>
     
      
      <span>
      <MdOutlineClose className='actionbtn'  />
      </span>
    </p>
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    SO-PP/22-23/101114
                  </td>
           
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    Box-PP/22-23/100661
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    SO-PP/22-23/101091
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    Transfer Order
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>DKB</td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>GGN</td>
                 
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    2024-10-14 12:46:24
                  </td>
                  <td style={{ textWrap: "nowrap", fontSize: "13px" }}>
                    Alam21
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
            //   filterdata(e.selected * 40);
          }}
          containerClassName="pagination"
          breakClassName="page-item"
          forcePage={currentPage - 1}
          activeClassName="active"
          pageClassName="page-item"
          previousLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
             <FaAnglesLeft color="grey" size={13} />
            </li>
          }
          nextLabel={
            <li
              className="page-item"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#eff4f9",
              }}
            >
              <FaAnglesRight color="grey" size={13} />
            </li>
          }
        />
      </div>
      </div>
    </div>
  );
};

export default Lpntable;
