import Header from "../../../../Components/Header";
import PendingPutAwayComponents from "./Components/PendingPutAway-Components";
import Footer from "../../../../Components/Footer";

const PedingPutAway = () => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <PendingPutAwayComponents />
      <Footer />
    </div>
  );
};
export default PedingPutAway;
