import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport, MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import {
  FaFileAlt,
  FaSort,
  FaList,
  FaThLarge,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { FaListUl, FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";

import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";

const Purchaseordercomponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const purchaseOrders = [
    {
      id: "PO-PP/24-25/00015",
      orderDate: "2024-10-01",
      vendorNo: "-",
      orderStatus: "Pending",
      vendorName: "Vendor A",
      address: "123 Street",
      postCode: "12345",
      city: "City A",
      state: "State A",
      country: "Country A",
      masterClient: "DKB",
      locationCode: "GGN",
      creationDate: "2024-09-30",
      createdBy: "Admin",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
  //   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
      <div className="" style={{ backgroundColor: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center p-2"
          style={{ background: "#fcfdfe" }}
        >
          {/* <div>
        <a href="#" className="pagetitle px-2" style={{borderBottom:'0px solid'}}>
        {" "}
        Purchase Order
      </a>
        </div> */}
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "5px", width: "100%" }}
          >
            <a
              href="#"
              className="pagetitle px-2"
              style={{ borderBottom: "0px solid" }}
            >
              {" "}
              Purchase Order
            </a>
            <NavLink
              to="/purchaseorder/create-purchase-order"
              style={{ textDecoration: "none" }}
              className="createbtn m-0"
            >
              <span className="iconbox">
                <MdAdd fontSize={18} />{" "}
              </span>{" "}
              Add New
            </NavLink>
            {/* <a href="#" style={{backgroundColor:'#fff',textDecoration:'none'}}  className="importbtn">
            <span className="iconbox">
              <MdOutlineImportExport fontSize={18}  />{" "}
            </span>{" "}
            Import
          </a> */}
          </div>

          {/* filter start here  */}
          {/* <div className="d-flex" style={{gap:'4px'}}>
        <div className=" filterbtn "> <span className="iconbox">  <MdFilterAlt fontSize={18} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <AiOutlineFileExcel fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaFileAlt fontSize={15} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaSort fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <LuRefreshCw fontSize={14} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaListUl fontSize={16} color="#270b79" /></span> </div>
        <div className=" filterbtn "> <span className="iconbox-left">  <FaThLarge fontSize={14} color="#270b79" /></span> </div>
        </div> */}
        </div>
        {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

        <div
          className="table-responsive"
          //  style={{overflow:'visible'}}
        >
          <table className="table table-bordered ">
            <thead className="thead-dark">
              <tr>
                <th className="table-header">Action </th>
                <th className="table-header">
                  No. <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor No.{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Order Status{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Name{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Address{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Post Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor City{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor State{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Vendor Country{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Master Client{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Location Code{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Creation Date{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
                <th className="table-header">
                  Created By{" "}
                  <MdOutlineImportExport fontSize={18} color="#6c757d" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown />
                    </td>
                    <td style={{ textWrap: "nowrap" }}>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown />
                    </td>
                    <td>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
              {purchaseOrders
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  currentPage * rowsPerPage
                )
                .map((order, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown />
                    </td>
                    <td>{order.id}</td>
                    <td>{order.orderDate}</td>
                    <td>{order.vendorNo}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.vendorName}</td>
                    <td>{order.address}</td>
                    <td>{order.postCode}</td>
                    <td>{order.city}</td>
                    <td>{order.state}</td>
                    <td>{order.country}</td>
                    <td>{order.masterClient}</td>
                    <td>{order.locationCode}</td>
                    <td>{order.creationDate}</td>
                    <td>{order.createdBy}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
          {/* <ReactPaginate currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
          <div>
            <p style={{ color: "#412889", fontSize: "0.8rem" }}>
              Showing 31 to 40 of 56 entries
            </p>
          </div>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              //   filterdata(e.selected * 40);
            }}
            containerClassName="pagination"
            breakClassName="page-item"
            forcePage={currentPage - 1}
            activeClassName="active"
            pageClassName="page-item"
            previousLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesLeft color="grey" size={13} />
              </li>
            }
            nextLabel={
              <li
                className="page-item"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#eff4f9",
                }}
              >
                <FaAnglesRight color="grey" size={13} />
              </li>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Purchaseordercomponent;
