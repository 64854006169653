import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { MdAdd, MdOutlineImportExport,MdFilterAlt } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileAlt,FaSort,FaList,FaThLarge} from 'react-icons/fa';
import { LuRefreshCw } from "react-icons/lu";
import { FaAnglesLeft, FaAnglesRight, FaListUl } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import { IoCloseSharp } from "react-icons/io5";

const Table = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  const purchaseOrders = [
    {
      id: "PO-PP/24-25/00015",
      orderDate: "2024-10-01",
      vendorNo: "-",
      orderStatus: "Pending",
      vendorName: "Vendor A",
      address: "123 Street",
      postCode: "12345",
      city: "City A",
      state: "State A",
      country: "Country A",
      masterClient: "DKB",
      locationCode: "GGN",
      creationDate: "2024-09-30",
      createdBy: "Admin",
    },
    // Add more data as needed
  ];

  const totalPages = 28;
//   const totalPages = Math.ceil(purchaseOrders.length / rowsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid maindiv mt-3">
   <div style={{backgroundColor:'white'}}>
      <div className="d-flex justify-content-between align-items-center p-2" style={{background:'#fcfdfe'}}>
      <a href="#" className="pagetitle px-2">
        Create Undo GRN
      </a>
        <div className="d-flex" style={{gap:'6px'}}>
        <NavLink to="/purchaseorder/create-purchase-order" className="createbtn m-0">
            <span className="iconbox">
              <MdAdd fontSize={18}/>{" "}
            </span>{" "}
            Add New
          </NavLink>
        
        </div>

      
      </div> 
      <div className="d-flex justify-content-center p-2" style={{background:'#fcfdfe'}}>
       <div className="row m-0" style={{width:'100%',height:"30px"}}>
  
        
          <div className="col-3"> <select name="" className="form-control" style={{fontSize:'13px'}} id="">
          <option value="Select Document Type">Select Warehouse Receipt No</option>
          </select> </div>
        <div className="col-3"><select name="" className="form-control" style={{fontSize:'13px'}} id="">
        <option value="Select Gate Entry No">Select Posted Warehouse No</option>
        </select></div>
        <div className="col-3"><select name="" className="form-control" style={{fontSize:'13px'}} id="">
        <option value="Select Gate Entry No">Select PO No</option>
        </select></div>
        <div className="col-3" style={{minHeight:'57.5px'}}>
          
          {/* <input type="date" style={{fontSize:"13px"}} className="form-control" /> */}
          
          <input
        type="date"
        value={value}
className="form-control"
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          color: value || focused ? 'black' : 'gray',fontSize:'13px'
        }}
        placeholder="Select date"
      />
      {!value && !focused && (
        <span
          style={{
            position: 'relative',
            pointerEvents: 'none',
            color: 'gray',
            paddingLeft: '10px',
            top:'-30px',
            left:'2px',
            backgroundColor:'#fff',
          padding:'2px 13px',
          fontSize:'13px'
          }}
        >
        Assigned date
        </span>
      )}
          
          </div>
          
        
 
       </div>
      </div> 
      {/* <div>
        <input type="text" placeholder="Search" style={{borderRadius:'0px',marginTop:'4px',fontSize:'13px',padding:'8px 0px'}} className="form-control" />
      </div> */}

      <div className="table-responsive" >
        <table className="table table-bordered ">
          <thead className="thead-dark">
            <tr>
              <th className="table-header">ID<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Posted Warehouse Receipt<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Warehouse Receipt<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Order No.<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">No. of Lines<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Total Qty<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Assigned User ID<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Assignment Date<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Assignment Time<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Master Client<MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
              <th className="table-header">Location Code <MdOutlineImportExport fontSize={18} color="#6c757d"  /> </th>
            </tr>
          </thead>
          <tbody>
<tr>
  <td> <input type="radio" name="" id="" /> </td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="PGRN-PP/11/21/100422" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="GRN-PP/21-22/100674" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="PO-PP/21-22/10542" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="1" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="12.0000" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="0000-00-00 00:00:00" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="00:00:00" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="DKB" /></td>
  <td><input type="text" className="form-control" style={{borderRadius:'0px',fontSize:'13px'}} readOnly value="GGN" /></td>
</tr>
          </tbody>
        </table>
      </div>

      <div className="container-fluid mt-2 d-flex justify-content-between align-items-center">
      <div>
        <p style={{color:'#412889',fontSize:'0.8rem'}}>Showing 31 to 40 of 56 entries</p>
      </div>
      <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                    //   filterdata(e.selected * 40);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    forcePage={currentPage - 1}
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                        
                                        <FaAnglesLeft color="grey" size={13} />
                                         
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item" style={{textDecoration:'underline',textDecorationColor:'#eff4f9'}}>
                                      
                                      <FaAnglesRight color="grey" size={13} />
                                      
                                      </li>
                                    }
                                  />
    </div>
    </div>
    
    </div>
  );
};

export default Table;
